<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useStringToHsl } from "@verbleif/lib";
import { computed, ref } from "vue";

interface Props {
  name?: string
  active?: boolean
  icon?: string | string[]
  inactiveIcon?: string | string[]
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  letterAmount?: number
  loading?: boolean
  color?: string
  avatarUrl?: string | undefined | null
  notifications?: number
  main?: boolean
  borderStyle?: "none" | "light" | "medium" | "bold"
  borderColor?: string
  status?: "online" | "away" | "busy" | "offline" | null
  statusPosition?: "topRight" | "topLeft" | "bottomRight" | "bottomLeft"
}

const {
  name = "",
  active = true,
  icon = "",
  inactiveIcon = "",
  size = "sm",
  main = false,
  letterAmount = 2,
  loading = false,
  color = undefined,
  notifications = 0,
  borderStyle = "none",
  borderColor = "ring-gray-500",
  status = null,
  statusPosition = "bottomRight",
  avatarUrl = undefined,
} = defineProps<Props>();

const { stringToHslColor } = useStringToHsl();
const imageError = ref(false);

const letters = computed(() => {
  const incomingName = name.split(" ").filter(n => n);

  if (incomingName.length === 1) {
    return incomingName[0].slice(0, letterAmount);
  }

  let letters = "";
  incomingName.forEach((part) => {
    if (letters.length >= letterAmount) {
      return;
    }
    letters += part[0];
  });

  return letters;
});

function getContrastYIQ(hexcolor: string) {
  const r = Number.parseInt(hexcolor.replace("#", "").substr(0, 2), 16);
  const g = Number.parseInt(hexcolor.replace("#", "").substr(2, 2), 16);
  const b = Number.parseInt(hexcolor.replace("#", "").substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq >= 200 ? "black" : "white";
}

const computedColor = computed(() => {
  if (loading) {
    return "#CCCCCC";
  }

  if (color) {
    return color;
  }

  return active ? stringToHslColor(name) : "#CCCCCC";
});

const textColor = computed(() => {
  return getContrastYIQ(computedColor.value);
});

const sizeClasses = computed(() => {
  const sizes = {
    xs: {
      container: "w-6 h-6",
      content: "w-full h-full",
      text: "text-xs",
      icon: "text-xs",
      notification: "w-4 h-4 text-[8px] -top-1 -right-1",
    },
    sm: {
      container: "w-8 h-8",
      content: "w-full h-full",
      text: "text-xs",
      icon: "text-sm",
      notification: "w-5 h-5 text-xs -top-1 -right-1",
    },
    md: {
      container: "w-10 h-10",
      content: "w-full h-full",
      text: "text-sm",
      icon: "text-base",
      notification: "w-5 h-5 text-xs -top-1 -right-1",
    },
    lg: {
      container: "w-12 h-12",
      content: "w-full h-full",
      text: "text-base",
      icon: "text-xl",
      notification: "w-6 h-6 text-xs -top-1 -right-1",
    },
    xl: {
      container: "w-16 h-16",
      content: "w-full h-full",
      text: "text-xl",
      icon: "text-2xl",
      notification: "w-6 h-6 text-xs -top-1 -right-1",
    },
  };

  return sizes[size as keyof typeof sizes] || sizes.sm;
});

const borderClasses = computed(() => {
  if (borderStyle === "none") {
    return "";
  }

  const ringWidthMap = {
    light: "ring-1",
    medium: "ring-2",
    bold: "ring-4",
  };

  return `${ringWidthMap[borderStyle]} ${borderColor} ring-offset-2 ring-offset-white dark:ring-offset-dark-600`;
});

const statusClasses = computed(() => {
  if (!status) {
    return null;
  }

  const statusColors = {
    online: "bg-green-500",
    away: "bg-yellow-500",
    busy: "bg-red-500",
    offline: "bg-gray-400",
  };

  return statusColors[status];
});

const statusPositionClasses = computed(() => {
  const positions = {
    topRight: "top-0 right-0 translate-x-[calc(10%-2px)] translate-y-[calc(-10%+2px)]",
    topLeft: "top-0 left-0 translate-x-[calc(-10%+2px)] translate-y-[calc(-10%+2px)]",
    bottomRight: "bottom-0 right-0 translate-x-[calc(10%-2px)] translate-y-[calc(10%-2px)]",
    bottomLeft: "bottom-0 left-0 translate-x-[calc(-10%+2px)] translate-y-[calc(10%-2px)]",
  };

  return positions[statusPosition];
});

const statusSizeClasses = computed(() => {
  if (size === "xl" || size === "lg") {
    return "w-1/4 h-1/4";
  }
  return "w-1/3 h-1/3";
});

function handleImageError() {
  imageError.value = true;
}
</script>

<template>
  <div class="relative inline-flex">
    <!-- Notification badge -->
    <div
      v-if="notifications > 0"
      class="absolute flex justify-center items-center bg-red-500 text-white rounded-full z-20"
      :class="[
        sizeClasses.notification,
        notifications > 99 ? 'text-[10px]' : '',
      ]"
    >
      {{ notifications > 99 ? '99+' : notifications }}
    </div>

    <!-- Avatar container -->
    <div
      class="rounded-full transition-all duration-200 relative flex items-center justify-center"
      :class="[
        sizeClasses.container,
        borderClasses,
      ]"
    >
      <!-- Custom slot for additional UI elements -->
      <slot name="button" />

      <!-- Avatar content -->
      <div
        class="rounded-full justify-center items-center overflow-hidden flex"
        :class="[sizeClasses.content]"
        :style="{
          backgroundColor: computedColor,
          color: textColor,
        }"
      >
        <!-- Loading state -->
        <VSkeleton
          v-if="loading"
          pill
          :width="100"
          :height="100"
          height-unit="%"
          :dynamic-width="false"
        />

        <!-- Avatar content when not loading -->
        <div
          v-else
          class="flex items-center justify-center w-full h-full"
          :class="[
            sizeClasses.text,
            { 'font-bold': main },
          ]"
        >
          <!-- Image avatar -->
          <template v-if="avatarUrl && !imageError">
            <img
              :src="avatarUrl"
              alt="Avatar"
              class="w-full h-full object-cover"
              @error="handleImageError"
            >
          </template>

          <!-- Icon avatar -->
          <template v-else-if="icon && !imageError">
            <FontAwesomeIcon
              :icon="icon"
              :class="[sizeClasses.icon, { 'font-bold': main }]"
            />
          </template>

          <!-- Initials avatar (also used when image fails to load) -->
          <template v-else>
            <span class="font-medium">{{ letters.toUpperCase() }}</span>
          </template>
        </div>
      </div>

      <!-- Status indicator -->
      <div
        v-if="status"
        class="absolute rounded-full border border-white dark:border-dark-600"
        :class="[
          statusClasses,
          statusPositionClasses,
          statusSizeClasses,
        ]"
      />
    </div>

    <!-- Inactive icon -->
    <span v-if="!loading && !active && inactiveIcon" class="ml-1.5">
      <FontAwesomeIcon :icon="inactiveIcon" />
    </span>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.7);
}
</style>
