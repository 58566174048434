<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Dropdown as VDropdown } from "floating-vue";
import { defineProps } from "vue";

const {
  label = "",
  dropdownLabel = "",
  disabled = false,
  layout = "is-column",
  min = 0,
  max = 0,
  step = 1,
} = defineProps<Props>();

interface Props {
  invalid?: boolean
  required?: boolean
  label?: string
  dropdownLabel?: string
  disabled?: boolean
  layout?: string
  min?: number
  max?: number
  step?: number | string
}

const modelValue = defineModel<number | undefined>();

if (modelValue.value === undefined) {
  modelValue.value = Number(min);
}

function decreaseValue() {
  if (modelValue.value === undefined) {
    return;
  }
  if (modelValue.value - Number(step) < min) {
    modelValue.value = Number(min);
    return;
  }
  modelValue.value -= Number(step);
}

function increaseValue() {
  if (modelValue.value === undefined) {
    modelValue.value = 1;
    return;
  }
  if (!max) {
    modelValue.value += Number(step);
    return;
  }
  if (modelValue.value + Number(step) > max) {
    modelValue.value = Number(max);
    return;
  }
  modelValue.value += Number(step);
}

function checkValue() {
  if (modelValue.value === undefined) {
    return;
  }
  if (max !== 0 && modelValue.value + Number(step) > max) {
    modelValue.value = Number(max);
  }
  if (modelValue.value - Number(step) < min) {
    modelValue.value = Number(min);
  }
}

// reset value input number
function resetValue() {
  modelValue.value = 0;
}
</script>

<template>
  <div>
    <label
      v-if="label"
      class="flex items-center gap-1 text-theme-light-gray-2 dark:text-gray-300 text-sm mb-1"
      :class="{ 'w-1/3': layout === 'is-row' }"
    >
      {{ label }}
      <span v-if="required" class="text-red-500 font-bold">*</span>
    </label>
    <div class="inline-block ">
      <VDropdown no-auto-focus theme="verbleif-select" placement="bottom">
        <div
          class="h-[36px] w-[60px] rounded-full border border-solid flex justify-center items-center transition-all"
          :class="{
            'bg-gray-300 dark:bg-dark-700 border-gray-200 dark:border-dark-600 cursor-not-allowed': disabled,
            'border-danger-600 dark:border-danger-700 bg-white dark:bg-dark-800': invalid,
            'border-gray-200 dark:border-dark-600 bg-white dark:bg-dark-800 hover:border-gray-400 dark:hover:border-gray-500 focus-within:ring-2 focus-within:ring-primary-500':
              !disabled && !invalid,
          }"
        >
          <input
            v-model="modelValue"
            type="number"
            class="w-full text-center bg-transparent outline-none dark:text-white"
            :class="{ 'cursor-not-allowed': disabled }"
            :disabled="disabled"
            @blur="checkValue"
          >
        </div>

        <!-- Dropdown menu -->
        <template #popper>
          <div class="bg-white rounded-lg p-4">
            <label class="  mb-1 font-bold">
              {{ label }}
            </label>
            <div class="flex items-center justify-center mb-2 gap-2 p-2">
              <button
                class="w-10 h-10 flex justify-center items-center rounded-full transition-all"
                :class="disabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-danger-600 dark:bg-danger-700 hover:bg-danger-300 dark:hover:bg-danger-300'"
                :disabled="disabled"
                @click="decreaseValue"
              >
                <FontAwesomeIcon :icon="['fas', 'minus']" class="text-white" />
              </button>
              <div
                class="h-[36px] w-[60px] rounded-full border border-solid flex justify-center items-center mx-auto transition-all"
                :class="{
                  'bg-gray-300 dark:bg-dark-700 border-gray-200 dark:border-dark-600 cursor-not-allowed': disabled,
                  'border-danger-600 dark:border-danger-700 bg-white dark:bg-dark-800': invalid,
                  'border-gray-200 dark:border-dark-600 bg-white dark:bg-dark-800 hover:border-gray-400 dark:hover:border-gray-500 focus-within:ring-2 focus-within:ring-primary-500':
                    !disabled && !invalid,
                }"
              >
                <input
                  v-model="modelValue"
                  type="number"
                  class="w-full text-center bg-transparent outline-none dark:text-white"
                  :class="{ 'cursor-not-allowed': disabled }"
                  :disabled="disabled"
                  @blur="checkValue"
                >
              </div>
              <button
                class="w-10 h-10 flex justify-center items-center rounded-full transition-all"
                :class="disabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-primary-600 dark:bg-primary-700 hover:bg-primary-300 dark:hover:bg-primary-300'"
                :disabled="disabled"
                @click="increaseValue"
              >
                <FontAwesomeIcon :icon="['fas', 'plus']" class="text-white" />
              </button>
            </div>
            <div class=" border-t border-gray-400 my-2" />
            <button
              class="mt-1 w-full py-2 text-center rounded-lg transition-all hover:bg-gray-500 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
              :class="disabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : '  dark:bg-dark-700 hover:bg-gray-400 dark:hover:bg-gray-600'"
              :disabled="disabled"
              @click="resetValue"
            >
              <label>
                <FontAwesomeIcon :icon="['far', 'ban']" />
                {{ dropdownLabel }}
              </label>
            </button>
          </div>
        </template>
      </VDropdown>
    </div>
  </div>
</template>
