<script setup lang="ts">
import { useSystemStore } from "@verbleif/shared";
import CloseIcon from "@web/assets/CloseIcon.svg?component";
import { usePropertyStates } from "@web/features/Tasks/TaskExtraData/usePropertyStates";
import { computed, ref } from "vue";
import ReservationStateContent from "../ReservationStateContent.vue";
import { useProvideReservationState } from "../useReservationState";

const visible = ref(false);
const reservationState = ref<any>(null);
const { propertiesIndexedByIri } = useSystemStore();
const { getPropertyState } = usePropertyStates();

// Create providable state with dialog instance
const reservationStateInstance = useProvideReservationState();

const propertyIri = computed(() => reservationState.value?.property || null);

const propertyId = computed(() => {
  if (!propertyIri.value || !propertiesIndexedByIri.value[propertyIri.value]) {
    return null;
  }
  return propertiesIndexedByIri.value[propertyIri.value].id;
});

async function show({ reservationState: reservationStateProp }: { reservationState?: any }) {
  visible.value = true;
  reservationState.value = reservationStateProp || null;

  // Get property ID from IRI and load state
  if (propertyId.value) {
    reservationStateInstance.setPropertyId(propertyId.value);
    await getPropertyState(propertyId.value);
  } else {
    reservationStateInstance.setPropertyId(null);
  }
}

function hide() {
  visible.value = false;
  reservationState.value = null;
  reservationStateInstance.setPropertyId(null);
}

defineExpose({
  show,
  hide,
});
</script>

<template>
  <VDialog
    id="reservation-state-dialog"
    :visible="visible"
    mask
    headless
    @close="hide"
  >
    <template #content="{ close }">
      <template v-if="reservationState">
        <div class="w-[800px] min-h-[600px] pb-4 overflow-hidden">
          <div class="flex items-center justify-between p-4">
            <strong class="text-xl text-gray-800 dark:text-gray-200">
              {{ $t("tasks.information.property_info_title") }}
            </strong>

            <span
              class="cursor-pointer p-1.5 rounded-full w-8 h-8 flex items-center justify-center close-icon"
              role="button"
              aria-label="Close"
              @click="close"
              @mousedown.stop
            >
              <CloseIcon class="w-4 h-4" />
            </span>
          </div>
          <ReservationStateContent :dialog="true" />
        </div>
      </template>
    </template>
  </VDialog>
</template>

<style scoped>

</style>
