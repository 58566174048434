<script setup lang="ts">
import type { ReservationDatePointState } from "@verbleif/lib";
import { computed } from "vue";
import { useDatePointState } from "./useDatePointState";

const props = defineProps<{
  datePointState: ReservationDatePointState
  loading: boolean
}>();

const state = computed(() => useDatePointState(props.datePointState));
</script>

<template>
  <template v-if="loading">
    <div class="flex items-center pt-2 justify-center flex-col gap-2 h-full w-full">
      <VSkeleton :width="20" width-unit="px" :height="20" />
      <VSkeleton :width="80" width-unit="px" :height="14" />
      <VSkeleton :width="130" width-unit="px" :height="14" />
    </div>
  </template>
  <div v-else class="flex items-center justify-center flex-col gap-5">
    <template v-if="state.upperSection">
      <div class="flex items-center justify-center flex-col gap-1">
        <FontAwesomeIcon
          class="w-5 h-5"
          :class="state.upperSection.color"
          :icon="state.upperSection.icon"
        />
        <div class="flex flex-col items-center justify-center">
          <span
            class="font-bold"
            :class="state.upperSection.color"
          >
            {{ $t(state.upperSection.transitionKey) }}
          </span>
          <span
            class="text-sm"
            :class="state.upperSection.color"
          >
            {{ $t(state.upperSection.subtitleKey, state.upperSection.subtitleParams) }}
          </span>
        </div>
      </div>
    </template>

    <div class="flex items-center justify-center flex-col gap-1">
      <FontAwesomeIcon
        class="w-5 h-5"
        :class="state.stateColor"
        :icon="state.stateIcon"
      />
      <div class="flex flex-col items-center justify-center">
        <span
          class="font-bold"
          :class="state.stateColor"
        >
          {{ $t(state.mainTransitionKey) }}
        </span>
        <span
          v-if="state.getSubText.key"
          class="text-sm"
          :class="state.stateColor"
        >
          {{ $t(state.getSubText.key, state.getSubText.params) }}
        </span>
      </div>
    </div>
  </div>
</template>
