import type { HeadlessProgressToastProps } from "../components";
import { type AppSubscriptionImportProgress, iriToId } from "@verbleif/lib";
import { useI18n } from "@verbleif/lib/src/translations/i18n";
import { createGlobalState } from "@vueuse/core";
import dayjs from "dayjs";
import { ref } from "vue";

const TRANSLATION_PATH = "appStore.settings.sync";

/**
 * Format seconds into a human-readable duration (seconds, minutes, hours)
 */
function formatDuration(seconds: number, t: ReturnType<typeof useI18n>["t"]): string {
  if (seconds <= 0) {
    return `0 ${t(`${TRANSLATION_PATH}.time.seconds`, 0)}`;
  }

  const dayjsDuration = dayjs.duration(seconds, "seconds");

  if (seconds < 60) {
    const secs = Math.round(dayjsDuration.asSeconds());
    return `${secs} ${t(`${TRANSLATION_PATH}.time.seconds`, secs)}`;
  } else if (seconds < 3600) {
    const mins = Math.ceil(dayjsDuration.asMinutes());
    return `${mins} ${t(`${TRANSLATION_PATH}.time.minutes`, mins)}`;
  } else {
    const hours = Math.floor(dayjsDuration.asHours());
    const mins = dayjsDuration.minutes();

    if (mins === 0) {
      return `${hours} ${t(`${TRANSLATION_PATH}.time.hours`, hours)}`;
    } else {
      return `${hours} ${t(`${TRANSLATION_PATH}.time.hours`, hours)} ${t(`${TRANSLATION_PATH}.time.and`)} ${mins} ${t(`${TRANSLATION_PATH}.time.minutes`, mins)}`;
    }
  }
}
export const useImportHistory = createGlobalState(() => {
  const importHistory = ref<Record<string, AppSubscriptionImportProgress>>({});
  const { t } = useI18n();

  function getComponentProps(progress: AppSubscriptionImportProgress): HeadlessProgressToastProps | undefined {
    if (!progress.importBatch) {
      console.log("No importBatch in progress:", progress);
      return;
    }
    const batchId = iriToId(progress.importBatch);

    // Format remaining times
    const formattedRemainingTime = formatDuration(progress.progressBar?.remaining || 0, t);
    const formattedSequenceRemainingTime = progress.sequenceProgressBar
      ? formatDuration(progress.sequenceProgressBar.remaining, t)
      : "";

    const isDone = !progress.failed && !progress.finished;

    // Format sequence name for display
    const displaySequenceName = progress.sequenceName
      ? t(`${TRANSLATION_PATH}.types.${progress.sequenceName}`, progress.sequenceName)
      : "";

    // Define violations for failed state
    const syncViolations = progress.failed ? {} : undefined;

    const title = `${t(`${TRANSLATION_PATH}.synchronisation`)} ${batchId}`;
    const message = progress.failed
      ? t(`${TRANSLATION_PATH}.sync_failed`)
      : progress.finished
        ? t(`${TRANSLATION_PATH}.sync_finished`)
        : `${t(`${TRANSLATION_PATH}.total_progress`)} (${progress.progressBar?.step || 0}/${progress.progressBar?.max || 0})`;
    const messageRight = isDone
      ? progress.progressBar?.completed
        ? t(`${TRANSLATION_PATH}.completed`)
        : progress.failed
          ? t(`${TRANSLATION_PATH}.failed`)
          : t(`${TRANSLATION_PATH}.remaining_time`, { time: formattedRemainingTime })
      : undefined;

    const value = isDone ? progress.progressBar?.percent || 0 : undefined;

    const secondaryMessage = progress.sequenceProgressBar
      ? t(`${TRANSLATION_PATH}.type_step`, {
          type: displaySequenceName,
          current: progress.sequenceProgressBar.step,
          total: progress.sequenceProgressBar.max,
        })
      : "";

    const secondaryMessageRight = progress.sequenceProgressBar
      ? (
          progress.sequenceProgressBar.completed
            ? t(`${TRANSLATION_PATH}.completed`)
            : progress.failed
              ? t(`${TRANSLATION_PATH}.failed`)
              : t(`${TRANSLATION_PATH}.remaining_time`, { time: formattedSequenceRemainingTime })
        )
      : "";
    const secondaryValue = progress.sequenceProgressBar?.percent || 0;
    return {
      title,
      message,
      messageRight,
      value,
      secondaryMessage,
      secondaryMessageRight,
      secondaryValue,
      showSecondaryProgressBar: !!progress.sequenceProgressBar,
      runningIconClass: "text-primary-500",
      violations: syncViolations,
      isComplete: progress.finished,
    };
  }

  function getImportHistoryForAppSubscription(appSubscriptionId: string) {
    return Object.values(importHistory.value).filter(progress => progress.appSubscription === appSubscriptionId);
  }

  return {
    importHistory,
    getComponentProps,
    getImportHistoryForAppSubscription,
  };
});
