import type { Ref } from "vue";
import type { AccordionSize, AccordionVariant } from "./types";
import { createInjectionState } from "@vueuse/core";

const [useProvideAccordionState, useAccordionStateRaw] = createInjectionState(({
  modelValue,
  multiple,
  size,
  variant,
}: {
  modelValue: Ref<string | number | (string | number)[]>
  multiple: Ref<boolean>
  size: Ref<AccordionSize>
  variant: Ref<AccordionVariant>
}) => {
  function onPanelClick(value: string | number) {
    if (multiple?.value) {
      const currentValues = Array.isArray(modelValue.value) ? modelValue.value : [];
      const index = currentValues.indexOf(value);
      if (index === -1) {
        modelValue.value = [...currentValues, value];
      } else {
        modelValue.value = currentValues.filter(v => v !== value);
      }
    } else {
      const currentValue = Array.isArray(modelValue.value) ? modelValue.value[0] : modelValue.value;
      modelValue.value = currentValue === value ? [] : value;
    }
  }

  return {
    multiple,
    onPanelClick,
    size,
    variant,
    modelValue,
  };
});

export { useProvideAccordionState };

export function useAccordionState() {
  const state = useAccordionStateRaw();
  if (state == null) {
    throw new Error("Please call `useProvideAccordionState` on the appropriate parent component");
  }
  return state;
}
