import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Verbleif from "@verbleif/lib";
import VueDatePicker from "@vuepic/vue-datepicker";

import app from "@web/core/app";
import { provideUpdater } from "@web/features/Updater/useUpdater";
import {
  // Components
  Dropdown,
  Menu,
  options,
  Tooltip,
  vClosePopper,
  // Directives
  vTooltip,
} from "floating-vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "animate.css";
import "@web/core/config";
import "@verbleif/lib/src/translations/i18n";
import "@web/core/plugin/router";
import "@web/core/plugin/sentry";
import "@web/core/plugin/fontAwesome";
import "@web/core/plugin/import";
import "@web/core/plugin/notifications";
import "@web/core/plugin/theme";
import "@web/core/plugin/moment";
import "floating-vue/dist/style.css";
import "@verbleif/lib/src/global.css";

if (import.meta.env.DEV) {
  if (window.location.hostname === "localhost") {
    window.location.hostname = "verbleif.localhost";
  }
}

async function initializeApp() {
  if (import.meta.env.VITE_MOCKED === "true") {
    console.log("%c[MSW] Mocked is true", "color: red; font-weight: bold;");
    const { setupWorker } = await import("msw/browser");
    const { handlers, webHandlers } = await import("@verbleif/mocks");
    // We put ...handlers at the end because we have a * wildcard in the handlers array to prevent dynamic imports from being mocked
    await setupWorker(...webHandlers, ...handlers).start({ onUnhandledRequest: "bypass" });
  }

  options.themes["verbleif-popper"] = {
    $extend: "dropdown",
  };
  options.themes["verbleif-select"] = {
    $extend: "dropdown",
  };
  options.themes["verbleif-popper-bounded"] = {
    $extend: "dropdown",
  };

  options.themes["verbleif-tooltip"] = {
    $extend: "tooltip",
  };
  options.themes["verbleif-tooltip-gray"] = {
    $extend: "tooltip",
  };
  options.themes["verbleif-tooltip-ac"] = {
    $extend: "tooltip",
  };
  options.themes["verbleif-tooltip-dark"] = {
    $extend: "tooltip",
  };
  options.themes["verbleif-tooltip-solid"] = {
    $extend: "tooltip",
  };
  app
    .use(provideUpdater())
    .directive("tooltip", vTooltip)
    .directive("close-popper", vClosePopper)

    .component("VDropdown", Dropdown)
    .component("VVTooltip", Tooltip)
    .component("VMenu", Menu)
    .component("VueDatePicker", VueDatePicker)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(Verbleif)
    .mount("#app");
}

initializeApp().then();
