<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, defineProps, inject, ref, watch } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";
import VSkeleton from "../Skeleton/VSkeleton.vue";
import { useComponentId } from "../useComponentId";

const {
  type = "text",
  iconRight = undefined,
  name = "",
  autocomplete = "off",
  autocapitalize = "on",
  editable = true,
  disabled = false,
  loading = false,
  variant = "is-gray",
  textAlign = "is-text-align-left",
  errorMessage = "",
  hasTopLabel = false,
  labelLeft = "",
  labelCenter = false,
  hasMargin = false,
  layout = "is-column",
  required = false,
  initialValue = undefined,
  showErrorBelow = false,
  hasPerms = true,
  invalid = false,
  size = "medium",
  ariaLabel = "",
  ariaRequired = false,
  ariaHidden = false,
  clearable = false,
  "id": rawId,
  placeholder = "",
  maxDecimals = null,
} = defineProps<Props>();

const emit = defineEmits<{
  (e: "blur", event: Event): void
  (e: "onRightIconClick"): void
  (e: "keydown", event: KeyboardEvent): void
}>();

const model = defineModel<InputModelValue>();

interface Props {
  type?: string
  iconRight?: string | [ "fa" | "fas" | "far" | "fad" | "fal", string] | undefined
  iconLeft?: string | [ "fa" | "fas" | "far" | "fad" | "fal", string] | undefined
  initialValue?: InputModelValue
  name?: string
  autocomplete?: string
  autocapitalize?: "on" | "off" | "words" | "sentences" | "characters"
  disabled?: boolean
  loading?: boolean
  variant?: string
  textAlign?: string
  hasPerms?: boolean
  errorMessage?: string
  hasTopLabel?: boolean
  placeholder: string
  labelCenter?: boolean
  hasMargin?: boolean
  hasIconRightAction?: boolean
  layout?: string
  labelLeft?: string
  required?: boolean
  showErrorBelow?: boolean
  editable?: boolean
  invalid?: boolean
  size?: "medium" | "large"
  ariaLabel?: string
  ariaRequired?: boolean
  ariaHidden?: boolean
  id?: string
  clearable?: boolean
  maxDecimals?: number | null
}

const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

export type InputModelValue = string | number | null | unknown;

const componentId = useComponentId();

const injectedId = inject<string>("FieldId", componentId?.toString() || "");
const id = computed(() => {
  return rawId || injectedId;
});
const revealPass = ref(false);
const valueRef = ref(initialValue || model.value);

function onInput(event: Event) {
  if (maxDecimals === null) {
    return;
  }

  const input = event.target as HTMLInputElement;
  let value: string = input.value;
  if (!value) {
    model.value = value;
    valueRef.value = value;
    return;
  }
  const commaIndex = value.indexOf(".");
  if (commaIndex === -1) {
    return;
  }
  // console.info("commaIndex", commaIndex);
  value = value.slice(0, commaIndex + maxDecimals + 1);

  model.value = value;
  valueRef.value = value;
  // console.info("value", value);
}

watch(
  () => model.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    valueRef.value = newValue;
  },
);

watch(valueRef, (value, oldValue) => {
  if (value === oldValue) {
    return;
  }
  model.value = value;
});

const computedType = computed(() => {
  if (type !== "password") {
    return type;
  }

  if (revealPass.value) {
    return "text";
  }

  return type;
});

const computedIconRight = computed(() => {
  if (type === "password") {
    if (revealPass.value) {
      return "eye-slash";
    }

    return "eye";
  }
  if (type === "date") {
    return "calendar";
  }

  if (type === "time") {
    return "clock";
  }

  return iconRight;
});

function onRightIconClick() {
  if (type === "password") {
    revealPass.value = !revealPass.value;
  } else {
    emit("onRightIconClick");
  }
}

const showErrorUnder = computed(() => {
  return showErrorBelow || (errorMessage && model.value);
});

const placeholderText = computed(() => {
  if (errorMessage && !showErrorUnder.value) {
    return errorMessage;
  }
  return placeholder;
});

function clearInput() {
  valueRef.value = "";
}

const showClearIcon = computed(() => {
  return clearable && valueRef.value;
});
</script>

<template>
  <div class="v-input-wrapper">
    <div
      class="sub-v-input-wrapper"
      :class="[
        layout,
        {
          'is-loading': loading,
          'is-not-editable': !editable,
          'is-disabled': isDisabled,
          'label-center': labelCenter,
          'is-danger': errorMessage || invalid,
          'has-margin-bottom': hasMargin,
        },
      ]"
    >
      <label
        v-if="labelLeft"
        class="label leading-[16px] text-sm mb-[5px]"
        :class="{ 'w-1/3': layout === 'is-row' }"
        :for="id"
      >
        {{ labelLeft }}
        <span v-if="required" class="required">*</span>
      </label>
      <!-- <VSkeleton
        v-if="loading"
        :height="32"
        :width="100"
        width-unit="%"
        :dynamic-width="false"
        style="border-radius: 5px"
        :pill="false"
      /> -->
      <ACTooltip
        type="edit_field"
        :allow-change="hasPerms"
      >
        <div
          class="input"
          :class="[
            variant,
            textAlign,
            size,
            (errorMessage || invalid) && 'is-danger',
            {
              'has-top-label': hasTopLabel,
              'has-icon-left': iconLeft,
              'has-icon-right': iconRight || type === 'password' || showClearIcon,
              'has-validation-error': errorMessage || invalid,
            },
          ]"
          :style="{ width: layout === 'is-row' ? 'calc(100% - 33.33%)' : '100%' }"
        >
          <div v-if="iconLeft" class="icon left">
            <FontAwesomeIcon v-if="iconLeft" :icon="iconLeft" />
          </div>
          <div
            v-if="loading"
            class="input-content flex  items-center"
          >
            <VSkeleton :width="140" width-unit="px" :height="size === 'medium' ? 10 : 12" height-unit="px" pill />
          </div>
          <input
            v-else
            :id="id"
            v-model="valueRef"
            :name="name"
            :type="computedType"
            class="input-content"
            :class="[
              size === 'medium' ? '!text-sm !leading-[32px]' : '!text-[16px] !leading-[40px]',
            ]"
            :placeholder="placeholderText"
            :disabled="isDisabled || !editable"
            :autocomplete="autocomplete"
            :autocapitalize="autocapitalize"
            :aria-label="ariaLabel"
            :aria-required="ariaRequired"
            :aria-hidden="ariaHidden"
            @input="onInput"
            @blur="$emit('blur', $event)"
            @keydown="$emit('keydown', $event)"
          >
          <div
            v-if="showClearIcon"
            class="icon right clear-icon"
            @click="clearInput"
          >
            <FontAwesomeIcon :icon="['fas', 'times']" class="font-bold" />
          </div>
          <div
            v-else-if="computedIconRight"
            :class="[
              type,
              {
                'has-action':
                  type === 'password'
                  || type === 'date'
                  || type === 'time'
                  || hasIconRightAction,
              },
            ]"
            class="icon right"
            @click="onRightIconClick"
          >
            <FontAwesomeIcon v-if="computedIconRight" :icon="computedIconRight" />
          </div>
        </div>
      </ACTooltip>
      <div
        v-if="showErrorUnder && errorMessage"
        class="error-message mt-1 text-sm"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-input-wrapper {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 16px;

  .sub-v-input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.is-not-editable {
      cursor: default;

      input:disabled {
        cursor: inherit;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      .input {
        opacity: 0.5;
      }

      input:disabled {
        cursor: inherit;
      }
    }

    &.is-loading {
      cursor: progress;
    }

    &.has-margin-bottom {
      margin-bottom: 25px;
    }

    &.is-row {
      flex-direction: row;
      align-items: center;

      .label {
        width: 33.33%;
      }

      .input {
        width: calc(100% - 33.33%);
      }

      &.label-center {
        align-items: center;
      }

      &.has-label-left {
        padding-left: 15px;
      }
    }

    .error-message {
      color: theme("colors.danger.600");
    }

    .label {
      display: inline-flex;
      color: var(--text);
      gap: 5px;
      font-size: 14px;

      .required {
        color: var(--secondary);
        font-weight: bold;
      }
    }

    .input {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 25px;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      border: 1px solid var(--input-border);
      input {
        border-radius: 25px;
      }

      // Extra styling when validation error is occurred
      &.is-danger.has-validation-error {
        border-color: theme("colors.danger.600");

        input {
          color: theme("colors.danger.600");

          &::placeholder {
            color: theme("colors.danger.600");
          }
        }
      }

      &.is-gray,
      &.is-white {
        background: var(--input-background);

        input {
          background-color: transparent;
          color: var(--input-text);

          &::placeholder {
            color: var(--input-placeholder);
          }
        }

        .icon {
          color: var(--text);
        }
      }

      .icon {
        position: absolute;
        font-size: 14px;
        display: inline-flex;
        align-items: center;

        &.left {
          left: 0;
          margin-left: 15px;
        }

        &.right {
          margin-right: 15px;
          right: 0;

          &.clear-icon {
            cursor: pointer;
          }

          &.has-action {
            cursor: pointer;
          }
        }
      }

      &.is-text-align-right {
        input {
          text-align: right;
        }
      }

      &.is-text-align-left {
        .label {
          min-width: 175px;
        }

        input {
          text-align: left;
        }
      }

      &.has-icon-left {
        input {
          padding-left: 37px;
        }
      }

      &.has-icon-right {
        input {
          padding-right: 40px;
        }
      }

      .input-content {
        flex: 1;
        border: none;
        font-size: inherit;
        box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        width: 100%;
        height: 100%;
        // Hack to vertical align correctly.
        padding-left: 20px;

        &:focus {
          outline: none;
        }
      }

      &.medium {
        height: 32px;
      }

      &.large {
        height: 40px;
      }
    }
  }
}

.error-message {
  color: theme("colors.danger.600");
}
</style>
