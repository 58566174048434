<script setup lang="ts">
import {
  DefaultConfirmDialog,
  provideConfirmDialog,
  provideDefaultConfirmDialog,
  provideNewConfirmDialog,
} from "@verbleif/lib";
import i18n from "@verbleif/lib/src/translations/i18n";
import { DebugTools, ThemeSetting, useAuthStore, useLocationStore, usePermsWatch, useSystemStore, useTheme } from "@verbleif/shared";
import { useFirebase } from "@verbleif/shared/src/composables/useFirebase";
import { refreshToken } from "@web/core/refresh";
import Loader from "@web/features/Loader/LoaderView.vue";
import { useLoader } from "@web/features/Loader/useLoader";
import NavbarContainer from "@web/features/Navbar/NavbarContainer.vue";
import Notifications from "@web/features/Notification/NotificationList.vue";
import { Crisp } from "crisp-sdk-web";
import moment from "moment";
import { getCurrentInstance, ref } from "vue";
import { Toaster } from "vue-sonner";
import { setYupLocales } from "./core/plugin/yup";
import PropertyStateDebug from "./features/Property/PropertyStateDebug.vue";
import { usePropertyStateDebug } from "./features/Property/usePropertyStateDebug";
import CurrentReservationDialog from "./features/Tasks/Overview/ReservationState/Dialog/CurrentReservationDialog.vue";
import ReservationStateDialog from "./features/Tasks/Overview/ReservationState/Dialog/ReservationStateDialog.vue";
import { useCurrentReservationDialog } from "./features/Tasks/Overview/ReservationState/Dialog/useCurrentReservationDialog";
import { useReservationStateDialog } from "./features/Tasks/Overview/ReservationState/Dialog/useReservationStateDialog";
import TimeEntriestopDialog from "./features/Tasks/TimeEntry/TimeEntryStopDialog.vue";
import { useActiveTimeEntry } from "./features/Tasks/TimeEntry/useActiveTimeEntry";
import { useAppSync } from "./useAppSync";
import "@web/core/impersonate";

const { propertyStateDebugRef, enablePropertyStateDebug } = usePropertyStateDebug();
const { reservationStateDialogRef } = useReservationStateDialog();
const { currentReservationDialogRef } = useCurrentReservationDialog();
const { timeEntryStopDialogRef } = useActiveTimeEntry();
const { t, locale } = useI18n();
const { user } = useAuthStore();
const route = useRoute();

watch(() => user.value?.locale, (loc) => {
  if (route.meta.skipAuthChecks !== true) {
    locale.value = loc?.toLowerCase() ?? "nl";
    moment.locale(locale.value);
  }
  setYupLocales(t);
}, { immediate: true });

useFirebase();

const authStore = useAuthStore();
const systemStore = useSystemStore();
const locationStore = useLocationStore();
const loaderProvider = useLoader();
provideConfirmDialog();
useAppSync();
provideNewConfirmDialog();
try {
  Crisp.configure("3df3ab2f-fe2a-4c89-ad4a-43965e928246", {
    autoload: false,
    sessionMerge: true,
    locale: i18n.global.locale.value ?? "nl",
  });
} catch (e) {
  console.error(e);
}

const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

const confirmDialogRef = ref<InstanceType<typeof DefaultConfirmDialog>>();
const app = getCurrentInstance()?.appContext.app;

// Provide the confirm dialog instance immediately after setup
watch(confirmDialogRef, (value) => {
  if (value && app) {
    provideDefaultConfirmDialog(app, value);
  }
}, { immediate: true });

// Reset system store when location changes
watch(() => locationStore.selectedLocation.value, (newLocation, oldLocation) => {
  if (!newLocation) {
    return;
  }
  // When we reload the page, oldLocation is undefined. We skip because this causes the task + reports filters to be reset everytime we reload the page.
  if (typeof oldLocation === "undefined") {
    return;
  }
  console.log("%c [App.vue] locationStore.selectedLocation changed / immediate to: ", "color: #4CAF50; font-weight: bold;", newLocation);
  systemStore.reset();
}, { immediate: true });

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      globalLoading.value = false;
      loaderProvider.hide();
    });
}

const loading = computed(() => loaderProvider.loading.value);
const { theme } = useTheme();

usePermsWatch();
</script>

<template>
  <transition name="fade-new">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <Notifications />
  <Toaster
    :theme="theme === ThemeSetting.DARK ? 'dark' : 'light'"
    rich-colors
    position="bottom-right"
    :expand="true"
    :visible-toasts="8"
  />
  <DefaultConfirmDialog ref="confirmDialogRef" />
  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer />
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
        <VConfirmDialog />
      </router-view>
      <VModalContainer />
      <ReservationStateDialog ref="reservationStateDialogRef" />
      <CurrentReservationDialog ref="currentReservationDialogRef" />
      <TimeEntriestopDialog ref="timeEntryStopDialogRef" />
      <DebugTools v-model:enable-property-state-debug="enablePropertyStateDebug" />
      <PropertyStateDebug
        v-if="enablePropertyStateDebug"
        ref="propertyStateDebugRef"
      />
    </div>
  </template>
</template>

<!-- <style src="@verbleif/lib/src/scss/Reset.css"></style>

<style src="@verbleif/lib/src/scss/Fonts.css"></style>

<style src="@verbleif/lib/src/scss/Toast.css"></style> -->

<style lang="scss" src="./main.scss" />
