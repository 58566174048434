import type { AppHydraItem, AppSubscriptionSyncImportHydraItem, HydraItem, MediaObject, ProductHydraItem, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { SortFieldEnum } from "@verbleif/lib";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { computed, ref } from "vue";

export interface TaskSidebar {
  shortcuts: boolean
  departments: boolean
}

function createSystemStore() {
  const systemUsers = ref<UserHydraItem[]>([]);
  const sorts = ref<SortHydraItem[]>([]);
  const propertyGroups = ref<PropertyGroupHydraItem[]>([]);
  const properties = ref<PropertyHydraItem[]>([]);
  const avatars = ref<HydraItem<MediaObject>[]>([]);
  const products = ref<ProductHydraItem[]>([]);
  const activeApps = ref<AppHydraItem[]>([]);
  const reportTopics = ref<ReportTopicHydraItem[]>([]);
  const appSubscriptionSyncImportBatches = ref<AppSubscriptionSyncImportHydraItem[]>([]);
  const searchValue = ref<string>("");
  const taskSidebar = useStorage<TaskSidebar>("taskSidebar", {
    shortcuts: false,
    departments: false,
  }, undefined, {
    serializer: StorageSerializers.object,
  });
  const reportSidebar = useStorage<Record<string, any>>("reportSidebar", {
    general: false,
    reportTopics: false,
  }, undefined, {
    serializer: StorageSerializers.object,
  });
  const taskOverviewSettings = useStorage<{
    showStatusDone: boolean
    showStatusCompleted: boolean
    showSearchAndSort: boolean
    showNoDeadline: boolean
  }>(
    "taskOverviewSettingsV1",
    {
      showStatusDone: true,
      showStatusCompleted: true,
      showSearchAndSort: true,
      showNoDeadline: true,
    },
    undefined,
    { serializer: StorageSerializers.object },
  );
  const reportOverviewSettings = useStorage<{
    showtransfered: boolean
    showSearchAndSort: boolean
    showStatusClosed: boolean
  }>(
    "reportOverviewSettingsV1",
    {
      showtransfered: true,
      showSearchAndSort: true,
      showStatusClosed: true,
    },
    undefined,
    { serializer: StorageSerializers.object },
  );

  // Computed Properties
  const avatarsUrlIndexedByIri = computed<Record<string, string>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key, contentUrl } = obj;
      acc[key] = contentUrl;
      return acc;
    }, {} as Record<string, string>);
  });
  const avatarsMediaObjectIndexedByIri = computed<Record<string, HydraItem<MediaObject>>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<MediaObject>>);
  });

  const systemUsersIndexedByIri = computed<Record<string, UserHydraItem>>(() => {
    return systemUsers.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  });

  const activeAppsIndexedByIri = computed<Record<string, AppHydraItem>>(() => {
    return activeApps.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, AppHydraItem>);
  });

  const sortsIndexedByIri = computed<Record<string, SortHydraItem>>(() => {
    return sorts.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, SortHydraItem>);
  });

  const reportTopicsIndexedByIri = computed<Record<string, ReportTopicHydraItem>>(() => {
    return reportTopics.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, ReportTopicHydraItem>);
  });

  function updateSort(sort: SortHydraItem) {
    sorts.value = sorts.value.map(s => s["@id"] === sort["@id"] ? sort : s);
  }

  function createSort(sort: SortHydraItem) {
    sorts.value.push(sort);
  }

  function deleteSort(sort: SortHydraItem) {
    sorts.value = sorts.value.filter(s => s["@id"] !== sort["@id"]);
  }

  function updatePropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value = propertyGroups.value.map(pg => pg["@id"] === propertyGroup["@id"] ? propertyGroup : pg);
  }

  function createPropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value.push(propertyGroup);
  }

  function deletePropertyGroup(propertyGroup: PropertyGroupHydraItem) {
    propertyGroups.value = propertyGroups.value.filter(pg => pg["@id"] !== propertyGroup["@id"]);
  }

  function updateProduct(product: ProductHydraItem) {
    products.value = products.value.map(p => p["@id"] === product["@id"] ? product : p);
  }

  function createProduct(product: ProductHydraItem) {
    products.value.push(product);
  }

  function deleteProduct(product: ProductHydraItem) {
    products.value = products.value.filter(p => p["@id"] !== product["@id"]);
  }

  const propertyGroupsIndexedByIri = computed<Record<string, PropertyGroupHydraItem>>(() => {
    return propertyGroups.value.reduce((acc: Record<string, PropertyGroupHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  function updateProperty(property: PropertyHydraItem) {
    properties.value = properties.value.map(p => p["@id"] === property["@id"] ? property : p);
  }

  function createProperty(property: PropertyHydraItem) {
    properties.value.push(property);
  }

  function deleteProperty(property: PropertyHydraItem) {
    properties.value = properties.value.filter(p => p["@id"] !== property["@id"]);
  }

  const propertiesIndexedByIri = computed<Record<string, PropertyHydraItem>>(() => {
    return properties.value.reduce((acc: Record<string, PropertyHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  const productsIndexedByIri = computed<Record<string, ProductHydraItem>>(() => {
    return products.value.reduce((acc: Record<string, ProductHydraItem>, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {});
  });

  const usersBasedOnRole = computed(() => {
    return [];
  });
  const assignableUsersTeamsDepartments = computed(() => {
    const defaultAssignableAssignees = [
      ...usersBasedOnRole.value,
    ];

    return defaultAssignableAssignees.sort((a: UserHydraItem | UserGroupHydraItem, b: UserHydraItem | UserGroupHydraItem) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });
  const filteredAssignableUserGroupsUsers = computed(() => {
    return assignableUsersTeamsDepartments.value.filter(() => {
      if (searchValue.value === "") {
        return true;
      }

      // if ("name" in item) {
      //   return item.name.toLowerCase().includes(searchValue.value.toLowerCase());
      // }

      return true;
    });
  });

  const isAnyAppSubscriptionSyncImportBatchRunning = computed(() => {
    return appSubscriptionSyncImportBatches.value.some(batch => !batch.finishedAt && !batch.failedAt);
  });

  // Methods
  function reset() {
    sorts.value = [];
    propertyGroups.value = [];
    properties.value = [];
    avatars.value = [];
    appSubscriptionSyncImportBatches.value = [];
    searchValue.value = "";
    taskOverviewSettings.value = {
      showStatusDone: true,
      showStatusCompleted: true,
      showSearchAndSort: true,
      showNoDeadline: true,
    };
    reportOverviewSettings.value = {
      showtransfered: true,
      showSearchAndSort: true,
      showStatusClosed: true,
    };
    reportSidebar.value = {
      general: false,
      reportTopics: false,
    };
  }

  function setSystemUsers(newUsers: UserHydraItem[]) {
    systemUsers.value = newUsers;
  }
  function setPropertyGroups(newPropertyGroups: PropertyGroupHydraItem[]) {
    propertyGroups.value = newPropertyGroups;
  }
  function setProperties(newProperties: PropertyHydraItem[]) {
    properties.value = newProperties;
  }

  function setSorts(newSorts: SortHydraItem[]) {
    sorts.value = newSorts;
  }

  function setSearchValue(value: string) {
    searchValue.value = value;
  }
  function setAvatars(newAvatars: HydraItem<MediaObject>[]) {
    avatars.value = newAvatars;
  }

  function setAppSubscriptionSyncImportBatches(newAppSubscriptionSyncImportBatches: AppSubscriptionSyncImportHydraItem[]) {
    appSubscriptionSyncImportBatches.value = newAppSubscriptionSyncImportBatches;
  }

  function setProducts(newProducts: ProductHydraItem[]) {
    products.value = newProducts;
  }

  function setReportTopics(newReportTopics: ReportTopicHydraItem[]) {
    reportTopics.value = newReportTopics;
  }

  function setActiveApps(newActiveApps: AppHydraItem[]) {
    activeApps.value = newActiveApps;
  }

  const hasOneSort = computed(() => {
    return sorts.value.length === 1;
  });

  const hasInsideOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.INSIDE);
    });
  });

  const hasQuantityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.QUANTITY);
    });
  });

  const hasPriorityOnASort = computed<boolean>(() => {
    return sorts.value.some((sort) => {
      return sort.visibleFields?.includes(SortFieldEnum.PRIORITY);
    });
  });

  function upsertAppSubscriptionSyncImportBatch(batch: AppSubscriptionSyncImportHydraItem) {
    const index = appSubscriptionSyncImportBatches.value.findIndex(b => b["@id"] === batch["@id"]);
    if (index !== -1) {
      appSubscriptionSyncImportBatches.value[index] = batch;
    } else {
      appSubscriptionSyncImportBatches.value.push(batch);
    }
  }

  function deleteAppSubscriptionSyncImportBatch(batchIri: string) {
    appSubscriptionSyncImportBatches.value = appSubscriptionSyncImportBatches.value.filter(b => b["@id"] !== batchIri);
  }

  function isImportRunningForAppSubscription(appSubscriptionIri: string) {
    return appSubscriptionSyncImportBatches.value.some(batch => batch.appSubscription === appSubscriptionIri && !batch.finishedAt && !batch.failedAt);
  }

  function getAppSubscriptionSyncImportBatches(appSubscriptionIri: string) {
    return appSubscriptionSyncImportBatches.value.filter(batch => batch.appSubscription === appSubscriptionIri);
  }

  return {
    // State
    sorts,
    appSubscriptionSyncImportBatches,
    properties,
    getAppSubscriptionSyncImportBatches,
    isImportRunningForAppSubscription,
    upsertAppSubscriptionSyncImportBatch,
    deleteAppSubscriptionSyncImportBatch,
    propertyGroups,
    avatars,
    products,
    searchValue,
    updateSort,
    taskSidebar,
    reportSidebar,
    hasOneSort,
    taskOverviewSettings,
    reportTopics,
    // Computed Properties
    createSort,
    isAnyAppSubscriptionSyncImportBatchRunning,
    deleteSort,
    avatarsUrlIndexedByIri,
    avatarsMediaObjectIndexedByIri,
    systemUsersIndexedByIri,
    sortsIndexedByIri,
    propertyGroupsIndexedByIri,
    propertiesIndexedByIri,
    productsIndexedByIri,
    usersBasedOnRole,
    assignableUsersTeamsDepartments,
    filteredAssignableUserGroupsUsers,
    hasInsideOnASort,
    hasQuantityOnASort,
    hasPriorityOnASort,
    reportTopicsIndexedByIri,
    setAppSubscriptionSyncImportBatches,
    // Methods
    reset,
    updatePropertyGroup,
    createPropertyGroup,
    activeAppsIndexedByIri,
    activeApps,
    deletePropertyGroup,
    updateProperty,
    createProperty,
    deleteProperty,
    setSystemUsers,
    setPropertyGroups,
    setActiveApps,
    setProperties,
    setSorts,
    setSearchValue,
    setAvatars,
    setReportTopics,
    setProducts,
    updateProduct,
    createProduct,
    deleteProduct,
    reportOverviewSettings,
  };
}

type SystemStoreInterface = ReturnType<typeof createSystemStore>;
let backwardsCompatibleInstance: null | SystemStoreInterface = null;
export function useSystemStore(): SystemStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createSystemStore();
  }

  return backwardsCompatibleInstance;
}
export default useSystemStore;
