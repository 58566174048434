import type { AxiosError, AxiosResponse } from "axios";
import type { MaybeRef, Ref } from "vue";
import type { HydraCollection, HydraContext, PartialHydraCollection } from "../Api";
import type { SearchableField } from "./Searchable";
import { computed, inject, nextTick, onMounted, provide, ref } from "vue";
import { provideCursor } from "./Cursor";
import { provideSearchable } from "./Searchable";
import { provideSortable } from "./Sortable";

const PaginateKey = Symbol("Paginate");

export interface PaginateLoadFnOptions {
  scroll: boolean
}

export interface OnLoadOptions {
  params: URLSearchParams
}

export type OnLoadFunction<T> = (options: OnLoadOptions) =>
Promise<AxiosResponse<(PartialHydraCollection<T> | HydraCollection<T>)>>;

export type LoadParamsFunction = (options: URLSearchParams) => void;

export interface TableColumn {
  label: string
  width?: string | number | "auto"
  align?: "left" | "right"
}

export type TableField = TableColumn | string;

interface CreatePaginateParams<T> {
  tableFields?: Partial<Record<keyof T, TableField>>
  sortableFields: MaybeRef<PartialRecord<Extract<keyof T, string>, string>>
  searchableFields: Array<Extract<keyof T, string>> | Array<SearchableField<Extract<keyof T, string>>>
  initialSort?: { field: string, direction: "DESC" | "ASC" }
  onLoad: (options: any) => Promise<unknown>
  loadParams?: LoadParamsFunction
  initialPerPage?: number
  cursorParams?: Parameters<typeof provideCursor>[0]
  runOnMount?: boolean
  onReset?: () => void
}
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;
export interface SkeletonItem {
  loading: true
  skeleton: true
}

function createPaginate<T extends HydraContext>({
  tableFields,
  sortableFields,
  searchableFields,
  initialSort,
  onLoad,
  initialPerPage = 10,
  runOnMount = false,
  loadParams = () => {},
  cursorParams = { partial: false },
  onReset,
}: CreatePaginateParams<T>) {
  const onLoadRef = ref<OnLoadFunction<T>>(onLoad as OnLoadFunction<T>);
  const items = ref<(T | SkeletonItem)[]>(generateSkeletonItems(initialPerPage)) as Ref<(T | SkeletonItem)[]>;
  const sortable = provideSortable(sortableFields, initialSort);
  const hasBeenReset = ref(false);
  const cursor = provideCursor(cursorParams);
  const searchable = provideSearchable<T>(searchableFields);
  const { setSortUrlParams } = sortable;
  const { perPage, totalItems, setPaginateUrlParams, resetCursor } = cursor;
  const { setSearchUrlParams } = searchable;

  perPage.value = initialPerPage;

  const loading = ref(true);
  const errorMsg = ref("");
  const errorCode = ref(0);

  const hasError = computed(() => errorMsg.value !== "");

  function generateSkeletonItems(amount: number) {
    const items = [];
    for (let i = 0; i < amount; i++) {
      items.push({ loading: true, skeleton: true } as SkeletonItem);
    }
    return items;
  }

  function clearError() {
    errorMsg.value = "";
    errorCode.value = 0;
  }

  function setOnLoad(newOnLoad: OnLoadFunction<T>) {
    onLoadRef.value = newOnLoad;
  }

  function setError(error: Error | AxiosError<{ "hydra:description"?: string, "detail": string }>) {
    if (error?.message === "Request aborted by user.") {
      return;
    }
    console.error(error);
    let msg: string | null = null;
    let code = 0;

    if ("response" in error && error.response) {
      if (error.response.data && error.response.data?.["hydra:description"]) {
        msg = error.response.data["hydra:description"];
      }
      if (error.response.data && error.response.data?.detail) {
        msg = error.response.data.detail;
      }
      code = error.response.status;
    }

    if (msg === null) {
      msg = error.message;
    }
    errorMsg.value = msg;
    errorCode.value = code;
    items.value = [];
  }

  // async function recalculateTotalItems() {
  //   if (!shouldRecalculateTotalItems) {
  //     return;
  //   }

  //   const params = new URLSearchParams();
  //   setSortUrlParams(params);
  //   setSearchUrlParams(params);

  //   if (typeof loadParams === "function") {
  //     loadParams(params);
  //   }

  //   try {
  //     const res = await onLoadRef.value({ params });
  //     if (res?.data) {
  //       setTotalItems(res.data);
  //     }
  //   } catch (error) {
  //     console.error("Failed to recalculate total items:", error);
  //   }
  // }

  async function resetAndLoad(options = {}) {
    onReset?.();
    hasBeenReset.value = true;
    loading.value = true;
    items.value = generateSkeletonItems(perPage.value);
    resetCursor();

    // await recalculateTotalItems();

    await load(options);
    await nextTick();
    hasBeenReset.value = false;
  }

  function setTotalItems(data: PartialHydraCollection<T> | HydraCollection<T>) {
    if (!("totalItems" in data)) {
      console.warn("The response does not contain \"totalItems\" key.");
      return;
    }

    totalItems.value = data.totalItems;
  }

  async function load(options: Partial<PaginateLoadFnOptions> = {}) {
    loading.value = true;
    clearError();

    if (options.scroll) {
      for (const skeleton of generateSkeletonItems(perPage.value)) {
        items.value.push(skeleton);
      }
    }

    const params = new URLSearchParams();
    setPaginateUrlParams(params);
    setSortUrlParams(params);
    setSearchUrlParams(params);

    if (typeof loadParams === "function") {
      loadParams(params);
    }

    onLoadRef.value({ params })
      .then((res) => {
        const response = res as AxiosResponse<{ member: T[] }>;
        if (!response) {
          return;
        }

        const result = response.data.member;
        setTotalItems(response.data);

        if (!options.scroll) {
          items.value = result;
          return;
        }

        for (const item of result) {
          const skeletonIndex = items.value.findIndex(i => "skeleton" in i && i.skeleton);

          // When no skeleton is found, we push it. (24 skeletons, 25 results, should be impossible.)
          if (skeletonIndex === -1) {
            items.value.push(item);
            continue;
          }

          items.value[skeletonIndex] = item;
        }

        // If less results are returned than the perPage value, we remove the last skeleton.
        let itemsLength = items.value.length;
        while (itemsLength--) {
          const item = items.value[itemsLength];
          if ("skeleton" in item && item.skeleton) {
            items.value.splice(itemsLength, 1);
          }
        }
      })
      .catch(setError)
      .finally(() => {
        loading.value = false;
      });
  }

  onMounted(() => {
    if (runOnMount) {
      load();
    }
  });

  return {
    setOnLoad,
    resetAndLoad,
    load,
    loading,
    hasError,
    hasBeenReset,
    errorMsg,
    errorCode,
    items,
    cursor,
    searchable,
    sortable,
    perPage,
    tableFields: tableFields as Record<keyof T, TableField>,
  };
}

type CreatePaginateParameters<T extends HydraContext> = Parameters<typeof createPaginate<T>>[0];
export function providePaginate<T extends HydraContext>(config: CreatePaginateParameters<T>) {
  const instance = createPaginate<T>(config);
  provide(PaginateKey, instance);
  return instance;
}

type UsePaginate<T extends HydraContext> = ReturnType<typeof createPaginate<T>>;
export function usePaginate<T extends HydraContext>() {
  const instance = inject<UsePaginate<T>>(PaginateKey);

  if (!instance) {
    throw new Error("Run providePaginate before usePaginate.");
  }

  return instance;
}
