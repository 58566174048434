<script setup lang="ts">
import type { VButtonProps } from "./VButton.props";
import { computed } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";

const props = withDefaults(defineProps<VButtonProps>(), {
  type: "button",
  variant: "is-primary",
  size: "is-medium",
  outlined: false,
  loading: false,
  iconLeft: undefined,
  hasPerms: true,
  iconRight: undefined,
  rounded: false,
  disabled: false,
  iconOnly: false,
  textColor: undefined,
});

const emit = defineEmits(["click"]);

const slots = defineSlots<{
  default: () => any
}>();

const isDisabled = computed(() => props.disabled || !props.hasPerms);

const hasContent = computed(() => slots.default && slots.default().length);

function handleClick(event: MouseEvent) {
  if (isDisabled.value || props.loading) {
    return;
  }
  emit("click", event);
}
</script>

<template>
  <ACTooltip
    type="button"
    :allow-change="hasPerms"
  >
    <div
      class="button-container"
      :class="[
        {
          'is-loading': loading,
          'is-disabled': isDisabled,
          'has-icon-only': !hasContent,
        },
        $attrs.class,
      ]"
    >
      <button
        v-bind="$attrs"
        class="v-button"
        :type="type"
        :disabled="isDisabled"
        :style="{ color: textColor }"
        :class="[
          variant,
          size,
          {
            'is-small': size === 'small',
            'is-medium': size === 'medium',
            'is-large': size === 'large',
            'has-icon-left': iconLeft,
            'has-icon-right': iconRight,
            'is-rounded': rounded,
            'is-loading': loading,
            'is-outlined': outlined,
          },
        ]"
        @click="handleClick"
      >
        <FontAwesomeIcon v-if="iconLeft" :icon="iconLeft" class="icon left" />
        <span v-if="hasContent" class="text">
          <slot />
        </span>
        <FontAwesomeIcon
          v-if="iconRight"
          :icon="iconRight"
          class="icon right"
        />
        <FontAwesomeIcon v-if="loading" class="v-loader" spin icon="spinner" />
      </button>
    </div>
  </ACTooltip>
</template>

<style lang="scss" scoped>
.button-container {
  &.has-icon-only {
    .v-button {
      .icon.left {
        margin-right: 0;
      }
    }
  }

  &.is-loading {
    cursor: wait;

    .v-button {
      pointer-events: none;

      .icon,
      .text {
        color: transparent;
      }

      .v-loader {
        position: absolute;
        left: calc(50% - 0.5em);
        top: calc(50% - 0.5em);
        animation-duration: 1s;
      }
    }
  }

  &.is-disabled {
    cursor: not-allowed;

    .v-button {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .v-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color ease 100ms;
    user-select: none;
    border-radius: 3px;
    position: relative;
    outline: none;
    border: none;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.2px;
    min-width: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    }

    &:focus-visible {
      border: 2px solid black;
      outline: initial;
    }

    &.is-rounded {
      border-radius: 20px;
    }

    &.is-loading {
      justify-content: center;
    }

    .v-loader {
      font-size: 16px;
    }

    .icon {
      font-size: 14px;
      flex-shrink: 0;

      &.left {
        margin-right: 8px;
      }

      &.right {
        margin-left: 10px;
      }
    }

    &.is-medium {
      height: 32px;

      &:not(.has-icon-left) {
        padding: 6px 20px;
      }

      &.has-icon-left {
        padding: 6px 15px;
      }

      width: 100%;
      font-size: 14px;
    }

    &.is-small {
      &:not(.has-icon-left) {
        padding: 5px 9px;
      }

      &.has-icon-left {
        padding: 5px 9px;
      }

      width: 100%;
      height: 28px;
      line-height: 24px;
      font-size: 12px;
    }

    &.is-large {
      height: 40px;

      &:not(.has-icon-left) {
        padding: 8px 24px;
      }

      &.has-icon-left {
        padding: 8px 20px;
      }

      width: 100%;
      font-size: 18px;
    }

    &.is-primary {
      background-color: var(--primary);
      color: var(--text-invert);

      &:hover {
        background-color: var(--primary-dark);
      }

      &:active {
        background-color: var(--primary-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--primary);
        color: var(--primary);

        &:hover {
          background-color: var(--primary);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--primary-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-secondary {
      background-color: var(--secondary);
      color: var(--text-invert);

      &:hover {
        background-color: var(--secondary-dark);
      }

      &:active {
        background-color: var(--secondary-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--secondary);
        color: var(--secondary);

        &:hover {
          background-color: var(--secondary);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--secondary-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-success {
      background-color: var(--success);
      color: var(--text-invert);

      &:hover {
        background-color: var(--success-dark);
      }

      &:active {
        background-color: var(--success-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--success);
        color: var(--success);

        &:hover {
          background-color: var(--success);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--success-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-info {
      background-color: var(--info);
      color: var(--text-invert);

      &:hover {
        background-color: var(--info-dark);
      }

      &:active {
        background-color: var(--info-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--info);
        color: var(--info);

        &:hover {
          background-color: var(--info);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--info-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-warning {
      background-color: var(--warning);
      color: var(--text-invert);

      &:hover {
        background-color: var(--warning-dark);
      }

      &:active {
        background-color: var(--warning-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--warning);
        color: var(--warning);

        &:hover {
          background-color: var(--warning);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--warning-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-danger {
      background-color: var(--danger);
      color: var(--text-invert);

      &:hover {
        background-color: var(--danger-dark);
      }

      &:active {
        background-color: var(--danger-darker);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--danger);
        color: var(--danger);

        &:hover {
          background-color: var(--danger);
          color: var(--text-invert);
        }

        &:active {
          background-color: var(--danger-dark);
          color: var(--text-invert);
        }
      }
    }

    &.is-light {
      background-color: var(--input-background);
      color: var(--primary);
      border: 1px solid var(--input-background-active);

      &:hover {
        background-color: var(--input-background-hover);
      }

      &:active {
        background-color: var(--input-background-active);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--input-background);
        color: var(--input-background);

        &:hover {
          background-color: var(--input-background);
          color: var(--primary);
        }

        &:active {
          background-color: var(--input-background-active);
          color: var(--primary);
        }
      }
    }

    &.is-gray {
      background-color: var(--gray-3);
      color: var(--gray-button-text);

      &:hover {
        background-color: var(--gray-1);
      }

      &:active {
        background-color: var(--gray-2);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--gray-3);
        color: var(--gray-3);

        &:hover {
          background-color: var(--gray-3);
          color: var(--gray-button-text);
        }

        &:active {
          background-color: var(--gray-2);
          color: var(--gray-button-text);
        }
      }
    }

    &.is-custom {
      background-color: var(--input-background);
      border: 1px solid var(--input-background-active);

      &:hover {
        background-color: var(--input-background-hover);
      }

      &:active {
        background-color: var(--input-background-active);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--input-background);
        color: var(--input-background);

        &:hover {
          background-color: var(--input-background);
          color: inherit;
        }

        &:active {
          background-color: var(--input-background-active);
          color: inherit;
        }
      }
    }

    &.is-white {
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--input-background-active);

      &:hover {
        background-color: var(--input-background-hover);
      }

      &:active {
        background-color: var(--input-background-active);
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid var(--white);
        color: var(--white);

        &:hover {
          background-color: var(--white);
          color: var(--primary);
        }

        &:active {
          background-color: var(--input-background-active);
          color: var(--primary);
        }
      }
    }
  }
}
</style>
