export enum TaskStatusEnum {
  IN_PROGRESS = 1,
  OPEN = 2,
  WAITING_FOR = 3,
  FINISHED = 4,
  CHECKED = 5,
}

export enum PriorityEnum {
  LOW = 1,
  NORMAL = 2,
  HIGH = 3,
  URGENT = 4,
}

export enum InsideEnum {
  NO = 0,
  YES = 1,
}
