<script setup lang="ts">
import type { BadgeProps } from "./VBadge.types";
import { computed, useAttrs } from "vue";
import { badgeCva, badgePulseCva } from "./VBadge.cva";

const props = withDefaults(defineProps<{
  color?: BadgeProps["color"]
  size?: BadgeProps["size"]
  pulseAnimation?: boolean
}>(), {
  color: "blue",
  size: "md",
  disabled: false,
  pulseAnimation: false,
});

defineSlots<{
  default?: () => any
}>();

const attrs = useAttrs();

const badgeClasses = computed(() => {
  return badgeCva({
    color: props.color,
    size: props.size,
    disabled: typeof attrs.disabled !== "undefined",
    pulse: props.pulseAnimation,
  });
});

const pulseClasses = computed(() => {
  return badgePulseCva({
    color: props.color,
  });
});
</script>

<template>
  <div
    :class="[badgeClasses]"
  >
    <span
      v-if="props.pulseAnimation"
      class="absolute rounded-full"
      :class="[pulseClasses]"
    />
    <slot />
  </div>
</template>

<style scoped>
@keyframes badge-pulse {
  0%, 100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.45;
    transform: translate(-50%, -50%) scale(1.5);
  }
}

.animate-badge-pulse {
  animation: badge-pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
