<script setup lang="ts">
import ReservationDate from "../ReservationDate.vue";
import { useReservationState } from "../useReservationState";

const {
  getGuestCount,
  loading,
  reservationState,
} = useReservationState();

const relevantReservation = computed(() => {
  if (reservationState.value?.current) {
    return { ...reservationState.value.current, type: "current" };
  }

  if (reservationState.value?.lastDeparture) {
    return { ...reservationState.value.lastDeparture, type: "lastDeparture" };
  }

  return null;
});
</script>

<template>
  <div class="flex text-sm text-gray-800 dark:text-gray-200 flex-col">
    <h3 class="font-bold w-full pb-[15px]">
      {{ $t(`tasks.reservation_state.occupation.${relevantReservation?.type}.title`) }}
    </h3>

    <div class="flex flex-col gap-2">
      <template v-if="loading">
        <div v-for="n in 2" :key="n" class="flex items-center gap-3">
          <VSkeleton :width="80" width-unit="%" :height="14" />
        </div>

        <VDivider class="my-1 !mb-1" />

        <div class="flex items-center gap-3">
          <VSkeleton :width="70" width-unit="%" :height="14" />
        </div>

        <div class="flex items-center gap-3">
          <VSkeleton :width="60" width-unit="%" :height="14" />
        </div>
      </template>
      <template v-else>
        <ReservationDate
          v-if="relevantReservation?.startAt"
          :iso-date="relevantReservation.startAt"
          :icon="['fad', 'house-person-return']"
          icon-color="text-green-500"
        />

        <ReservationDate
          v-if="relevantReservation?.endAt"
          :iso-date="relevantReservation.endAt"
          :icon="['fad', 'house-person-leave']"
          icon-color="text-red-500"
          :late-checkout="relevantReservation?.lateCheckout"
        />

        <VDivider class="my-1 !mb-1" />

        <div class="flex items-center gap-3">
          <div class="w-5">
            <FontAwesomeIcon :icon="['fad', 'people-group']" />
          </div>
          <span class="flex-1">
            {{ $t('tasks.reservation.guests', { n: getGuestCount(relevantReservation).guests }) }}<template v-if="getGuestCount(relevantReservation).pets > 0">, {{ $t('tasks.reservation.pets', { n: getGuestCount(relevantReservation).pets }) }}</template>
          </span>
        </div>

        <div v-if="relevantReservation?.data.locale" class="flex items-center gap-3">
          <div class="flex flex-row items-center gap-1">
            <img alt="flag" :src="`/flags/${relevantReservation.data.locale.toUpperCase()}.svg`" class="w-5 mr-2 self-center">
            <span class="flex-1">{{ $t(`base.locales.${relevantReservation.data.locale}`) }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
