<script setup lang="ts">
import { configOverrideDialogVisible, reportVoter, ThemeSetting, useLocationStore, useTheme } from "@verbleif/shared";
import { useLogout } from "@web/features/Auth/Logout/useLogout";
import Verbleif from "@web/features/Navbar/verbleif_v.svg?component";
import NavbarSettings from "@web/features/NavbarSettings/NavbarSettings.vue";
import { useUpdater } from "@web/features/Updater/useUpdater";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { version } from "../../../package.json";
import { useImpersonationStore } from "../Auth/Impersonate/useImpersonationStore";
import { TaskListType } from "../Tasks/TasksRoutes";
import { useActiveTimeEntry } from "../Tasks/TimeEntry/useActiveTimeEntry";

const { t } = useI18n();
const impersonationStore = useImpersonationStore();
const locationStore = useLocationStore();
const { pendingInvites } = locationStore;
const { themeSetting, theme } = useTheme();
const { logout } = useLogout();
const { hasUpdate, installUpdate } = useUpdater();
const router = useRouter();
const route = useRoute();
const {
  currentActiveTimeEntry,
  trackedTime,
  stopActiveTimeEntryInDialog,
} = useActiveTimeEntry();
function goToTimeEntry() {
  if (!currentActiveTimeEntry.value?.task) {
    return;
  }

  const taskId = currentActiveTimeEntry.value?.task.split("/").pop();
  router.replace({
    name: "task-list",
    params: route.name === "task-list"
      ? {
          ...route.params,
          taskId: `t${taskId}`,
        }
      : {
          type: TaskListType.TYPE_TODAY,
          taskId: `t${taskId}`,
        },
    query: route.query,
  });
}

// for when we actually have avatars
// const { hasAvatar, avatarSrc } = usePersonalProfilePicture();

function chooseLocation() {
  const from = { redirect: route.fullPath };
  return router.push({ name: "spaces", params: from, query: from });
}

const nextTheme = computed(() => {
  switch (theme.value) {
    case ThemeSetting.DARK:
      return ThemeSetting.LIGHT;
    case ThemeSetting.LIGHT:
      return ThemeSetting.DARK;
  }

  return ThemeSetting.DARK;
});

function switchTheme() {
  themeSetting.value = nextTheme.value;
}

function clearImpersonation() {
  impersonationStore.reset();
  logout();
}

// const notificationIcon = computed(() =>
//   notificationCount.value > 0 ? ["fas", "bell"] : ["far", "bell"],
// );

function openDocs() {
  // Crisp.chat.setHelpdeskView();
  window.open("https://docs.verbleif.com", "_blank")?.focus();
}
async function stopAndGoToTimeEntry() {
  stopActiveTimeEntryInDialog();
}

const hasReportContext = computed(() => reportVoter.canCrud());
</script>

<template>
  <div class="navbar-container">
    <div class="logo" @click.shift="configOverrideDialogVisible = true">
      <Verbleif />
    </div>
    <div
      v-if="impersonationStore.isImpersonating.value"
      class="nav-item-small stop-impersonation"
      @click="clearImpersonation"
    >
      <FontAwesomeIcon class="icon" :icon="['far', 'mask']" />
      <div class="title">
        {{ t("navbar.stop-impersonation") }}
      </div>
    </div>
    <div
      v-if="locationStore.hasMultipleLocations.value"
      class="nav-item-small"
      :title="t('navbar.change-location')"
      @click="chooseLocation"
    >
      <div class="flex flex-col m-3 gap-3 items-center">
        <VTooltip :disabled="!pendingInvites" :content="pendingInvites ? t('navbar.pending-invites', { count: pendingInvites }) : ''">
          <template #reference>
            <div class="flex flex-row items-center">
              <div class="relative">
                <FontAwesomeIcon class="icon text-2xl" :icon="['fad', 'location-dot']" />
                <div v-if="pendingInvites" class="absolute font-bold -right-3 -top-2.5 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                  {{ pendingInvites }}
                </div>
              </div>
            </div>
          </template>
        </VTooltip>
        <div class="title">
          {{ locationStore.selectedLocationObject.value?.name || "None" }}
        </div>
      </div>
    </div>
    <!-- <div
      class="nav-item-small notifications"
      @click="toggleNotificationSidebar"
    >
      <div class="badge-container">
        <div v-if="notificationCount > 0" class="badge">
          {{ notificationCount }}
        </div>
        <FontAwesomeIcon :icon="notificationIcon" class="icon" />
      </div>
      <div class="title">
        {{ t("navbar.notifications") }}
      </div>
    </div> -->
    <div class="items">
      <div class="navbar-links">
        <router-link v-if="hasReportContext" v-slot="{ navigate, isActive }" custom :to="{ name: 'reports' }">
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'flag']" class="icon" />
            <div class="title">
              {{ t("navbar.reports") }}
            </div>
          </div>
        </router-link>
        <router-link v-slot="{ navigate, isActive }" custom :to="{ name: 'tasks' }">
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'clipboard-list']" class="icon" />
            <div class="title">
              {{ t("navbar.tasks") }}
            </div>
          </div>
        </router-link>
        <router-link v-slot="{ navigate, isActive }" custom :to="{ name: 'settings' }">
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'cog']" class="icon" />
            <div class="title">
              {{ t("navbar.settings") }}
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div
      v-if="currentActiveTimeEntry"
      class="text-white flex items-center gap-1 text-xs px-2 py-1 rounded-lg mb-3 bg-primary-400 hover:bg-primary-700 cursor-pointer"
      @click="goToTimeEntry"
    >
      {{ trackedTime }}
      <div
        class="rounded-full bg-red-400 w-5 h-5 flex items-center justify-center hover:bg-red-500 cursor-pointer"
        @click.stop="stopAndGoToTimeEntry"
      >
        <div class="w-2 h-2 bg-white rounded-sm" />
      </div>
    </div>
    <NavbarSettings />
    <div v-if="hasUpdate" class="nav-item-small mt-3 py-4 bg-primary-400 hover:bg-primary-700 cursor-pointer update-available">
      <FontAwesomeIcon class="icon" icon="sync" @click="installUpdate" />
      <div class="title">
        {{ t("navbar.update-available") }}
      </div>
    </div>
    <div class="flex gap-4 m-3">
      <div class="cursor-pointer text-white" @click="openDocs">
        <FontAwesomeIcon class="text-xs" :icon="['fa', 'book']" />
      </div>
      <div class="theme-switch cursor-pointer" @click="switchTheme">
        <FontAwesomeIcon class="text-xs icon" icon="adjust" />
      </div>
    </div>

    <div class="logout" @click="() => logout()">
      {{ t("base.logout") }}
    </div>
    <div class="text-white text-xs font-bold">
      v{{ version }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  word-wrap: anywhere;
}
.navbar-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  width: 90px;
  height: 100vh;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  padding: 25px 0;

  .logo {
    margin-bottom: 15px;

    &::v-deep(.v) {
      fill: var(--text-invert);
    }
  }

  .stop-impersonation {
    font-weight: bold;

    .icon {
      font-size: 25px;
      transition: none;
    }

    &:hover {
      color: var(--secondary);
    }
  }

  .items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .navbar-links {
      margin-top: 25px;
    }

    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      padding: 20px 0;
      transition: ease all 100ms;
      text-decoration: none;
      gap: 15px;

      &:hover,
      &.router-link-exact-active,
      &.is-active {
        background-color: var(--primary-darker);
      }

      &.router-link-exact-active {
        .title {
          font-weight: bold;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        color: var(--text-invert);
        font-size: 30px;
      }

      .title {
        text-align: center;
        word-break: break-all;
        max-width: 85%;
        color: var(--text-invert);
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .nav-item-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-invert);
    width: 100%;
    transition: 100ms;
    text-align: center;
    cursor: pointer;

    &:hover {
      transition: 100ms;
      background-color: var(--primary-darker);
    }

    .title {
      font-size: 11px;
    }
  }

  .notifications {
    .badge-container {
      position: relative;

      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 0 2px;
        height: 16px;
        min-width: 16px;
        text-align: center;
        vertical-align: center;
        line-height: 17px;
        font-weight: bold;
        background-color: theme("colors.danger.600");
        font-size: 10px;
        color: var(--text-invert);
        border-radius: 20px;
        z-index: 10;
      }
    }
  }

  .theme-switch {
    color: var(--text-invert);

    .icon {
      transition: transform 100ms ease-in-out;
    }

    .title {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  .update-available {
    font-size: 20px;
    position: relative;
    cursor: pointer;
    -webkit-animation: blink 1s infinite;
    -moz-animation: blink 1s infinite;
    -o-animation: blink 1s infinite;
    animation: blink 1s infinite;
  }

  @-webkit-keyframes blink {
    0%,
    49% {
      color: var(--text-invert);
    }
    50%,
    100% {
      color: var(--secondary);
    }
  }

  .logout {
    margin: 2px 0;
    color: var(--text-invert);
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: #e0e0e0;
    }
  }

  .version {
    color: var(--text-invert);
    font-size: 12px;
  }
}

@media only screen and (max-height: 700px) {
  .navbar-container {
    .items {
      .notification-item {
        .title {
          display: none;
        }
      }

      .item {
        padding: 15px 0;

        .icon {
          font-size: 25px;
        }
      }
    }
  }
}
</style>
