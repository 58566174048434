<script setup lang="ts">
import type { AccordionSize } from "./types";
import { computed, nextTick, ref, watch } from "vue";
import { useAccordionPanelState } from "./useAccordionPanelState";
import { useAccordionState } from "./useAccordionState";

interface Props {
  size?: AccordionSize
}

const props = defineProps<Props>();

const { modelValue, size: contextSize } = useAccordionState();
const { value } = useAccordionPanelState();

const isActive = computed(() => {
  const currentValue = modelValue.value;
  if (Array.isArray(currentValue)) {
    return currentValue.includes(value);
  }
  return currentValue === value;
});
const contentRef = ref<HTMLDivElement | null>(null);
const contentHeight = ref(0);

const sizeClasses = computed(() => {
  const currentSize = props.size || contextSize.value;
  switch (currentSize) {
    case "xs":
      return "p-2";
    case "sm":
      return "p-3";
    case "md":
      return "p-4";
    case "lg":
      return "p-6";
    default:
      return "p-4";
  }
});

// Update content height when active state changes
watch(isActive, async (newValue) => {
  if (newValue) {
    await nextTick();
    if (contentRef.value) {
      contentHeight.value = contentRef.value.scrollHeight;
    }
  }
}, { immediate: true });
</script>

<template>
  <div
    ref="contentRef"
    class="overflow-hidden transition-all duration-300 ease-in-out"
    :style="{
      height: isActive ? `${contentHeight}px` : '0px',
      opacity: isActive ? 1 : 0,
    }"
  >
    <div :class="sizeClasses">
      <slot />
    </div>
  </div>
</template>
