<script setup lang="ts">
import { useProvideAccordionPanelState } from "./useAccordionPanelState";
import { useAccordionState } from "./useAccordionState";

interface Props {
  value: string | number
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const { onPanelClick } = useAccordionState();

useProvideAccordionPanelState({
  value: props.value,
  disabled: props.disabled,
});

function handleClick() {
  if (!props.disabled) {
    onPanelClick(props.value);
  }
}
</script>

<template>
  <div class="w-full" @click="handleClick">
    <slot />
  </div>
</template>
