import type { AppConfig } from "../../composables/ConfigStore/useConfigStore";

export type ConfigRecord = Record<string, AppConfig>;
export type EnvironmentKey = keyof ConfigRecord;

export const config = <{
  cms: ConfigRecord
  web: ConfigRecord
}>{
  cms: {
    "localhost": {
      apiUrl: "https://api.verbleif.localhost",
      mercureUrl: "https://mercure.verbleif.localhost:81",
      cdnUrl: "https://cdn.verbleif.localhost",
      webUrl: "http://verbleif.localhost:5173",
      appEnv: "local",
      loginUrl: "https://login.verbleif.dev",
      loginRedirect: "http://cms.verbleif.localhost:5174",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",

    },
    "sherin": {
      apiUrl: "https://api.sherin.vitrion.dev",
      mercureUrl: "https://mercure.sherin.vitrion.dev",
      loginUrl: "https://login.sherin.vitrion.dev",
      loginRedirect: "http://app.verbleif.localhost:5173",
      cdnUrl: "https://cdn.sherin.vitrion.dev",
      appEnv: "dev",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    "cms.verbleif.dev": {
      apiUrl: "https://api.verbleif.dev",
      mercureUrl: "https://mercure.verbleif.dev",
      cdnUrl: "https://cdn.verbleif.dev",
      webUrl: "https://app.verbleif.dev",
      appEnv: "dev",
      loginUrl: "https://login.verbleif.dev",
      loginRedirect: "http://cms.verbleif.dev",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    "cms.staging.verbleif.com": {
      apiUrl: "https://api.staging.verbleif.com",
      mercureUrl: "https://mercure.staging.verbleif.com",
      cdnUrl: "https://cdn.staging.verbleif.com",
      webUrl: "https://app.staging.verbleif.com",
      appEnv: "staging",
      loginUrl: "https://login.staging.verbleif.com",
      loginRedirect: "http://cms.staging.verbleif.com",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    "cms.verbleif.com": {
      apiUrl: "https://api.verbleif.com",
      mercureUrl: "https://mercure.verbleif.com",
      cdnUrl: "https://cdn.verbleif.com",
      webUrl: "https://app.verbleif.com",
      appEnv: "production",
      loginUrl: "https://login.verbleif.com",
      loginRedirect: "http://cms.verbleif.com",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
  },
  web: {
    localhost: {
      apiUrl: "https://api.verbleif.dev",
      mercureUrl: "https://mercure.verbleif.localhost:81",
      cdnUrl: "https://cdn.verbleif.localhost",
      appEnv: "local",
      loginUrl: "https://login.verbleif.dev",
      loginRedirect: "http://app.verbleif.localhost:5173",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    sherin: {
      apiUrl: "https://api.sherin.vitrion.dev",
      mercureUrl: "https://mercure.sherin.vitrion.dev",
      loginUrl: "https://login.sherin.vitrion.dev",
      loginRedirect: "http://app.verbleif.localhost:5173",
      cdnUrl: "https://cdn.sherin.vitrion.dev",
      appEnv: "dev",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    testing: {
      apiUrl: "https://api.verbleif.dev",
      mercureUrl: "https://mercure.verbleif.dev",
      loginUrl: "https://login.verbleif.dev",
      loginRedirect: "http://app.verbleif.dev",
      cdnUrl: "https://cdn.verbleif.dev",
      appEnv: "dev",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
    },
    staging: {
      apiUrl: "https://api.staging.verbleif.com",
      mercureUrl: "https://mercure.staging.verbleif.com",
      loginUrl: "https://login.staging.verbleif.com",
      loginRedirect: "http://app.staging.verbleif.com",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
      cdnUrl: "https://cdn.staging.verbleif.com",
      appEnv: "staging",
    },
    production: {
      apiUrl: "https://api.verbleif.com",
      mercureUrl: "https://mercure.verbleif.com",
      loginUrl: "https://login.verbleif.com",
      loginRedirect: "http://app.verbleif.com",
      loginClientId: "f3bee0a0f13225abc6216d29ffce28a5",
      cdnUrl: "https://cdn.verbleif.com",
      appEnv: "production",
    },
  },
};
