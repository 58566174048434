<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineEmits, defineProps } from "vue";
import VButton from "../Button/VButton.vue";
import Dialog from "./VDialog.vue";

const {
  id = undefined,
  description = "",
  size = "confirm",
  mask = true,
  type,
  confirmLoading = false,
} = defineProps<{
  id?: string
  title: string
  description?: string
  confirmText: string
  cancelText: string
  icon: string | string[]
  size?: string
  type: string
  confirmLoading?: boolean
  mask?: boolean
}>();

const emit = defineEmits<{
  (e: "confirm"): void
  (e: "cancel"): void
}>();

const visible = defineModel<boolean>("visible");

// Define the unique ID generator
let uniqueIdCounter = 0;

function generateUniqueId(prefix = "id"): string {
  uniqueIdCounter += 1;
  return `${prefix}-${Date.now()}-${uniqueIdCounter}`;
}

const dialogId = id || generateUniqueId("confirm-dialog");

function getIconClass() {
  switch (type) {
    case "info": return "fa-info-circle";
    case "warning": return "fa-exclamation-triangle";
    case "danger": return "fa-exclamation-circle";
    case "success": return "fa-check-circle";
    default: return "fa-question-circle";
  }
}

function handleClose() {
  visible.value = false;
  emit("cancel");
}
</script>

<template>
  <Dialog
    :id="dialogId"
    :size="size"
    :mask="mask"
    :visible="!!visible"
    @update:visible="(value: boolean) => visible = value"
    @close="handleClose"
  >
    <template #title>
      <slot name="title">
        <strong class="text-xl">{{ title }}</strong>
      </slot>
    </template>
    <template #content>
      <div class="confirm-dialog-content">
        <FontAwesomeIcon v-if="icon || type" :icon="icon || ['fas', getIconClass()]" :class="type" size="2x" />
        <div class="description">
          {{ description }}
          <slot name="content" />
        </div>
      </div>
    </template>
    <template #footer>
      <VButton variant="is-gray" rounded @click="handleClose">
        {{ cancelText }}
      </VButton>
      <VButton
        :variant="`is-${type}`"
        :loading="confirmLoading"
        rounded
        @click="$emit('confirm')"
      >
        {{ confirmText }}
      </VButton>
    </template>
  </Dialog>
</template>

<style scoped lang="scss">
@use "./Dialog.scss";

@mixin type-color($type) {
  .#{$type} {
    color: var(--#{$type});
  }
}

.confirm-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.description {
  margin-top: 1rem;
  text-align: center;
  width: 100%;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-secondary {
  background-color: #ecf0f1;
  color: #34495e;
}

.btn-secondary:hover {
  background-color: #bdc3c7;
}

.btn-info {
  background-color: #3498db;
  color: white;
}

.btn-info:hover {
  background-color: #2980b9;
}

.btn-warning {
  background-color: #f39c12;
  color: white;
}

.btn-warning:hover {
  background-color: #d35400;
}

.btn-danger {
  background-color: #e74c3c;
  color: white;
}

.btn-danger:hover {
  background-color: #c0392b;
}

.btn-success {
  background-color: #4CAF50;
  color: white;
}

.btn-success:hover {
  background-color: #43A047;
}

@include type-color('info');
@include type-color('warning');
@include type-color('danger');
@include type-color('primary');
@include type-color('secondary');
@include type-color('success');
</style>
