<script setup lang="ts">
import type { ReservationHydraItem, TransitionState } from "@verbleif/lib";
import moment from "moment";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const visible = ref(false);
const currentReservation = ref<{
  reservation: ReservationHydraItem | null
  state: TransitionState | null
} | null>(null);

const currentMoment = computed(() => moment().locale(locale.value));

function formatDate(isoDate: string) {
  if (!isoDate) {
    return { date: "", time: "", isToday: false };
  }

  const momentDate = moment(isoDate).locale(locale.value);
  if (!momentDate.isValid()) {
    return { date: "", time: "", isToday: false };
  }

  const time = momentDate.format("HH:mm");
  const isToday = momentDate.isSame(currentMoment.value, "day");
  const formattedDate = momentDate.format("dddd, D MMMM");

  return {
    date: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
    time,
    isToday,
  };
}

async function show({
  currentReservation: currentReservationProp,
}: {
  currentReservation?: {
    reservation: ReservationHydraItem | null
    state: TransitionState | null
  } | null
}) {
  visible.value = true;
  currentReservation.value = {
    reservation: currentReservationProp?.reservation || null,
    state: currentReservationProp?.state || null,
  };
}

function hide() {
  visible.value = false;
  currentReservation.value = null;
}

defineExpose({
  show,
  hide,
});

// Get total guests count
const getGuestCount = computed(() => {
  const reservationData = currentReservation.value?.reservation?.data;
  if (!reservationData) {
    return {
      guests: 0,
      pets: 0,
    };
  }

  return {
    guests: (reservationData.adultAmount || 0)
      + (reservationData.adolescentAmount || 0)
      + (reservationData.babyAmount || 0)
      + (reservationData.childAmount || 0),
    pets: reservationData.petAmount || 0,
  };
});
</script>

<template>
  <VDialog
    id="current-reservation-dialog"
    :visible="visible"
    mask
    @close="hide"
  >
    <template #title>
      <strong class="text-xl text-gray-800 dark:text-gray-200">
        {{ t("tasks.reservation.details") }}
      </strong>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-green-500">
              <FontAwesomeIcon :icon="['fad', 'house-person-return']" />
            </div>
            <span class="flex-1 text-gray-800 dark:text-gray-200 font-medium">{{ t("reservations.arriveAt") || "Aankomst" }}</span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-400">
              <FontAwesomeIcon :icon="['fad', 'calendar-day']" />
            </div>
            <template v-if="currentReservation?.reservation?.startAt">
              <template v-if="formatDate(currentReservation?.reservation?.startAt).isToday">
                <span class="flex-1 text-blue-600">{{ t("base.today") }}, {{ formatDate(currentReservation?.reservation?.startAt).time }}</span>
              </template>
              <template v-else>
                <span class="flex-1 text-gray-700 dark:text-gray-300">
                  {{ formatDate(currentReservation?.reservation?.startAt).date }},
                  {{ formatDate(currentReservation?.reservation?.startAt).time }}
                </span>
              </template>
            </template>
          </div>
        </div>

        <VDivider class="my-1 !mb-1" />

        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-red-500">
              <FontAwesomeIcon :icon="['fad', 'house-person-leave']" />
            </div>
            <span class="flex-1 text-gray-800 dark:text-gray-200 font-medium">{{ t("reservations.leaveAt") || "Vertrek" }}</span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-400">
              <FontAwesomeIcon :icon="['fad', 'calendar-day']" />
            </div>
            <template v-if="currentReservation?.reservation?.endAt">
              <template v-if="formatDate(currentReservation?.reservation?.endAt).isToday">
                <span class="flex-1 text-blue-600">{{ t("base.today") }}, {{ formatDate(currentReservation?.reservation?.endAt).time }}</span>
              </template>
              <template v-else>
                <span class="flex-1 text-gray-700 dark:text-gray-300">
                  {{ formatDate(currentReservation?.reservation?.endAt).date }},
                  {{ formatDate(currentReservation?.reservation?.endAt).time }}
                </span>
              </template>
            </template>
          </div>

          <div v-if="true" class="flex items-center gap-1">
            <div class="w-6 text-red-600">
              <FontAwesomeIcon :icon="['fad', 'clock']" />
            </div>
            <span class="flex-1 text-red-600">{{ t("reservations.lateCheckout") }}</span>
          </div>
        </div>

        <VDivider class="my-1 !mb-1" />

        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-600">
              <FontAwesomeIcon :icon="['fad', 'people-group']" />
            </div>
            <span class="flex-1 text-gray-700 dark:text-gray-300">
              {{ t("tasks.reservation.guests", { n: getGuestCount.guests }) || `${getGuestCount.guests} personen` }}
            </span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-600">
              <FontAwesomeIcon :icon="['fad', 'paw']" />
            </div>
            <span v-if="getGuestCount.pets > 0" class="flex-1 text-gray-700 dark:text-gray-300">
              {{ t("tasks.reservation.pets", { n: getGuestCount.pets }) || `${getGuestCount.pets} huisdieren` }}
            </span>
            <span v-else class="flex-1 text-gray-700 dark:text-gray-300">{{ t("tasks.reservation.no_pets") || "Geen huisdieren" }}</span>
          </div>

          <div v-if="currentReservation?.reservation?.data?.locale" class="flex items-center gap-1">
            <div class="w-6">
              <img
                :src="`/flags/${currentReservation?.reservation?.data?.locale.toUpperCase()}.svg`"
                :alt="currentReservation?.reservation?.data?.locale"
                class="w-5 h-auto"
              >
            </div>
            <span class="flex-1 text-gray-700 dark:text-gray-300">{{ t(`base.locales.${currentReservation?.reservation?.data?.locale}`) || currentReservation?.reservation?.data?.locale }}</span>
          </div>
        </div>
      </div>
    </template>
  </VDialog>
</template>

<style scoped>

</style>
