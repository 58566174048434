<script setup lang="ts">
import type { CSSProperties } from "vue";
import { defineProps, ref, watch } from "vue";

const {
  text,
  variant = "is-primary",
  closeable = false,
  icon = "",
  disabled = false,
  color = null,
  backgroundColor = null,
  size = "small",
  cursor = false,
  iconLess = false,
  selectable = false,
  dataValue = "",
} = defineProps<{
  text?: string
  variant?: string
  closeable?: boolean
  icon?: string
  disabled?: boolean
  color?: string | null
  backgroundColor?: string | null
  size?: string
  cursor?: boolean
  iconLess?: boolean
  selectable?: boolean
  dataValue?: string
}>();

const emit = defineEmits<{
  close: []
}>();

const model = defineModel<boolean>("modelValue", { default: false });

const isChecked = ref(model.value);

watch(() => model.value, (newValue) => {
  isChecked.value = newValue;
});

function toggleCheck() {
  if (!selectable) {
    return;
  }
  isChecked.value = !isChecked.value;
  model.value = isChecked.value;
}

const tagStyle = ref<CSSProperties>({
  backgroundColor: backgroundColor ?? "",
  color: color ?? "",
});
</script>

<template>
  <div
    :style="tagStyle"
    :data-value="dataValue"
    class="inline-flex whitespace-nowrap font-roboto w-fit gap-2.5"
    :class="[
      {
        'cursor-pointer': cursor || selectable,
        'bg-primary-100 text-primary-600 dark:bg-primary-600 dark:text-white': variant === 'is-primary',
        'bg-secondary-100 text-secondary-600 dark:bg-secondary-600 dark:text-white': variant === 'is-secondary',
        'bg-gray-300 text-gray-800 dark:bg-dark-800 dark:text-white': variant === 'is-text-color',
        'bg-blue-100 text-blue-600 dark:bg-blue-600 dark:text-white': variant === 'is-info',
        'bg-warning-100 text-warning-600 dark:bg-warning-600 dark:text-white': variant === 'is-warning',
        'bg-danger-100 text-danger-600 dark:bg-danger-600 dark:text-white': variant === 'is-danger',
        'bg-success-100 text-success-600 dark:bg-success-600 dark:text-white': variant === 'is-success',
        'bg-gray-200 text-gray-700 dark:bg-dark-700 dark:text-white': variant === 'is-gray',
        'bg-primary-400 text-white': isChecked,
      },
      {
        'text-xs font-medium leading-[14px] rounded-[9px] px-2 py-0': size === 'extra-small',
        'text-sm font-bold leading-[15px] rounded-[9px] px-2 py-0.5': size === 'small',
        'text-base font-bold leading-[17px] rounded-xl px-3 py-1': size === 'medium',
        'text-xl font-bold leading-[19px] rounded-[15px] px-4 py-1.5': size === 'large',
      },
    ]"
    @click="toggleCheck"
  >
    <div v-if="icon && !iconLess" class="cursor-pointer transition-transform duration-250 hover:rotate-90 active:scale-110">
      <slot name="icon">
        <FontAwesomeIcon :key="icon" :icon="icon" />
      </slot>
    </div>

    <div v-if="text" class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
      {{ text }}
    </div>

    <div v-if="closeable && !disabled && !iconLess" class="cursor-pointer transition-transform duration-250 hover:rotate-90 active:scale-110">
      <FontAwesomeIcon data-test="close-icon" icon="times" @click.stop="emit('close')" />
    </div>
  </div>
</template>
