import type { UserStatusHydraItem } from "@verbleif/lib";
import { computed, watch } from "vue";
import { useAuthStore } from "../AuthStore/useAuthStore";
import { useLocationStore } from "../LocationStore/useLocationStore";
import { useSharedStore } from "../SharedStore/useSharedStore";
import { PermissionService } from "./PermissionService";
import { ActivityCommentVoter } from "./Voters/ActivityCommentVoter";
import { AdminVoter } from "./Voters/AdminVoter";
import { AppSubscriptionVoter } from "./Voters/AppSubscriptionVoter";
import { AppVoter } from "./Voters/AppVoter";
import { LegacyNotificationUserSubscriptionVoter } from "./Voters/LegacyNotificationUserSubscriptionVoter";
import { LocationVoter } from "./Voters/LocationVoter";
import { MediaObjectMetaDataVoter } from "./Voters/MediaObjectMetaDataVoter";
import { PermissionTemplateGroupVoter } from "./Voters/PermissionTemplateGroupVoter";
import { PropertyGroupVoter } from "./Voters/PropertyGroupVoter";
import { PropertyVoter } from "./Voters/PropertyVoter";
import { ReportFieldVoter } from "./Voters/ReportFieldVoter";
import { ReportTopicVoter } from "./Voters/ReportTopicVoter";
import { ReportVoter } from "./Voters/ReportVoter";
import { ReservationVoter } from "./Voters/ReservationVoter";
import { ShortcutVoter } from "./Voters/ShortcutVoter";
import { SortVoter } from "./Voters/SortVoter";
import { TaskFieldVoter } from "./Voters/TaskFieldVoter";
import { TaskProductVoter } from "./Voters/TaskProductVoter";
import { TaskTimeEntryVoter } from "./Voters/TaskTimeEntryVoter";
import { TaskVoter } from "./Voters/TaskVoter";
import { UserGroupVoter } from "./Voters/UserGroupVoter";
import { UserVoter } from "./Voters/UserVoter";

export const permissionService = new PermissionService({
  setUserPermissions: () => useAuthStore().user.value?.permissions ?? [],
  setUserIri: () => useAuthStore().user.value?.["@id"] ?? null,
  setMode: () => useLocationStore().mode.value,
  setDefaultLocation: () => useLocationStore().modeSelectedLocation.value,
  setDefaultClient: () => useLocationStore().modeSelectedClient.value,
  setLocationClientMap: () => {
    const { locationsList } = useLocationStore();
    return locationsList.value.reduce((map, location) => {
      map.set(location["@id"], location.client);
      return map;
    }, new Map<string, string>());
  },
  setUsersData: () => {
    const { usersList } = useSharedStore();
    return usersList.value.reduce((map, user) => {
      map.set(user["@id"], { statuses: user.statuses });
      return map;
    }, new Map<string, { statuses: UserStatusHydraItem[] }>());
  },
});

export const appSubscriptionVoter = new AppSubscriptionVoter(permissionService);
export const appVoter = new AppVoter(permissionService);
export const locationVoter = new LocationVoter(permissionService);
export const permissionTemplateGroupVoter = new PermissionTemplateGroupVoter(permissionService);
export const propertyGroupVoter = new PropertyGroupVoter(permissionService);
export const propertyVoter = new PropertyVoter(permissionService);
export const shortcutVoter = new ShortcutVoter(permissionService);
export const adminVoter = new AdminVoter(permissionService);
export const sortVoter = new SortVoter(permissionService);
export const userGroupVoter = new UserGroupVoter(permissionService);
export const userVoter = new UserVoter(permissionService);
export const taskVoter = new TaskVoter(permissionService);
export const reportVoter = new ReportVoter(permissionService);
export const reportTopicVoter = new ReportTopicVoter(permissionService);
export const taskTimeEntryVoter = new TaskTimeEntryVoter(permissionService, taskVoter);
export const taskProductVoter = new TaskProductVoter(permissionService, taskVoter);
export const activityCommentVoter = new ActivityCommentVoter(permissionService, taskVoter, reportVoter);
export const mediaObjectMetaDataVoter = new MediaObjectMetaDataVoter(permissionService, taskVoter, reportVoter);
export const taskFieldVoter = new TaskFieldVoter(permissionService, taskVoter);
export const reportFieldVoter = new ReportFieldVoter(permissionService, reportVoter);
export const reservationVoter = new ReservationVoter(permissionService);
export const legacyNotificationUserSubscriptionVoter = new LegacyNotificationUserSubscriptionVoter(permissionService);
export const isAdmin = computed(() => adminVoter.isAdmin());

export function usePermsWatch() {
  watch([
    () => useAuthStore().isAuthenticated.value,
    () => useAuthStore().user.value?.permissions,
    () => useLocationStore().selectedLocation.value,
  ], ([isAuthenticated, authPermissions, selectedLocation]) => {
    if (!isAuthenticated) {
      console.log("%c [usePermsWatch] not authenticated", "color: #FFA500; font-weight: bold;");
      return;
    }
    if (!authPermissions) {
      console.log("%c [usePermsWatch] no permissions", "color: #FFA500; font-weight: bold;");
      return;
    }
    if (!selectedLocation) {
      console.log("%c [usePermsWatch] no location", "color: #FFA500; font-weight: bold;");
      return;
    }

    console.log("%c [usePermsWatch] init", "color: #4CAF50; font-weight: bold;");
    permissionService.init();
  }, {
    immediate: true,
    deep: true,
  });
}
