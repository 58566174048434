<script setup lang="ts">
import type { EnvironmentKey } from "./configPresets";
import { disableMercure } from "@verbleif/lib";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { toast } from "vue-sonner";
import { isAdmin, isCms, useConfigStore, useSystemStore } from "../../composables";
import { useAuthStore } from "../../composables/AuthStore/useAuthStore";
import { useLocationStore } from "../../composables/LocationStore/useLocationStore";
import { config } from "./configPresets";

const configOverrideDialog = defineModel<boolean>();
const enablePropertyStateDebug = defineModel<boolean>("enablePropertyStateDebug");

const authStore = useAuthStore();
const locationStore = useLocationStore();
const systemStore = useSystemStore();
const configStore = useConfigStore();
const { t } = useI18n();

const loading = ref(false);

async function setPreset(presetName: EnvironmentKey) {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    const preset = config[isCms.value ? "cms" : "web"][presetName];
    if (!preset) {
      throw new Error(`Preset not found: ${presetName}`);
    }

    configStore.setOverrideConfig({ presetName, ...preset });
    toast.info(t("settings.configOverride.overrideApplied"));
  } finally {
    loading.value = false;
  }
}

async function reset() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    configStore.reset();
    toast.info(t("settings.configOverride.resetSuccessful"));
  } finally {
    loading.value = false;
  }
}

function toggleMercure() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    disableMercure.value = !disableMercure.value;
    toast.info(t(`settings.configOverride.${disableMercure.value ? "disableMercure" : "enableMercure"}`));
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <div class="mb-6">
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
        {{ t("settings.configOverride.environmentPresets") }}
      </div>
      <div class="flex flex-wrap gap-2">
        <VButton
          :variant="!!configStore.config.value?.presetName ? 'is-info is-outlined' : 'is-info'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => configOverrideDialog = true"
        >
          {{ t("settings.configOverride.custom") }}
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'localhost' ? 'is-primary' : 'is-primary is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => setPreset('localhost')"
        >
          {{ t("settings.configOverride.localhost") }}
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'sherin' ? 'is-primary' : 'is-primary is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => setPreset('sherin')"
        >
          Sherin
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'testing' ? 'is-primary' : 'is-primary is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => setPreset('testing')"
        >
          {{ t("settings.configOverride.dev") }}
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'staging' ? 'is-warning' : 'is-warning is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => setPreset('staging')"
        >
          {{ t("settings.configOverride.staging") }}
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'production' ? 'is-danger' : 'is-danger is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => setPreset('production')"
        >
          {{ t("settings.configOverride.production") }}
        </VButton>
        <VButton
          :variant="configStore.config.value?.presetName === 'production' ? 'is-danger' : 'is-danger is-outlined'"
          size="is-small"
          :loading="loading"
          class="w-full sm:w-auto"
          @click="() => reset()"
        >
          {{ t("settings.configOverride.reset") }}
        </VButton>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
    <!-- Admin Section -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.configOverride.admin") }}
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex items-center gap-2 px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <FontAwesomeIcon
              :icon="['fas', 'user-shield']"
              class="text-primary-500 w-[18px] h-[18px]"
              size="lg"
            />
            <span class="font-medium text-gray-700 dark:text-gray-300 text-sm">{{ t("settings.configOverride.admin_status") }}</span>
            <div class="flex-1" />
            <span
              class="px-2 py-0.5 rounded text-xs font-medium"
              :class="isAdmin ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300'"
            >
              {{ isAdmin ? t("settings.configOverride.admin_enabled") : t("settings.configOverride.admin_disabled") }}
            </span>
          </div>
          <div class="flex items-center gap-2 px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <FontAwesomeIcon
              :icon="['fad', 'server']"
              class="text-primary-500 w-[18px] h-[18px]"
              size="lg"
            />
            <span class="font-medium text-gray-700 dark:text-gray-300 text-sm">Instance</span>
            <div class="flex-1" />
            <span
              class="px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300"
            >
              {{ isCms ? "CMS" : "WEB" }}
            </span>
          </div>
          <div class="flex items-center gap-2 px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <FontAwesomeIcon
              :icon="['far', 'bug']"
              class="text-primary-500 w-[18px] h-[18px]"
              size="lg"
            />
            <span class="font-medium text-gray-700 dark:text-gray-300 text-sm">{{ t("settings.configOverride.debugMode") }}</span>
            <div class="flex-1" />
            <VCheckbox
              v-model="configStore.debugMode.value"
              name="debugMode"
              :value="configStore.debugMode.value"
              :true-value="true"
              :false-value="false"
              class="dark:text-gray-300"
              @update:model-value="configStore.debugMode.value = $event as boolean"
            />
          </div>
          <div v-if="!isCms" class="flex items-center gap-2 px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <FontAwesomeIcon
              :icon="['far', 'bug']"
              class="text-primary-500 w-[18px] h-[18px]"
              size="lg"
            />
            <span class="font-medium text-gray-700 dark:text-gray-300 text-sm">Property State Debug</span>
            <div class="flex-1" />
            <VCheckbox
              v-model="enablePropertyStateDebug"
              name="enablePropertyStateDebug"
              :value="enablePropertyStateDebug"
              :true-value="true"
              :false-value="false"
              class="dark:text-gray-300"
              @update:model-value="enablePropertyStateDebug = $event as boolean"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Auth Store Section -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.configOverride.authStore") }}
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-wrap gap-2">
            <VButton
              variant="is-danger is-outlined"
              size="is-small"
              icon-left="undo"
              :loading="loading"
              class="flex-1 sm:flex-none"
              @click="() => authStore.reset()"
            >
              {{ t("settings.configOverride.reset") }}
            </VButton>
            <VButton
              variant="is-danger is-outlined"
              size="is-small"
              icon-left="undo"
              :loading="loading"
              class="flex-1 sm:flex-none"
              @click="() => authStore.token.value = '123'"
            >
              {{ t("settings.configOverride.fake_token") }}
            </VButton>
            <VButton
              variant="is-danger is-outlined"
              size="is-small"
              icon-left="undo"
              :loading="loading"
              class="flex-1 sm:flex-none"
              @click="() => authStore.refreshToken.value = '123'"
            >
              {{ t("settings.configOverride.fake_refresh_token") }}
            </VButton>
          </div>
          <div class="space-y-2 p-3 border border-gray-200 dark:border-gray-700 rounded">
            <VCheckbox
              name="hasToken"
              :value="authStore.hasToken.value"
              :model-value="authStore.hasToken.value"
              :label-right="t('settings.configOverride.hasToken')"
              :disabled="true"
              class="dark:text-gray-300"
            />
            <VCheckbox
              name="isAuthenticated"
              :value="authStore.isAuthenticated.value"
              :model-value="authStore.isAuthenticated.value"
              :label-right="t('settings.configOverride.isAuthenticated')"
              :disabled="true"
              class="dark:text-gray-300"
            />
            <VCheckbox
              name="isRemembered"
              :value="authStore.isRemembered.value"
              :model-value="authStore.isRemembered.value"
              :label-right="t('settings.configOverride.isRemembered')"
              :disabled="true"
              class="dark:text-gray-300"
            />
          </div>
          <div class="px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <span class="text-sm text-gray-700 dark:text-gray-300">{{ t("settings.configOverride.returnUrl") }}: {{ authStore.returnUrl.value || "-" }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Location Store Section -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.configOverride.locationStore") }}
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-wrap gap-2">
            <VButton
              variant="is-danger is-outlined"
              size="is-small"
              icon-left="undo"
              :loading="loading"
              class="w-full sm:w-auto"
              @click="() => locationStore.reset()"
            >
              {{ t("settings.configOverride.reset") }}
            </VButton>
          </div>
          <div class="space-y-2 p-3 border border-gray-200 dark:border-gray-700 rounded">
            <VCheckbox
              v-model="locationStore.hasMultipleLocations.value"
              name="hasMultipleLocations"
              :value="locationStore.hasMultipleLocations.value"
              :label-right="t('settings.configOverride.hasMultipleLocations')"
              :disabled="true"
              class="dark:text-gray-300"
            />
          </div>
          <div class="px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
            <div class="flex flex-col gap-2 text-sm text-gray-700 dark:text-gray-300">
              <span>{{ t("settings.configOverride.defaultLocation") }}: {{ locationStore.defaultLocation.value || "-" }}</span>
              <span>{{ t("settings.configOverride.locationCount") }}: {{ locationStore.locations.value?.length || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- System Store Section -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.configOverride.systemStore") }}
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-wrap gap-2">
            <VButton
              variant="is-danger is-outlined"
              size="is-small"
              icon-left="undo"
              class="w-full sm:w-auto"
              @click="() => systemStore.reset()"
            >
              {{ t("settings.configOverride.reset") }}
            </VButton>
          </div>
        </div>
      </div>
    </div>

    <!-- Config Store Section -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.configOverride.configStore") }}
        </div>
        <div class="flex flex-wrap gap-2">
          <VButton
            :variant="disableMercure ? 'is-danger is-outlined' : 'is-info is-outlined'"
            size="is-small"
            :loading="loading"
            class="w-full sm:w-auto"
            @click="() => toggleMercure()"
          >
            {{ t(`settings.configOverride.${disableMercure ? "enableMercure" : "disableMercure"}`) }}
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
