<script setup lang="ts">
import { createReusableTemplate } from "@vueuse/core";
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import CloseIcon from "../../assets/CloseIcon.svg?component";
import { useDialog } from "../../composables/Dialog/useDialog";
import { useDialogDirtyCheck } from "../../composables/Dialog/useDialogDirtyCheck";
import { useDialogDrag } from "../../composables/Dialog/useDialogDrag";
import { useEscape } from "../../composables/Dialog/useEscape";

const {
  id,
  size = "default",
  teleportTarget = "body",
  square = false,
  confirmClose = false,
  mask = false,
  headless = false,
  isDirty,
  newspaper = false,
  draggable = false,
  closable = true,
  keepInViewport = true,
  confirmCloseOptions = undefined,
  paddingless = false,
  header = null,
  footer = null,
} = defineProps<Props>();

const emit = defineEmits<{
  close: []
  confirm: []
  dragstart: [event: MouseEvent]
  dragend: [event: MouseEvent]
}>();

interface ConfirmCloseOptions {
  title: string
  message: string
  confirmText: string
  cancelText: string
  icon?: string
  type?: string
}

interface Props {
  id: string
  size?: string
  teleportTarget?: HTMLElement | "body" | "self"
  square?: boolean
  confirmClose?: boolean
  isDirty?: () => boolean
  mask?: boolean
  headless?: boolean
  newspaper?: boolean
  draggable?: boolean
  closable?: boolean
  paddingless?: boolean
  keepInViewport?: boolean
  confirmCloseOptions?: ConfirmCloseOptions
  header?: string | null
  footer?: string | null
}

const { t } = useI18n();

const computedConfirmCloseOptions = computed<ConfirmCloseOptions>(() => {
  return {
    title: confirmCloseOptions?.title || t("base.confirm_close"),
    message: confirmCloseOptions?.message || t("base.confirm_close_message"),
    confirmText: confirmCloseOptions?.confirmText || t("base.dont_save"),
    cancelText: confirmCloseOptions?.cancelText || t("base.cancel"),
    icon: "exclamation-triangle",
    type: "primary",
  };
});

const dialog = ref<HTMLElement | null>(null);
const dialogWrapper = ref<HTMLElement | null>(null);
const isDragging = ref(false);

const visible = defineModel<boolean>("visible", { required: true });

const isActuallyDirty = isDirty
  ? () => {
      if (!visible.value) {
        return false;
      }
      return isDirty?.();
    }
  : undefined;

const {
  dialogZIndex,
  closeThisDialog,
  openThisDialog,
  isClosing,
  instanceId,
} = useDialog(id, {
  onClose: () => {
    visible.value = false;
    emit("close");
  },
  onConfirm: () => emit("confirm"),
  confirmClose,
  isDirty: isActuallyDirty,
  teleportTarget: teleportTarget as string,
  confirmCloseOptions: computedConfirmCloseOptions.value,
});

useEscape({
  onEscape: () => {
    if (closable && !isClosing.value) {
      closeThisDialog();
    }
  },
  enabled: closable,
  instanceId: computed(() => instanceId.value),
  visible,
});

const dialogElement = computed(() => dialog.value as HTMLElement | null);

const { initDrag } = useDialogDrag(dialogElement, {
  draggable,
  keepInViewport,
  onDragStart: (event) => {
    isDragging.value = true;
    emit("dragstart", event);
  },
  onDragEnd: (event) => {
    isDragging.value = false;
    emit("dragend", event);
  },
});

const maskZIndex = computed(() => dialogZIndex.value - 1);

useDialogDirtyCheck(isActuallyDirty);

const { activate, deactivate } = useFocusTrap(dialog, {
  clickOutsideDeactivates: true,
  escapeDeactivates: false,
  allowOutsideClick: true,
  returnFocusOnDeactivate: true,
  initialFocus: false,
});

watch(
  () => visible.value,
  (newValue) => {
    if (newValue) {
      openThisDialog();
      nextTick(() => {
        setTimeout(() => {
          try {
            if (dialog.value && document.contains(dialog.value)) {
              activate();
            }
          } catch (error) {
            console.warn("Failed to activate focus trap:", error);
          }
        }, 50);
      });
    } else {
      try {
        deactivate();
      } catch (error) {
        console.warn("Failed to deactivate focus trap:", error);
      }
    }
  },
  { immediate: true },
);

function handleMaskClick(event: MouseEvent) {
  if (!isClosing.value) {
    closeThisDialog(event);
  }
}

function handleDialogClick(event: MouseEvent) {
  event.stopPropagation();
}

function handleCloseClick(event: MouseEvent) {
  event.stopPropagation();
  closeThisDialog();
}

function bringDialogToFront() {
  openThisDialog();
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate();
</script>

<template>
  <DefineTemplate>
    <Transition name="fade">
      <div
        v-if="visible && mask"
        class="mask"
        :style="{ zIndex: maskZIndex }"
        @click="handleMaskClick"
        @mousedown.stop
      />
    </Transition>

    <Transition :name="newspaper ? 'newspaper' : 'dialog'">
      <div
        v-if="visible"
        ref="dialogWrapper"
        class="dialog-wrapper"
        :style="{ zIndex: dialogZIndex }"
        @mousedown="bringDialogToFront"
      >
        <div
          ref="dialog"
          :data-instance-id="instanceId"
          :data-dialog-id="id"
          class="dialog"
          :class="[
            size,
            {
              square,
              headless,
              'draggable': draggable && !headless,
              'no-padding': paddingless,
            },
          ]"
          role="dialog"
          @click="handleDialogClick"
          @mousedown.stop
        >
          <div
            v-if="!headless"
            class="header"
            @mousedown.prevent="initDrag"
          >
            <div class="title-container">
              <slot name="header" :close="handleCloseClick">
                <span v-if="header" class="title">{{ header }}</span>
                <slot v-else name="title" :close="handleCloseClick">
                  Title
                </slot>
              </slot>
            </div>
            <div v-if="closable" class="">
              <span
                class="cursor-pointer rounded-full w-4 h-4 flex items-center justify-center close-icon"
                role="button"
                aria-label="Close"
                @click="handleCloseClick"
                @mousedown.stop
              >
                <CloseIcon class="w-4 h-4" />
              </span>
            </div>
          </div>
          <div class="body">
            <slot v-if="visible" name="content" :close="handleCloseClick" />
          </div>

          <div v-if="$slots.footer || footer" class="footer">
            <slot name="footer" :close="handleCloseClick">
              {{ footer }}
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </DefineTemplate>
  <template v-if="teleportTarget === 'self'">
    <ReuseTemplate />
  </template>

  <Teleport v-else :to="teleportTarget">
    <ReuseTemplate />
  </Teleport>
</template>

<style scoped lang="scss">
@use "./Dialog";
</style>

<style lang="scss">
.close-icon {
  @apply dark:fill-gray-50 fill-gray-800;
  .w-4 {
    transition: transform 250ms ease;
  }
  &:hover .w-4 {
    transform: rotate(90deg);
  }
  &:active .w-4 {
    transform: rotate(90deg) scale(1.2);
  }
}
</style>
