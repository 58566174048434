import type { PropertyReservationStateHydraItem, PropertyTaskStateHydraItem, TaskDataHydraItem } from "@verbleif/lib";
import { createGlobalState, useStorage } from "@vueuse/core";

export const usePropertyStateDebug = createGlobalState(() => {
  const enablePropertyStateDebug = useStorage<boolean>("enablePropertyStateDebug", false);

  const propertyStateDebugRef = ref<{
    show: ({
      reservationState,
      taskState,
      taskData,
    }: {
      reservationState?: PropertyReservationStateHydraItem | null
      taskState?: PropertyTaskStateHydraItem | null
      taskData?: TaskDataHydraItem | null
    }) => void
  } | null>(null);

  return {
    enablePropertyStateDebug,
    propertyStateDebugRef,
  };
});
