<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";

const {
  stepIndex,
  previousButtonType = "default",
  nextButtonType = "default",
} = defineProps<{
  stepIndex: number
  previousButtonType?: "default" | "disabled" | "none"
  nextButtonType?: "default" | "disabled" | "none" | "submit"
  description?: string
  icon?: string | [ "fa" | "fas" | "far" | "fad" | "fal", string] | undefined
}>();

// Inject step state from VModernDialogStepper
const emit = defineEmits<{
  (e: "submit"): void
}>();

const { t } = useI18n();
const currentStep = inject("currentStep", ref(0));
const nextStep = inject("nextStep") as () => void;
const previousStep = inject("previousStep") as () => void;
const totalSteps = inject("totalSteps", ref(1));

const isActive = computed(() => stepIndex === currentStep.value);
</script>

<template>
  <div v-show="isActive">
    <div class="flex flex-col items-center mb-3">
      <FontAwesomeIcon
        v-if="icon"
        class="text-green-500 text-3xl mb-4"
        :icon="icon"
      />
      <div v-if="description" class="text-center max-w-xs">
        {{ description }}
      </div>
    </div>
    <slot name="content" />

    <div class="flex justify-between items-center mt-4">
      <!-- Custom previous button OR default button -->
      <slot name="previous">
        <VButton v-if="previousButtonType !== 'none'" rounded variant="is-gray" :disabled="currentStep < 1 || previousButtonType === 'disabled'" @click="previousStep">
          {{ t(`base.previous`) }}
        </VButton>
        <div v-else />
      </slot>
      <div class="flex gap-4">
        <div
          v-for="(_, index) in totalSteps"
          :key="index"
          :class="index === currentStep ? 'bg-green-500' : 'bg-gray-300'"
          class=" w-2.5 h-2.5 rounded-full"
        />
      </div>
      <!-- Custom next button OR default button -->
      <slot name="next">
        <VButton v-if="nextButtonType === 'submit'" rounded @click="emit('submit')">
          {{ t(`base.submit`) }}
        </VButton>
        <VButton v-else-if="nextButtonType !== 'none'" rounded :disabled="currentStep >= totalSteps - 1 || nextButtonType === 'disabled'" @click="nextStep">
          {{ t(`base.next`) }}
        </VButton>

        <div v-else />
      </slot>
    </div>
  </div>
</template>
