import type { PersonalWrite, UserHydraItem } from "@verbleif/lib";
import type { PersonalObjectSchema } from "./personal.interface";
import { useLocationStore } from "@verbleif/shared";

export function usePersonalTransformer() {
  function inputTransformer(input: UserHydraItem | null): PersonalObjectSchema {
    return {
      name: input?.name ?? "",
      username: input?.username ?? "",
      avatar: input?.avatar?.contentUrl ?? null,
      client: useLocationStore().selectedClient.value?.["@id"] ?? "",
      locale: input?.locale ?? "",
      timezone: input?.timezone ?? "",
    };
  }

  function outputTransformer(input: PersonalObjectSchema): PersonalWrite {
    return {
      name: input.name,
      username: input.username,
      timezone: input.timezone,
      locale: input.locale,
      avatar: input.avatar,
    };
  }

  function outputPartialTransformer(input: Partial<PersonalObjectSchema>): Partial<PersonalWrite> {
    return {
      name: input.name !== undefined ? input.name : undefined,
      username: input.username !== undefined ? input.username : undefined,
      timezone: input.timezone !== undefined ? input.timezone : undefined,
      locale: input.locale !== undefined ? input.locale : undefined,
      avatar: input.avatar !== undefined ? input.avatar : undefined,
    };
  }

  return {
    inputTransformer,
    outputTransformer,
    outputPartialTransformer,
  };
}
