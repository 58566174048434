<script setup lang="ts">
import { computed } from "vue";
import DatePointState from "../DatePoint/DatePointState.vue";
import { useReservationState } from "../useReservationState";
import OccupationLeft from "./OccupationLeft.vue";
import OccupationRight from "./OccupationRight.vue";
import OccupationStatus from "./OccupationStatus.vue";

const { dialog } = defineProps<{
  dialog?: boolean
}>();

const { reservationState, loading } = useReservationState();

const datePointState = computed(() => reservationState.value?.datePointState);
const hasLeftReservation = computed(() => !!reservationState.value?.current || !!reservationState.value?.lastDeparture);
const hasRightReservation = computed(() => !!reservationState.value?.nextArrival);
</script>

<template>
  <div class="px-4 h-full">
    <!-- Dialog layout: OccupationStatus above reservations -->
    <div v-if="dialog" class="flex flex-col gap-4">
      <OccupationStatus :dialog="dialog" />

      <VDivider class="my-1 !mb-1" />

      <div class="grid grid-cols-1 xl:grid-cols-3 gap-4 w-full">
        <div class="col-span-1">
          <OccupationLeft v-if="hasLeftReservation" />
          <div v-else class="flex flex-col gap-2 h-full text-sm text-gray-800 dark:text-gray-200">
            <h3 class="font-bold w-full">
              {{ $t('tasks.reservation_state.occupation.empty.title') }}
            </h3>
            <div class="justify-center text-gray-600 dark:text-gray-400 flex-1">
              <span class="text-center">
                {{ $t('tasks.reservation_state.occupation.no_current_reservation') }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-span-1 flex items-center justify-center py-4 md:py-0">
          <DatePointState
            v-if="datePointState"
            :date-point-state="datePointState"
            :loading="loading"
          />
        </div>
        <div class="col-span-1">
          <OccupationRight v-if="hasRightReservation" />
          <div v-else class="flex flex-col gap-2 h-full text-sm text-gray-800 dark:text-gray-200">
            <h3 class="font-bold w-full">
              {{ $t('tasks.reservation_state.occupation.nextArrival.title') }}
            </h3>
            <div class="justify-center text-gray-600 dark:text-gray-400 flex-1">
              <span class="text-center">
                {{ $t('tasks.reservation_state.occupation.no_next_reservation') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Regular layout: OccupationStatus next to reservations -->
    <div v-else class="grid grid-cols-1 xl:grid-cols-3 gap-4 h-full">
      <div class="col-span-1">
        <OccupationStatus />
      </div>

      <div class="col-span-1 xl:col-span-2">
        <div class="grid grid-cols-1 xl:grid-cols-3 w-11/12 sm:w-full h-full">
          <div class="col-span-1">
            <OccupationLeft v-if="hasLeftReservation" />
            <div v-else class="flex flex-col gap-2 h-full text-sm text-gray-800 dark:text-gray-200">
              <h3 class="font-bold w-full">
                {{ $t('tasks.reservation_state.occupation.empty.title') }}
              </h3>
              <div class="justify-center text-gray-600 dark:text-gray-400 flex-1">
                <span class="text-center">
                  {{ $t('tasks.reservation_state.occupation.no_current_reservation') }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-1 flex items-center justify-center py-4 md:py-0">
            <DatePointState
              v-if="datePointState"
              :date-point-state="datePointState"
              :loading="loading"
            />
          </div>
          <div class="col-span-1">
            <OccupationRight v-if="hasRightReservation" />
            <div v-else class="flex flex-col gap-2 h-full text-sm text-gray-800 dark:text-gray-200">
              <h3 class="font-bold w-full">
                {{ $t('tasks.reservation_state.occupation.nextArrival.title') }}
              </h3>
              <div class="justify-center text-gray-600 dark:text-gray-400 flex-1">
                <span class="text-center">
                  {{ $t('tasks.reservation_state.occupation.no_next_reservation') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
