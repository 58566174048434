import type { MaybeRef } from "vue";
import { computed, inject, provide, ref, toValue } from "vue";

const SortableKey = Symbol("Sortable");

function createSortable(sortableFields: MaybeRef<Partial<Record<string, string>>>, initialSort?: { field: string, direction: "DESC" | "ASC" }) {
  const sort = ref<string | null>(initialSort?.field || null);
  const sortDirection = ref<"DESC" | "ASC">(initialSort?.direction || "ASC");
  const sortableFieldsArray = computed(() => Object.keys(toValue(sortableFields)));

  function setSort(newSort: { field: string, direction: "DESC" | "ASC" } | undefined) {
    sort.value = newSort?.field || null;
    sortDirection.value = newSort?.direction || "DESC";
  }

  function toggleSortDirection() {
    sortDirection.value = sortDirection.value === "DESC" ? "ASC" : "DESC";
  }

  function sortingEnabled() {
    return sort.value;
  }

  function setSortUrlParams(urlParams: URLSearchParams) {
    if (sortingEnabled()) {
      urlParams.append(`order[${sort.value}]`, sortDirection.value);
    }
  }

  function isSortable(field: string) {
    return sortableFieldsArray.value.includes(field);
  }

  return {
    toggleSortDirection,
    sortingEnabled,
    setSortUrlParams,
    sort,
    sortDirection,
    sortableFields,
    isSortable,
    setSort,
  };
}

type CreateSortableParameters = Parameters<typeof createSortable>;
export function provideSortable(...params: CreateSortableParameters) {
  const instance = createSortable(...params);
  provide(SortableKey, instance);
  return instance;
}

type UseSortable = ReturnType<typeof createSortable>;
export function useSortable() {
  const instance = inject<UseSortable>(SortableKey);

  if (!instance) {
    throw new Error("Run provideSortable before useSortable.");
  }

  return instance;
}
