import type { Reservation, ReservationDatePointState } from "@verbleif/lib/src/composables/Api/Type/PropertyReservationState/propertyReservationState.interface";
import { createInjectionState } from "@vueuse/core";
import moment from "moment";
import { ref } from "vue";

// Create state with optional propertyId parameter
const [useProvideReservationState, useReservationStateRaw] = createInjectionState((providedPropertyId?: number) => {
  const route = useRoute();
  const { propertyStates, getPropertyState, loadingSingle: propertyLoadingSingle } = usePropertyStates();
  const { locale } = useI18n();

  const currentMoment = computed(() => moment().locale(locale.value));

  const manualPropertyId = ref<number | null>(providedPropertyId || null);

  const propertyId = computed(() => {
    // Priority: manually provided ID > route param
    if (manualPropertyId.value !== null) {
      return manualPropertyId.value;
    }
    return route.params.id as string ? Number.parseInt(route.params.id as string, 10) : null;
  });

  const isPropertyLoading = computed(() => {
    if (!propertyId.value) {
      return false;
    }
    return propertyLoadingSingle.value === propertyId.value;
  });

  watch(() => propertyId.value, (newId) => {
    if (newId) {
      getPropertyState(newId);
    }
  }, { immediate: true });

  const propertyState = computed(() => {
    if (!propertyId.value) {
      return null;
    }
    return propertyStates.value[propertyId.value];
  });

  const reservationState = computed(() => {
    if (!propertyState.value) {
      return null;
    }
    return propertyState.value?.data?.reservationState;
  });

  const taskState = computed(() => {
    if (!propertyState.value) {
      return null;
    }
    return propertyState.value?.data?.taskState;
  });

  const loading = computed(() => {
    if (propertyState.value) {
      return false;
    }

    return isPropertyLoading.value;
  });

  const datePointState = ref<ReservationDatePointState | null>(null);

  function getGuestCount(reservation: Reservation | null) {
    if (!reservation) {
      return {
        guests: 0,
        pets: 0,
      };
    }

    return {
      guests: reservation.data.adultAmount + reservation.data.childAmount + reservation.data.adolescentAmount + reservation.data.babyAmount,
      pets: reservation.data.petAmount,
    };
  }

  function formatDate(isoDate: string) {
    if (!isoDate) {
      return { date: "", time: "", isToday: false };
    }

    const momentDate = moment(isoDate).locale(locale.value);
    if (!momentDate.isValid()) {
      return { date: "", time: "", isToday: false };
    }

    const time = momentDate.format("HH:mm");
    const isToday = momentDate.isSame(currentMoment.value, "day");
    // Use 'ddd' for short weekday and 'MMM' for short month format
    const formattedDate = momentDate.format("ddd D MMM");

    return {
      date: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
      time,
      isToday,
    };
  }

  // Method to manually set property ID
  function setPropertyId(id: number | null) {
    manualPropertyId.value = id;
  }

  return {
    propertyState,
    reservationState,
    taskState,
    loading,
    datePointState,
    getGuestCount,
    formatDate,
    setPropertyId,
    setLoading: (value: number | null) => {
      propertyLoadingSingle.value = value;
    },
  };
});
export { useProvideReservationState };

export function useReservationState(propertyId?: number) {
  const reservationState = useReservationStateRaw();
  if (reservationState == null) {
    throw new Error("Please call `useProvideReservationState` on the appropriate parent component");
  }

  if (propertyId !== undefined) {
    reservationState.setPropertyId(propertyId);
  }

  return reservationState;
}
