import type { ActiveEnum } from "../active.enum";
import type { HydraContext } from "../hydra.interface";

export enum NotificationTypeEnum {
  TYPE_COMMENT = "comment",
  TYPE_TASK = "task",
  TYPE_REPORT = "report",
}

export interface NotificationHydraItem extends HydraContext {
  "@id": string
  "@type": string
  "title": string
  "type": NotificationTypeEnum
  "readAt": string | null
  "sentAt": string | null
  "alert": boolean
  "message"?: string | null
  "messageApp"?: string | null
  "messageWeb"?: string | null
  "active": ActiveEnum
  "id": number
  "createdAt": string
  "updatedAt"?: string | null
  "deletedAt"?: string | null

  // !MISSING FROM API
  "link"?: string
  "target"?: string
  "task"?: string | null
  "report"?: string | null
  "subject"?: string | null
  "location"?: string | null
  "createdBy"?: string | null
  "activity"?: string | null
}

export type NotificationHydraCollectionItem = NotificationHydraItem;

export interface NotificationWithSkeleton extends Notification {
  skeleton?: boolean
  id: number
  readAt: string | null
  title: string
  sentAt: string
  messageWeb: string
}

export interface NotificationFieldItem {
  fieldName: string
  field: string
  priority?: number
}
