import type { UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import type { Ref } from "vue";
import type { TaskEntityVote } from "../../composables/Permissions/Voters/voters.helpers";
import { createGlobalState, StorageSerializers, useStorage } from "@vueuse/core";
import { ref } from "vue";
import { taskVoter } from "../../composables/Permissions/SharedPermissions";
import { useSharedStore } from "../../composables/SharedStore/useSharedStore";

export const useAssignTo = createGlobalState(() => {
  const { usersList, userGroupsList } = useSharedStore();
  const searchTypes = useStorage<{
    UserGroup: boolean
    User: boolean
  }>("searchTypes", { UserGroup: true, User: true }, undefined, {
    serializer: StorageSerializers.object,
  });
  const activeOnly = ref(false);

  function toggleSearchType(type: "UserGroup" | "User") {
    searchTypes.value[type] = !searchTypes.value[type];
  }

  function reset() {
    searchTypes.value = { UserGroup: true, User: true };
    activeOnly.value = false;
  }

  function getSortedItems({
    assignees,
    taskEntity,
    originalTaskItem,
    search,
  }: {
    assignees: Ref<string[]>
    taskEntity?: TaskEntityVote | null
    originalTaskItem: TaskEntityVote | null
    search?: Ref<string | undefined>
  }): ((UserHydraItem | UserGroupHydraItem) & {
      canAssign: boolean
      canUnassign: boolean
    })[] {
    let items: ((UserHydraItem | UserGroupHydraItem) & {
      canAssign: boolean
      canUnassign: boolean
    })[] = [];

    if (searchTypes.value.User) {
      items.push(...usersList.value.map(i => ({
        ...i,
        canAssign: false,
        canUnassign: false,
      })));
    }
    if (searchTypes.value.UserGroup) {
      items.push(...userGroupsList.value.map(i => ({
        ...i,
        canAssign: false,
        canUnassign: false,
      })));
    }

    if (taskEntity !== undefined) {
      const canAssignAny = taskVoter.canAssignAny();
      const canUnassignAny = taskVoter.canUnassignAny();

      items = items.map((i) => {
        const canAssign = (() => {
          if (canAssignAny) {
            return true;
          }

          if (taskEntity) {
            return taskVoter.canAssign(taskEntity, i);
          }
          return false;
        })();

        const canUnassign = (() => {
          if (!originalTaskItem) {
            return true;
          }

          if (canUnassignAny) {
            return true;
          }

          if (taskEntity) {
            return taskVoter.canUnassign(taskEntity, i);
          }
          return false;
        })();
        return {
          ...i,
          canAssign,
          canUnassign,
        };
      }).filter((i) => {
        return i.canAssign || i.canUnassign || originalTaskItem?.assignees.includes(i["@id"]);
      });
    } else {
      items = items.map((i) => {
        return {
          ...i,
          canAssign: true,
          canUnassign: true,
        };
      });
    }

    if (activeOnly.value) {
      items = items.filter(i => assignees.value.includes(i["@id"]));
    }

    return items.sort((i) => {
      if (i["@type"] === "UserGroup") {
        return -1;
      }

      return 1;
    }).filter((i) => {
      if (!search?.value) {
        return true;
      }

      return i.name.toLowerCase().includes(search.value.toLowerCase());
    });
  };

  return {
    getSortedItems,
    toggleSearchType,
    reset,
    searchTypes,
    activeOnly,
  };
});
