import type { AppHydraItem, AppSubscriptionSyncImportHydraItem, ClientHydraItem, ProductHydraItem, PropertyGroupHydraItem, PropertyHydraItem, ReportTopicHydraItem, SortHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import { getAll, useAuthStore, useLocationStore, useSharedStore, useSystemStore } from "@verbleif/shared";
import { createGlobalState, until } from "@vueuse/core";
import { useRoute } from "vue-router";
import { useActiveTimeEntry } from "./features/Tasks/TimeEntry/useActiveTimeEntry";

export const useAppSync = createGlobalState(() => {
  const startedSyncForEntity = ref<string | null>(null);
  const route = useRoute();
  const {
    setClients,
    modeSelectedLocation,
    modeSelectedClient,
    selectedClient,
  } = useLocationStore();
  const { isAuthenticated } = useAuthStore();
  const {
    setProperties,
    setSorts,
    setPropertyGroups,
    setReportTopics,
    setProducts,
    setAppSubscriptionSyncImportBatches,
    setActiveApps,
  } = useSystemStore();

  const { setUsers, setUserGroups } = useSharedStore();
  const { getPropertyStates } = usePropertyStates();

  // const { setPropertiesExtraData } = usePropertyExtraData();
  const { getActiveTimeEntry } = useActiveTimeEntry();
  const isSyncRunning = ref(false);

  const params = computed<URLSearchParams>(() => {
    const params = new URLSearchParams();

    if (modeSelectedLocation.value) {
      params.set("location", `${modeSelectedLocation.value}`);
    }

    if (modeSelectedClient.value) {
      params.set("client", `${modeSelectedClient.value}`);
    }

    return params;
  });

  async function syncUserGroups() {
    const userGroups = await getAll<UserGroupHydraItem>({
      url: "/user_groups",
      initialParams: params.value,
    });
    setUserGroups(userGroups);
  };

  async function syncProperties() {
    const properties = await getAll<PropertyHydraItem>({
      url: "/properties",
      initialParams: params.value,
    });
    setProperties(properties);
    return properties;
  }

  // async function syncPropertiesExtraData() {
  //   const propertiesExtraData = await getAll<TaskDataHydraItem>({
  //     url: "/property-extra-datas",
  //     initialParams: params.value,
  //   });
  //   setPropertiesExtraData(propertiesExtraData);
  // }

  async function syncSorts() {
    const sorts = await getAll<SortHydraItem>({
      url: "/sorts",
      initialParams: params.value,
    });
    setSorts(sorts);
  }

  async function syncUsers() {
    const users = await getAll<UserHydraItem>({
      url: "/users",
      initialParams: params.value,
    });
    setUsers(users);
  }

  async function syncPropertyGroups() {
    const propertyGroups = await getAll<PropertyGroupHydraItem>({
      url: "/property_groups",
      initialParams: params.value,
    });
    setPropertyGroups(propertyGroups);
  }

  async function syncClients() {
    const clients = await getAll<ClientHydraItem>({
      url: "/clients",
      initialParams: params.value,
    });
    setClients(clients);
  }

  async function syncReportTopics() {
    const reportTopics = await getAll<ReportTopicHydraItem>({
      url: "/report_topics",
      initialParams: params.value,
    });
    setReportTopics(reportTopics);
  }

  async function syncProducts() {
    const products = await getAll<ProductHydraItem>({
      url: "/products",
      initialParams: params.value,
    });
    setProducts(products);
  }

  async function syncAppSubscriptionSyncImportBatches() {
    const initialParams = new URLSearchParams(params.value);
    initialParams.set("exists[failedAt]", "false");
    initialParams.set("exists[finishedAt]", "false");
    const appSubscriptionSyncImportBatches = await getAll<AppSubscriptionSyncImportHydraItem>({
      url: "/app_subscription_import_batches",
      initialParams,
    });
    setAppSubscriptionSyncImportBatches(appSubscriptionSyncImportBatches);
  }
  async function syncActiveApps() {
    await until(selectedClient.value).toBeTruthy({ timeout: 1000 });
    if (!selectedClient.value) {
      console.log("No selected client");
      return;
    }
    const urlSearchParams = new URLSearchParams();
    const activeApps = await getAll<AppHydraItem>({
      url: `/clients/${selectedClient.value.id}/apps`,
      initialParams: urlSearchParams,
    });
    setActiveApps(activeApps);
  }

  async function syncAll() {
    if (isSyncRunning.value) {
      return;
    }
    try {
      startedSyncForEntity.value = modeSelectedLocation.value ?? modeSelectedClient.value ?? null;
      isSyncRunning.value = true;
      syncUsers().then();
      syncUserGroups().then();
      syncSorts().then();
      syncProperties().then((properties) => {
        getPropertyStates(properties).then();
      });
      // syncPropertiesExtraData().then();
      syncPropertyGroups();
      syncClients().then();
      syncReportTopics().then();
      syncProducts().then();
      syncAppSubscriptionSyncImportBatches().then();
      getActiveTimeEntry().then();
      syncActiveApps().then();
    } finally {
      isSyncRunning.value = false;
    }
  }

  const skipRouteCheck = computed(() => route.meta.public === true || route.meta.skipAuthChecks === true || route.name === undefined);

  // Sync data when auth state and location changes
  watch(
    [
      () => isAuthenticated.value,
      () => modeSelectedLocation.value ?? modeSelectedClient.value,
      () => skipRouteCheck.value,
      () => startedSyncForEntity.value,
    ],
    ([isAuthenticated, selectedEntity, skipRouteCheck, startedSyncForEntity]) => {
      if (!isAuthenticated) {
        console.log("%c [App.vue] not authenticated", "color: #FFA500; font-weight: bold;");
        return;
      }

      if (!selectedEntity) {
        console.log("%c [App.vue] no location or client selected", "color: #FFA500; font-weight: bold;");
        return;
      }

      if (startedSyncForEntity && startedSyncForEntity === selectedEntity) {
        console.log("%c [App.vue] already started sync for this entity", "color: #FFA500; font-weight: bold;");
        return;
      }

      if (skipRouteCheck) {
        console.log("%c [App.vue] public route or skip auth checks or undefined route", "color: #FFA500; font-weight: bold;");
        return;
      }

      console.log("%c [App.vue] Syncing data - authenticated and location selected", "color: #4CAF50; font-weight: bold;");
      syncAll();
    },
    { immediate: true },
  );
  return {
    startedSyncForEntity,
    syncAll,
  };
});
