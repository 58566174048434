import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class LegacyNotificationUserSubscriptionVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionRead,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionDelete,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    if (item?.location === "/api/locations/2") {
      return false;
    }
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionUpdate,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionCreateany,
        PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionUpdateany,
        PermissionAttributesEnum.GlobalOperationLegacynotificationusersubscriptionDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionCreate,
        PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionUpdate,
        PermissionAttributesEnum.ContextOperationLegacynotificationusersubscriptionDelete,
      ],
      scope: PermissionScope.PROPERTY,
    });
  };
}
