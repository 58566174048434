<script setup lang="ts">
import type { AppSubscriptionImportProgress } from "@verbleif/lib/src/composables/Api/Type/MercureProgress/appSubscriptionImportProgress.interface";
import type { ExternalToast } from "vue-sonner";
import type { HeadlessProgressToastProps } from "../Sonner";
import { VButton } from "@verbleif/lib";
import { iriToId } from "@verbleif/lib/src/composables/Utils/iri.helpers";
import { useDebounceFn } from "@vueuse/core";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { computed, markRaw, ref } from "vue";
import { useI18n } from "vue-i18n";
import { toast as sonner } from "vue-sonner";
import { useImportHistory } from "../../composables/useImportHistory";
import { customToast } from "../Sonner";
import HeadlessToast from "./HeadlessToast.vue";

dayjs.extend(duration);

const { t } = useI18n();
const TRANSLATION_PATH = "appStore.settings.sync";
const { getComponentProps } = useImportHistory();

const toastId = ref<string | number | null>(null);
const progressValue = ref(0);
const isComplete = ref(false);
const violations = ref<{ [key: string]: string[] }>({});

function generateSyncProgress() {
  return {
    progressBar: {
      max: 14,
      startTime: Date.now(),
      step: 1,
      percent: 7,
      completed: false,
      estimated: 600, // 10 minutes in seconds
      remaining: 600, // 10 minutes in seconds
      elapsed: 60, // 1 minute in seconds
    },
    sequenceProgressBar: {
      max: 186,
      startTime: Date.now() - 60000, // Started 1 minute ago
      step: 30,
      percent: 15,
      completed: false,
      estimated: 120, // 2 minutes in seconds
      remaining: 60, // 1 minute in seconds
      elapsed: 60, // 1 minute in seconds
    },
    sequenceName: "executors",
    finished: false,
    failed: false,
    appSubscription: "/api/app_subscriptions/10",
    importBatch: "/api/app_subscription_import_batches/5",
  };
}

const syncProgress = ref<AppSubscriptionImportProgress | null>(null);
const syncStep = ref(0);

const batchId = computed(() => {
  return syncProgress.value?.importBatch
    ? iriToId(syncProgress.value.importBatch)
    : null;
});
function dismissSyncToast() {
  if (batchId.value !== null) {
    sonner.dismiss(batchId.value);
    syncStep.value = 0;
    syncProgress.value = null;
  }
}

const debouncedDismissSyncToast = useDebounceFn(() => {
  dismissSyncToast();
}, 4000);
function triggerCustomToast() {
  customToast({
    important: false,
    componentProps: {
      runningIcon: ["fad", "clipboard-list"],
      runningIconClass: "text-primary-500",
      title: "Nieuwe taak",
      message: "De taak is toegevoegd aan de lijst",
      buttons: [
        {
          label: "Bekijk",
          onClick: () => {
            console.log("Bekijk");
          },
          size: "small",
          iconLeft: ["far", "eye"],
        },
      ],
    },
  });
}

function triggerProgressToast() {
  const customId = Date.now();
  const baseToast: ExternalToast & { componentProps: HeadlessProgressToastProps } = {
    important: true,
    duration: Infinity,
    componentProps: {
      title: "Bulk bewerken taken",
      message: "De bulkbewerking is gestart",
      value: progressValue.value,
      isComplete: isComplete.value,
      violations: violations.value,
    },
  };

  if (toastId.value !== null) {
    // Update existing toast
    if (progressValue.value === 0) {
      progressValue.value = 50;
      isComplete.value = false;
      violations.value = {};
    } else if (progressValue.value === 50) {
      progressValue.value = 100;
      isComplete.value = true;
      violations.value = {};
    } else if (progressValue.value === 100 && Object.keys(violations.value).length === 0) {
      // Add violations on the fourth click
      violations.value = {
        "Taak 1": ["Ongeldige datum"],
        "Taak 2": ["Ontbrekende informatie"],
      };
    } else {
      // Reset for next demonstration
      progressValue.value = 0;
      isComplete.value = false;
      violations.value = {};
    }

    customToast({
      id: toastId.value,
      ...baseToast,
      componentProps: {
        ...baseToast.componentProps,
        message: progressValue.value === 100
          ? Object.keys(violations.value).length > 0
            ? "De bulkbewerking is voltooid met overtredingen"
            : "De bulkbewerking is voltooid"
          : "De bulkbewerking wordt uitgevoerd",
        value: progressValue.value,
        isComplete: isComplete.value,
        violations: violations.value,
      },
    });
  } else {
    // Create new toast
    progressValue.value = 0;
    isComplete.value = false;
    violations.value = {};
    const id = customToast({
      ...baseToast,
      id: customId,
    });
    toastId.value = id;
  }
}

function triggerSyncToast() {
  if (!syncProgress.value) {
    syncProgress.value = generateSyncProgress();
  }

  if (!batchId.value) {
    return;
  }

  // Update existing toast based on step
  syncStep.value = (syncStep.value + 1) % 6;

  // Update progress based on step
  switch (syncStep.value) {
    case 1: // Step 1: Progress to 25%
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: {
          ...syncProgress.value?.progressBar ?? generateSyncProgress().progressBar,
          step: 4,
          percent: 25,
          remaining: 4200, // 1 hour 10 minutes
          elapsed: 150,
        },
        sequenceProgressBar: {
          max: 186,
          startTime: Date.now() - 120000,
          step: 65,
          percent: 35,
          completed: false,
          estimated: 4800, // 1 hour 20 minutes
          remaining: 3600, // 1 hour
          elapsed: 1200,
        },
        sequenceName: "rentable_types",
        finished: false,
        failed: false,
      };
      break;
    case 2: // Step 2: Progress to 50%
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: {
          ...syncProgress.value?.progressBar ?? generateSyncProgress().progressBar,
          step: 7,
          percent: 50,
          remaining: 780, // 13 minutes
          elapsed: 300,
        },
        sequenceProgressBar: {
          max: 186,
          startTime: Date.now() - 180000,
          step: 130,
          percent: 70,
          completed: false,
          estimated: 900, // 15 minutes
          remaining: 480, // 8 minutes
          elapsed: 420,
        },
        sequenceName: "rentable_types",
        finished: false,
        failed: false,
      };
      break;
    case 3: // Step 3: Complete current sequence, start new one
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: {
          ...syncProgress.value?.progressBar ?? generateSyncProgress().progressBar,
          step: 8,
          percent: 57,
          remaining: 258, // ~4.3 minutes
          elapsed: 342,
        },
        sequenceProgressBar: {
          max: 42,
          startTime: Date.now(),
          step: 5,
          percent: 12,
          completed: false,
          estimated: 240,
          remaining: 45, // 45 seconds
          elapsed: 30,
        },
        sequenceName: "todo_lists",
        finished: false,
        failed: false,
      };
      break;
    case 4: // Step 4: Complete all
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: undefined,
        sequenceProgressBar: undefined,
        sequenceName: "todo_lists",
        finished: true,
        failed: false,
      };
      break;
    case 5: // Step 5: Show failed state
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: {
          ...syncProgress.value?.progressBar ?? generateSyncProgress().progressBar,
          step: 9,
          percent: 64,
          completed: false,
          remaining: 7200, // 2 hours
          elapsed: 390,
        },
        sequenceProgressBar: {
          max: 95,
          startTime: Date.now() - 180000,
          step: 45,
          percent: 47,
          completed: false,
          estimated: 360,
          remaining: 10, // just 10 seconds
          elapsed: 180,
        },
        sequenceName: "todos",
        finished: false,
        failed: true,
      };
      break;
    default: // Reset
      syncProgress.value = {
        ...syncProgress.value,
        progressBar: {
          max: 14,
          startTime: Date.now(),
          step: 1,
          percent: 7,
          completed: false,
          estimated: 600,
          remaining: 600,
          elapsed: 60,
        },
        sequenceProgressBar: {
          max: 186,
          startTime: Date.now() - 60000,
          step: 30,
          percent: 15,
          completed: false,
          estimated: 120,
          remaining: 60,
          elapsed: 60,
        },
        sequenceName: "executors",
        finished: false,
        failed: false,
      };
  }

  // Format remaining times
  const componentProps = getComponentProps(syncProgress.value);
  if (!componentProps) {
    return;
  }
  customToast({
    id: batchId.value,
    important: true,
    duration: Infinity,
    componentProps,
  });

  if (syncProgress.value.finished || syncProgress.value.failed) {
    debouncedDismissSyncToast();
  }
}

function toastWithAction() {
  // !We do this here because we get a build error in rollup if we define it in the action!
  function onClick() {
    console.log("SonnerDebugTab: onClick");
  }
  sonner("File uploaded", {
    action: {
      label: "Undo",
      onClick,
    },
  });
}

function toastHeadless() {
  sonner.custom(markRaw(HeadlessToast), {
    duration: 5000,
    componentProps: {
      message: "for a test",
    },
  });
}

function dismissToast() {
  if (toastId.value !== null) {
    sonner.dismiss(toastId.value);
    toastId.value = null;
    progressValue.value = 0; // Reset progress value when dismissing
  } else {
    sonner.dismiss();
  }
}
</script>

<template>
  <div class="max-w-2xl">
    <!-- Status Section -->
    <div class="mb-6">
      <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
        <h3 class="text-sm font-medium text-gray-700 dark:text-white mb-3">
          Toast Status
        </h3>
        <div class="flex items-center gap-3 mb-2">
          <span class="text-sm text-gray-600 dark:text-gray-400">Active Toast ID:</span>
          <code class="px-2 py-1 border border-gray-200 dark:border-gray-700 rounded text-sm font-mono bg-gray-50 dark:bg-dark-800">
            {{ toastId ?? "-" }}
          </code>
        </div>
        <div class="flex items-center gap-3 mb-2">
          <span class="text-sm text-gray-600 dark:text-gray-400">Active Batch ID:</span>
          <code class="px-2 py-1 border border-gray-200 dark:border-gray-700 rounded text-sm font-mono bg-gray-50 dark:bg-dark-800">
            {{ batchId ?? "-" }}
          </code>
        </div>
        <div v-if="toastId" class="flex items-center gap-3">
          <span class="text-sm text-gray-600 dark:text-gray-400">Progress:</span>
          <div class="flex items-center gap-2">
            <div class="h-2 w-24 bg-gray-100 dark:bg-dark-800 rounded-full overflow-hidden">
              <div
                class="h-full bg-primary-500 transition-all duration-300"
                :style="{ width: `${progressValue}%` }"
              />
            </div>
            <span class="text-sm text-gray-600 dark:text-gray-400">{{ progressValue }}%</span>
          </div>
        </div>
        <div v-if="syncProgress" class="flex flex-col gap-2 mt-2">
          <div class="flex items-center gap-3 mb-1">
            <span class="text-sm text-gray-600 dark:text-gray-400">Current Sequence:</span>
            <span class="text-sm font-medium">
              {{ syncProgress.sequenceName ? t(`${TRANSLATION_PATH}.types.${syncProgress.sequenceName}`, syncProgress.sequenceName) : 'Unknown' }}
              <span v-if="syncProgress.finished" class="text-success-500 ml-1">
                ({{ t(`${TRANSLATION_PATH}.completed`) }})
              </span>
              <span v-else-if="syncProgress.failed" class="text-danger-500 ml-1">
                ({{ t(`${TRANSLATION_PATH}.failed`) }})
              </span>
            </span>
          </div>
          <div class="flex items-center gap-3">
            <span class="text-sm text-gray-600 dark:text-gray-400">Sync Progress:</span>
            <div class="flex items-center gap-2">
              <div class="h-2 w-24 bg-gray-100 dark:bg-dark-800 rounded-full overflow-hidden">
                <div
                  class="h-full bg-green-500 transition-all duration-300"
                  :style="{ width: `${syncProgress.progressBar?.percent || 0}%` }"
                />
              </div>
              <span class="text-sm text-gray-600 dark:text-gray-400">{{ syncProgress.progressBar?.percent || 0 }}%</span>
            </div>
          </div>
          <div class="flex items-center gap-3">
            <span class="text-sm text-gray-600 dark:text-gray-400">Sync Sequence Progress:</span>
            <div class="flex items-center gap-2">
              <div class="h-2 w-24 bg-gray-100 dark:bg-dark-800 rounded-full overflow-hidden">
                <div
                  class="h-full bg-green-500 transition-all duration-300"
                  :style="{ width: `${syncProgress.sequenceProgressBar?.percent || 0}%` }"
                />
              </div>
              <span class="text-sm text-gray-600 dark:text-gray-400">{{ syncProgress.sequenceProgressBar?.percent || 0 }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Synchronization Test Section -->
    <div class="space-y-6">
      <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
        <h3 class="text-sm font-medium text-gray-700 dark:text-white mb-3">
          Synchronization Toast
        </h3>
        <div class="flex gap-2">
          <VButton
            variant="is-primary"
            outlined
            :icon-left="['fad', 'cloud-arrow-down']"
            @click="triggerSyncToast"
          >
            {{ syncProgress === null ? 'Show Sync Toast'
              : syncStep === 0 ? 'Step 1: Progress to 25%'
                : syncStep === 1 ? 'Step 2: Progress to 50%'
                  : syncStep === 2 ? 'Step 3: Start Takenlijsten'
                    : syncStep === 3 ? 'Step 4: Complete Sync'
                      : syncStep === 4 ? 'Step 5: Show Taken Error'
                        : 'Reset Sync' }}
          </VButton>
          <VButton
            variant="is-danger"
            outlined
            :icon-left="['far', 'times-circle']"
            @click="dismissSyncToast"
          >
            Dismiss Sync Toast
          </VButton>
        </div>
      </div>

      <!-- Progress Controls -->
      <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
        <h3 class="text-sm font-medium text-gray-700 dark:text-white mb-3">
          Progress Controls
        </h3>
        <div class="flex gap-2">
          <VButton
            variant="is-primary"
            outlined
            :icon-left="['far', 'bell']"
            @click="triggerProgressToast"
          >
            {{ progressValue === 0
              ? 'Start Progress'
              : progressValue === 50
                ? 'Complete Progress'
                : progressValue === 100 && Object.keys(violations).length === 0
                  ? 'Show Violations'
                  : 'Reset Progress' }}
          </VButton>
          <VButton
            variant="is-danger"
            outlined
            :icon-left="['far', 'times-circle']"
            @click="dismissToast"
          >
            Dismiss Toast
          </VButton>
        </div>
      </div>

      <!-- Custom Toast -->
      <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
        <h3 class="text-sm font-medium text-gray-700 dark:text-white mb-3">
          Custom Toast
        </h3>
        <div class="flex gap-2">
          <VButton
            variant="is-primary"
            outlined
            :icon-left="['far', 'bell']"
            @click="triggerCustomToast"
          >
            Trigger Custom Toast
          </VButton>
          <VButton
            variant="is-danger"
            outlined
            :icon-left="['far', 'times-circle']"
            @click="dismissToast"
          >
            Dismiss Toast
          </VButton>
        </div>
      </div>

      <!-- Toast Types -->
      <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
        <h3 class="text-sm font-medium text-gray-700 dark:text-white mb-3">
          Toast Types
        </h3>
        <div class="grid grid-cols-2 gap-2 sm:grid-cols-3">
          <VButton
            variant="is-success"
            outlined
            :icon-left="['far', 'check']"
            @click="sonner.success('Success message')"
          >
            Success
          </VButton>
          <VButton
            variant="is-danger"
            outlined
            :icon-left="['far', 'times']"
            @click="sonner.error('Error message')"
          >
            Error
          </VButton>
          <VButton
            variant="is-warning"
            outlined
            :icon-left="['far', 'triangle-exclamation']"
            @click="sonner.warning('Warning message')"
          >
            Warning
          </VButton>
          <VButton
            variant="is-info"
            outlined
            :icon-left="['far', 'spinner']"
            @click="sonner.loading('Loading...', { duration: 3000 })"
          >
            Loading
          </VButton>
          <VButton
            variant="is-secondary"
            outlined
            :icon-left="['far', 'file-export']"
            @click="toastWithAction"
          >
            With Action
          </VButton>
          <VButton
            outlined
            :icon-left="['far', 'cog']"
            @click="toastHeadless"
          >
            Headless
          </VButton>

          <VButton
            outlined
            :icon-left="['far', 'times']"
            @click="sonner.dismiss()"
          >
            Dismiss All
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>
