<script setup lang="ts">
import { OccupationState, TransitionEvent } from "@verbleif/lib";
import { useReservationState } from "./useReservationState";

const { reservationState } = useReservationState();
const JsonEditorVue = defineAsyncComponent(() =>
  import("json-editor-vue"),
);

enum Mode {
  text = "text",
  tree = "tree",
  table = "table",
}

const datePointState = computed(() => reservationState.value?.datePointState);
const currentReservation = computed(() => reservationState.value?.current);
const nextArrival = computed(() => reservationState.value?.nextArrival);
const lastDeparture = computed(() => reservationState.value?.lastDeparture);

const enums = computed(() => ({
  TransitionEvent: Object.entries(TransitionEvent)
    .filter(([key]) => Number.isNaN(Number(key)))
    .map(([key, value]) => ({ key, value })),
  OccupationState: Object.entries(OccupationState)
    .filter(([key]) => Number.isNaN(Number(key)))
    .map(([key, value]) => ({ key, value })),
}));
</script>

<template>
  <div class="px-4 max-h-[50vh]">
    <VTabs
      :active-index="0"
      lazy
      divider-between-tabs
      orientation="horizontal"
    >
      <VTabList>
        <VTabItem
          title="Date Point State"
          :icon="['fad', 'calendar']"
          :index="0"
        />
        <VTabItem
          title="Current Reservation"
          :icon="['fad', 'house']"
          :index="1"
        />
        <VTabItem
          title="Next Arrival"
          :icon="['fad', 'house-person-return']"
          :index="2"
        />
        <VTabItem
          title="Last Departure"
          :icon="['fad', 'house-person-leave']"
          :index="3"
        />
        <VTabItem
          title="Full State"
          :icon="['fad', 'database']"
          :index="4"
        />
        <VTabItem
          title="Enums"
          :icon="['fad', 'list']"
          :index="5"
        />
      </VTabList>
      <VTabPanels>
        <VTabPanel :index="0">
          <div v-if="datePointState" class="py-2">
            <JsonEditorVue
              :model-value="datePointState"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else>
            No date point state data available
          </div>
        </VTabPanel>
        <VTabPanel :index="1">
          <div v-if="currentReservation" class="py-2">
            <JsonEditorVue
              :model-value="currentReservation"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else>
            No current reservation data available
          </div>
        </VTabPanel>
        <VTabPanel :index="2">
          <div v-if="nextArrival" class="py-2">
            <JsonEditorVue
              :model-value="nextArrival"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else>
            No next arrival data available
          </div>
        </VTabPanel>
        <VTabPanel :index="3">
          <div v-if="lastDeparture" class="py-2">
            <JsonEditorVue
              :model-value="lastDeparture"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else>
            No last departure data available
          </div>
        </VTabPanel>
        <VTabPanel :index="4">
          <div v-if="reservationState" class="py-2">
            <JsonEditorVue
              :model-value="reservationState"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else>
            No reservation state data available
          </div>
        </VTabPanel>
        <VTabPanel :index="5">
          <div class="py-2 space-y-6">
            <div>
              <h3 class="text-xl font-semibold mb-2">
                Transition Events
              </h3>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="event in enums.TransitionEvent"
                  :key="event.key"
                  class="flex items-center gap-2 p-2 bg-gray-50 dark:bg-dark-800 rounded"
                >
                  <span class="font-mono">{{ event.value }}</span>
                  <span class="text-gray-600 dark:text-gray-400">{{ event.key }}</span>
                </div>
              </div>
            </div>
            <div>
              <h3 class="text-xl font-semibold mb-2">
                Occupation States
              </h3>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="state in enums.OccupationState"
                  :key="state.key"
                  class="flex items-center gap-2 p-2 bg-gray-50 dark:bg-dark-800 rounded"
                >
                  <span class="font-mono">{{ state.value }}</span>
                  <span class="text-gray-600 dark:text-gray-400">{{ state.key }}</span>
                </div>
              </div>
            </div>
          </div>
        </VTabPanel>
      </VTabPanels>
    </VTabs>
  </div>
</template>
