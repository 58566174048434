<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from "vue";

interface Props {
  mode?: "determinate" | "indeterminate"
  value?: number
  height?: string
  customClass?: string
  showPercentage?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: "determinate",
  value: 0,
  height: "1rem",
  customClass: "",
  showPercentage: true,
});

const currentWidth = ref(0);
const isIndeterminate = computed(() => props.mode === "indeterminate" || props.value === 0);
const formattedValue = computed(() => Math.round(props.value));

// Declare animationFrame outside the watch to maintain reference
let animationFrame: number | undefined;

const textColor = computed(() => {
  if (isIndeterminate.value) {
    return "text-transparent";
  }
  if (props.value >= 50) {
    return "text-white dark:text-white";
  }
  return "text-gray-700 dark:text-gray-300";
});

// Smooth progress animation
watch(() => props.value, (newValue) => {
  if (isIndeterminate.value) {
    currentWidth.value = 0;
    return;
  }

  // Cancel any existing animation
  if (animationFrame !== undefined) {
    cancelAnimationFrame(animationFrame);
  }

  const startWidth = currentWidth.value;
  const targetWidth = newValue;
  const startTime = performance.now();
  const duration = 400; // animation duration in ms

  function animate(currentTime: number) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);

    // Easing function for smoother animation using ** operator instead of Math.pow
    const eased = progress === 1 ? 1 : 1 - 2 ** (-10 * progress);

    currentWidth.value = startWidth + (targetWidth - startWidth) * eased;

    if (progress < 1) {
      animationFrame = requestAnimationFrame(animate);
    }
  }

  animationFrame = requestAnimationFrame(animate);
}, { immediate: true });

// Clean up animation frame on component unmount
onUnmounted(() => {
  if (animationFrame !== undefined) {
    cancelAnimationFrame(animationFrame);
  }
});
</script>

<template>
  <div
    class="w-full overflow-hidden rounded-2xl bg-gray-100 dark:bg-dark-700 relative"
    :class="[customClass]"
    :style="{ height }"
  >
    <div
      class="h-full bg-primary-500 rounded-2xl"
      :class="{
        'animate-progress': isIndeterminate,
        'bg-green-500': value === 100,
      }"
      :style="!isIndeterminate ? { width: `${currentWidth}%` } : {}"
    />
    <div
      v-if="showPercentage"
      class="absolute inset-0 flex items-center justify-center text-xs font-semibold z-10"
      :class="textColor"
    >
      {{ formattedValue }}%
    </div>
  </div>
</template>

<style scoped>
@keyframes progress {
  0% {
    width: 0%;
    margin-left: -20%;
  }
  100% {
    width: 40%;
    margin-left: 100%;
  }
}

.animate-progress {
  animation: progress 1.5s ease-in-out infinite;
}
</style>
