import type { AxiosResponse } from "axios";
import { api } from "@verbleif/shared";
import { createGlobalState, StorageSerializers, useStorage } from "@vueuse/core";
import { computed, ref } from "vue";

export const useMercureState = createGlobalState(
  () => {
    const mercureToken = useStorage<string | null>("mercure-sse-token", null, sessionStorage, {
      serializer: StorageSerializers.string,
    });
    const currentPromise = ref<Promise<AxiosResponse<{
      token: string
    }> > | null> (null);

    async function refreshMercureToken() {
      try {
        if (!currentPromise.value) {
          currentPromise.value = api.get<{
            token: string
          }>("/api/sse/token");
        }
        const { data } = await currentPromise.value;
        mercureToken.value = data.token;
      } finally {
        currentPromise.value = null;
      }
    }

    const params = computed(() => ({
      mercureTokenGetter: () => mercureToken.value || "",
      refreshMercureToken,
    }));

    return {
      mercureToken,
      refreshMercureToken,
      params,
    };
  },
);
