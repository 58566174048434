<script setup lang="ts">
import type { Placement } from "floating-vue";
import type { ComputedRef } from "vue";
import type { TabsContext } from "./types";
import { inject, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from "vue";

interface Props {
  title?: string
  icon?: string | string[]
  disabled?: boolean
  tooltip?: string
  tooltipPosition?: Placement
  index: number
  error?: boolean
  skeleton?: boolean
}

const {
  disabled = false,
  icon = undefined,
  tooltip = undefined,
  tooltipPosition = "right",
  index,
  title = "",
  error = false,
  skeleton = false,
} = defineProps<Props>();

const tabs = inject<TabsContext>("tabs")!;

onMounted(() => {
  tabs.registerTab({
    index,
    error,
    disabled,
  });
});

onBeforeUnmount(() => {
  tabs.unregisterTab(index);
});

watch([() => error, () => disabled], ([error, disabled]) => {
  const existingTab = tabs.tabItems.value.find((tab: { index: number }) => tab.index === index);
  if (existingTab) {
    existingTab.error = error ?? false;
    existingTab.disabled = disabled ?? false;
  }
});

const orientation = inject<ComputedRef<"horizontal" | "vertical">>("tabOrientation")!;
const tile = inject("tile", false);
const listPosition = inject<ComputedRef<"left" | "right">>("listPosition")!;

provide("error", error);

const titleRef = ref<HTMLSpanElement | null>(null);
const showTooltip = ref(false);

function checkTruncation() {
  if (titleRef.value) {
    const element = titleRef.value;
    showTooltip.value = element.scrollHeight > element.clientHeight
      || element.scrollWidth > element.clientWidth;
  }
}

onMounted(() => {
  nextTick(checkTruncation);
});

watch(() => title, () => {
  nextTick(checkTruncation);
});

function handleClick() {
  if (!disabled) {
    tabs.onTabClick(index);
  }
}

function handleKeyDown(event: KeyboardEvent) {
  if (!disabled && (event.key === "Enter" || event.key === " ")) {
    event.preventDefault();
    handleClick();
  }
}
</script>

<template>
  <template v-if="skeleton">
    <div
      class="tab-item flex items-center cursor-pointer select-none gap-2 transition-all duration-200 ease-in-out dark:text-gray-200"
      :class="[
        {
          'flex-col items-center justify-center text-center p-1 rounded-lg': tile,
          'px-4 py-2.5': !tile,
        },
        orientation === 'horizontal' && tile ? 'min-w-[88px] px-4' : '',
        orientation === 'vertical' && (tile ? 'min-w-[100px] max-w-[100px]' : 'max-w-[200px]'),
        orientation === 'horizontal' ? 'rounded-lg' : 'w-full',
      ]"
    >
      <template v-if="tile">
        <VSkeleton
          :width="32"
          width-unit="px"
          :dynamic-width="false"
          :height="32"
          :pill="false"
          class="w-8 h-8 p-1.5 rounded-lg bg-gray-100 dark:bg-dark-700"
        />
        <VSkeleton
          :width="orientation === 'vertical' ? 80 : 80"
          width-unit="px"
          :dynamic-width="false"
          :height="20"
          :pill="true"
        />
      </template>
      <template v-else>
        <VSkeleton
          :width="24"
          width-unit="px"
          :dynamic-width="false"
          :height="24"
          :pill="false"
          class="rounded-lg bg-gray-100 dark:bg-dark-700"
        />
        <VSkeleton
          :width="orientation === 'vertical' ? 160 : 80"
          width-unit="px"
          :dynamic-width="false"
          :height="20"
          :pill="true"
        />
      </template>
    </div>
  </template>
  <VTooltip v-else :content="tooltip || title" :placement="tooltipPosition" :disabled="!tooltip && !showTooltip">
    <template #reference>
      <div
        class="tab-item flex items-center select-none gap-1 transition-all duration-200 ease-in-out dark:text-gray-200 outline-none"
        :class="[
          {
            'text-primary-600 dark:text-primary-400': tabs.activeIndex.value === index && !disabled,
            'cursor-not-allowed text-gray-400 dark:text-gray-600': disabled,
            'flex-col items-center justify-center text-center p-1': tile,
            'px-4 py-2.5': !tile,
          },
          error && '!text-red-600 !dark:text-red-400 !dark:bg-red-800',
          orientation === 'horizontal' && tile ? 'min-w-[88px] px-4' : '',
          orientation === 'vertical' && (tile ? 'min-w-[100px] max-w-[100px]' : 'max-w-[200px]'),
          orientation === 'horizontal' ? 'rounded-lg' : 'w-full',
          orientation === 'vertical' && tile && tabs.activeIndex.value === index ? 'dark:bg-dark-800' : '',
          orientation === 'vertical' && !tile && tabs.activeIndex.value === index ? [
            listPosition === 'left'
              ? 'border-r border-primary-600 dark:border-primary-400'
              : 'border-l border-primary-600 dark:border-primary-400',
          ] : '',
          !disabled ? [
            'cursor-pointer hover:bg-gray-50 dark:hover:bg-dark-700',
            'focus:bg-gray-50 dark:focus:bg-dark-700',
          ] : '',
        ]"
        role="tab"
        :tabindex="disabled ? -1 : 0"
        :aria-selected="tabs.activeIndex.value === index"
        :aria-disabled="disabled"
        @click="handleClick"
        @keydown="handleKeyDown"
      >
        <div
          v-if="icon"
          class="flex items-center justify-center"
          :class="[
            error && '!text-red-600 !dark:text-red-400',
            tile ? [
              'w-8 h-8 p-1.5 rounded-lg bg-gray-100 dark:bg-dark-700',
              tabs.activeIndex.value === index && !error && !disabled && 'text-primary-600 dark:text-primary-400 bg-primary-100 dark:bg-primary-800/40',
              error && tabs.activeIndex.value === index && 'bg-red-100 dark:bg-red-800/40',
            ] : '',
          ]"
        >
          <FontAwesomeIcon :icon="icon" class="text-base" />
        </div>
        <span
          ref="titleRef"
          class=" text-sm leading-3"
          :class="[
            tile ? 'text-center' : 'whitespace-nowrap',
          ]"
        >
          {{ title }}
        </span>
      </div>
    </template>
  </VTooltip>
</template>
