import { cva } from "class-variance-authority";

export const badgeCva = cva("inline-flex items-center justify-center rounded-full transition-colors relative", {
  variants: {
    color: {
      red: ["bg-red-500", "text-white"],
      green: ["bg-green-500", "text-white"],
      blue: ["bg-blue-500", "text-white"],
      orange: ["bg-orange-500", "text-white"],
      yellow: ["bg-yellow-500", "text-white"],
      purple: ["bg-purple-500", "text-white"],
      gray: ["bg-gray-500", "text-white"],
    },
    size: {
      xs: ["w-2", "h-2"],
      sm: ["w-2.5", "h-2.5"],
      md: ["w-3.5", "h-3.5"],
      lg: ["w-4", "h-4"],
      xl: ["w-5", "h-5"],
    },
    disabled: {
      true: ["opacity-50", "cursor-not-allowed"],
      false: null,
    },
    pulse: {
      true: ["relative"],
      false: null,
    },
  },
  defaultVariants: {
    color: "blue",
    size: "md",
  },
});

export const badgePulseCva = cva("animate-badge-pulse absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-full h-full", {
  variants: {
    color: {
      red: ["bg-red-500/50"],
      green: ["bg-green-500/50"],
      blue: ["bg-blue-500/50"],
      orange: ["bg-orange-500/50"],
      yellow: ["bg-yellow-500/50"],
      purple: ["bg-purple-500/50"],
      gray: ["bg-gray-500/50"],
    },
  },
  defaultVariants: {
    color: "blue",
  },
});
