/**
 * Interface for cached data with timestamp
 */
export interface CachedItem<T> {
  data: T
  timestamp: number
}

/**
 * Maximum number of property parameters that can be sent in a single API request
 */
export const MAX_PROPERTY_BATCH_SIZE = 100;

/**
 * Cache expiration time in seconds
 */
export const CACHE_EXPIRATION_TIME = 15;

/**
 * Check if a cache timestamp is expired
 */
export function isExpired(timestamp: number): boolean {
  const now = Math.floor(Date.now() / 1000);
  return now - timestamp > CACHE_EXPIRATION_TIME;
}
