import { createGlobalState, StorageSerializers, useStorage } from "@vueuse/core";
import { computed } from "vue";

export const useImpersonationStore = createGlobalState(() => {
  const impersonationUserIdentifier = useStorage<string | null>("impersonationUserIdentifier", null, sessionStorage, { serializer: StorageSerializers.string });
  const isImpersonating = computed<boolean>(() => {
    return impersonationUserIdentifier.value !== null;
  });

  const getRequestHeaders = computed<Record<string, string>>(() => {
    const headers = <Record<string, string>>{};

    if (
      impersonationUserIdentifier.value !== null
    ) {
      headers["X-Switch-User"] = impersonationUserIdentifier.value;
    }

    return headers;
  });

  function setImpersonationUserIdentifier(username: string): void {
    impersonationUserIdentifier.value = username;
  }

  function reset() {
    impersonationUserIdentifier.value = null;
  }

  return {
    isImpersonating,
    impersonationUserIdentifier,
    getRequestHeaders,
    setImpersonationUserIdentifier,
    reset,
  };
});
