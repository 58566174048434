import type { UserHydraItem, UserRole } from "@verbleif/lib";
import type { Ref } from "vue";
import * as Sentry from "@sentry/vue";
import { PermissionAttributes } from "@verbleif/lib";
import { createGlobalState, StorageSerializers, useStorage } from "@vueuse/core";
import { computed, ref } from "vue";
import { api } from "../Api";

export const useAuthStore = createGlobalState(() => {
  const token = useStorage<string | null>("token", null, sessionStorage, {
    serializer: StorageSerializers.string,
  });

  const returnUrl = useStorage<string | null>("return-url", null, localStorage, {
    serializer: StorageSerializers.string,
  });

  const device = useStorage<{
    id: string
    token: string
  } | null>("device", null, localStorage, {
    serializer: StorageSerializers.object,
  });

  const refreshToken = useStorage<string | null>(
    "refresh_token",
    null,
    sessionStorage,
    { serializer: StorageSerializers.string },
  );

  const refreshTokenRemembered = useStorage<string | null>(
    "refresh-token",
    null,
    sessionStorage,
    { serializer: StorageSerializers.string },
  );

  const user = ref<UserHydraItem | null>(null);

  let userPermissions: Ref<string[]>;
  let permissions: Ref<Record<string, string[]> | null>;

  if (import.meta.env.VITE_ALL_PERMISSIONS === "true") {
    userPermissions = ref<string[]>([
      PermissionAttributes.global["*"].path,
      PermissionAttributes.global.operation["*"].path,
    ]);
    permissions = ref<Record<string, string[]> | null>({
      "/api/clients/1": [
        // PermissionAttributes.CONTEXT.CLIENT.OPERATION.THIRD_PARTY.READ_COLLECTION_VIA_CASE_FILE,
      ],
    });
  } else {
    permissions = useStorage<Record<string, string[]> | null>(
      "permissions",
      null,
      undefined,
      { serializer: StorageSerializers.object },
    ) as any as Ref<Record<string, string[]> | null>;

    userPermissions = useStorage<string[]>(
      "user-permissions",
      [],
    ) as any as Ref<string[]>;
  }

  const userPreferences = useStorage<{
    locale: string
    timezone: string
  }>(
    "user-preferences",
    {
      locale: "NL",
      timezone: "Europe/Amsterdam",
    },
    undefined,
    { serializer: StorageSerializers.object },
  );

  function setUserPreferences(user: UserHydraItem | null) {
    if (user === null) {
      return;
    }

    if (user.locale) {
      userPreferences.value.locale = user.locale.toUpperCase();
    }
    if (user.timezone) {
      userPreferences.value.timezone = user.timezone;
    }
  }

  const isAuthenticated = computed(() => {
    return user.value !== null;
  });

  const isRemembered = computed(() => {
    return refreshTokenRemembered.value !== null;
  });

  const hasToken = computed(() => {
    return token.value !== null;
  });

  const getRefreshToken = computed<string | null>(() => {
    return refreshToken.value
      ? refreshToken.value
      : refreshTokenRemembered.value;
  });

  const getRequestHeaders = computed<Record<string, string>>(() => {
    return {
      Authorization: `Bearer ${token.value}`,
    };
  });

  const tokenPayload = computed<Record<string, string> | null>(
    (): null | Record<string, string> => {
      if (token.value === null) {
        return null;
      }

      const base64Url = token.value.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
          })
          .join(""),
      );

      const parsed: any = JSON.parse(jsonPayload);
      if (!parsed || typeof parsed !== "object") {
        return null;
      }

      return <Record<string, string>>parsed;
    },
  );

  function setRefreshToken(refresh: string | null, remembered: boolean = isRemembered.value): void {
    if (!refresh) {
      refreshToken.value = null;
      refreshTokenRemembered.value = null;

      return;
    }
    refreshToken.value = refresh;

    if (remembered) {
      refreshTokenRemembered.value = refreshToken.value;
    }
  }

  function setToken(t: string) {
    token.value = t;
  }

  function setUser(userInput: UserHydraItem | null): void {
    user.value = {
      ...userInput,
      "@id": `/api/users/${userInput?.id}`,
    } as UserHydraItem;
    setUserPreferences(userInput);
  }

  function setUserPermissions(permissions: UserHydraItem["permissions"]): void {
    if (!user.value) {
      return;
    }
    user.value.permissions = permissions;
  }

  function patchUser(us: Partial<UserHydraItem>): void {
    if (!user.value) {
      return;
    }
    Object.assign(user.value, us);
  }

  async function initUser(): Promise<void> {
    const { data: user } = await api.get<UserHydraItem>("/api/users/me");
    setUser(user);

    if (import.meta.env.PROD) {
      Sentry.setUser(user);
    }
  }

  async function initPermissions() {
    if (import.meta.env.VITE_ALL_PERMISSIONS !== "true") {
      const userIri = user.value?.["@id"];
      if (!userIri) {
        return;
      }

      permissions.value = {};
      userPermissions.value = [];

      if (user.value?.permissions) {
        for (const permission of user.value.permissions) {
          if (!permissions.value) {
            return;
          }
          userPermissions.value = permission.attributes;
        }
      }
    } else {
      console.log("ALL_PERMISSIONS is true");
    }
  }

  function hasRole(role: UserRole): boolean {
    return user.value?.roles.includes(role) ?? false;
  }

  function reset(keepReturnUrl = false): void {
    token.value = null;
    refreshToken.value = null;
    refreshTokenRemembered.value = null;
    user.value = null;
    if (!keepReturnUrl) {
      returnUrl.value = null;
    }
    permissions.value = null;
    userPermissions.value = [];
  }

  return {
    token,
    returnUrl,
    refreshToken,
    setUserPermissions,
    refreshTokenRemembered,
    user,
    userPermissions,
    permissions,
    userPreferences,
    setUserPreferences,
    isAuthenticated,
    isRemembered,
    hasToken,
    getRefreshToken,
    getRequestHeaders,
    tokenPayload,
    device,
    setToken,
    setRefreshToken,
    setUser,
    patchUser,
    initUser,
    initPermissions,
    hasRole,
    reset,
  };
});

// function createAuthStore() {
//   const token = useSessionStorage<string | null>("token", null);
//   const refreshTokenRemembered = useLocalStorage<string | null>("refreshTokenRemembered", null);
//   const refreshToken = useSessionStorage<string | null>("refreshToken", null);
//   const client = useStorage<HydraItem<Client> | null>(
//     "client",
//     null,
//     undefined,
//     { serializer: StorageSerializers.object },
//   );
//   const user = ref<HydraItem<User> | null>(null);
//   let userPermissions: Ref<string[]>;
//   let permissions: Ref<Record<string, string[]> | null>;
//   if (import.meta.env.VITE_ALL_PERMISSIONS === "true") {
//     console.log("ALL_PERMISSIONS is true");
//     userPermissions = ref<string[]>([
//       PermissionAttributes.GLOBAL.FIELD["*"].path,
//       PermissionAttributes.GLOBAL.OPERATION["*"].path,
//     ]);
//     permissions = ref<Record<string, string[]> | null>({
//       "/api/clients/1": [
//         // PermissionAttributes.CONTEXT.CLIENT.OPERATION.THIRD_PARTY.READ_COLLECTION_VIA_CASE_FILE,
//       ],
//     });
//   } else {
//     console.log("ALL_PERMISSIONS is false");
//     permissions = useStorage<Record<string, string[]> | null>(
//       "permissions",
//       null,
//       undefined,
//       {
//         serializer: StorageSerializers.object,
//       },
//     );

//     userPermissions = useStorage<string[]>(
//       "user-permissions",
//       [],
//     );
//   }

//   const returnUrl = useSessionStorage<string | null>("returnUrl", null);

//   const isAuthenticated = computed(() => {
//     return user.value !== null;
//   });

//   const isRemembered = computed(() => {
//     return refreshToken.value !== null;
//   });

//   const hasToken = computed(() => {
//     return token.value !== null;
//   });

//   const getRefreshToken = computed<string | null>(() => {
//     return refreshToken.value
//       ? refreshToken.value
//       : refreshTokenRemembered.value;
//   });

//   const getRequestHeaders = computed<Record<string, string>>(() => {
//     return {
//       Authorization: `Bearer ${token.value}`,
//     };
//   });

//   const tokenPayload = computed<Record<string, string> | null>(
//     (): null | Record<string, string> => {
//       if (token.value === null) {
//         return null;
//       }

//       const base64Url = token.value.split(".")[1];
//       const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//       const jsonPayload = decodeURIComponent(
//         atob(base64)
//           .split("")
//           .map((c) => {
//             return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
//           })
//           .join(""),
//       );

//       const parsed: any = JSON.parse(jsonPayload);
//       if (!parsed || typeof parsed !== "object") {
//         return null;
//       }

//       return <Record<string, string>>parsed;
//     },
//   );

//   const userPreferences = useStorage<{
//     locale: string
//     timezone: string
//   }>(
//     "user-preferences",
//     {
//       locale: "NL",
//       timezone: "Europe/Amsterdam",
//     },
//     undefined,
//     { serializer: StorageSerializers.object },
//   );

//   function setUserPreferences(user: UserHydraItem | null) {
//     if (user === null) {
//       return;
//     }

//     if (user.locale) {
//       userPreferences.value.locale = user.locale.toUpperCase();
//     }
//     if (user.timezone) {
//       userPreferences.value.timezone = user.timezone;
//     }
//   }

//   async function initUser(): Promise<void> {
//     const { data: user } = await api.get<UserHydraItem>("/api/users/me");
//     setUser(user as HydraItem<User>);
//   }

//   function setToken(inputToken: string | null): void {
//     token.value = inputToken;
//   }

//   function setUser(userInput: HydraItem<User> | null): void {
//     user.value = {
//       ...userInput,
//       "@id": `/api/users/${userInput?.id}`,
//       "@type": "User",
//     } as HydraItem<User>;
//     setUserPreferences(userInput as UserHydraItem);
//   }

//   function setClient(clientInput: HydraItem<Client> | null): void {
//     client.value = clientInput;
//   }

//   function patchUser(us: Partial<User>): void {
//     if (!user.value) {
//       return;
//     }
//     Object.assign(user.value, us);
//   }

//   function setRefreshToken(refresh: string | null, remembered: boolean = isRemembered.value): void {
//     console.log("[Triggered setRefreshToken]", refresh, remembered);
//     if (!refresh) {
//       refreshToken.value = null;
//       refreshTokenRemembered.value = null;

//       return;
//     }
//     refreshToken.value = refresh;

//     if (remembered) {
//       refreshTokenRemembered.value = refreshToken.value;
//     }
//   }

//   function reset(): void {
//     setToken(null);
//     setRefreshToken(null);
//     client.value = null;
//     user.value = null;
//     returnUrl.value = null;
//   }

//   return {
//     getRequestHeaders,
//     client,
//     refreshTokenRemembered,
//     user,
//     tokenPayload,
//     getRefreshToken,
//     token,
//     isRemembered,
//     refreshToken,
//     hasToken,
//     isAuthenticated,
//     initUser,
//     patchUser,
//     reset,
//     setClient,
//     setUser,
//     setRefreshToken,
//     setToken,
//     returnUrl,
//     userPermissions,
//     permissions,
//   };
// }

// type AuthStoreInterface = ReturnType<typeof createAuthStore>;
// let backwardsCompatibleInstance: null | AuthStoreInterface = null;
// export function useAuthStore(): AuthStoreInterface {
//   if (backwardsCompatibleInstance === null) {
//     backwardsCompatibleInstance = createAuthStore();
//   }

//   return backwardsCompatibleInstance;
// }
// export default useAuthStore;
// export default useAuthStore;
// export default useAuthStore;
