<script setup lang="ts">
import moment from "moment";
import { useRouter } from "vue-router";
import { useReservationState } from "../useReservationState";

const { dialog } = defineProps<{
  dialog?: boolean
}>();

const { loading, taskState } = useReservationState();
const router = useRouter();

function formatDate(date: string | Date | undefined) {
  if (!date) {
    return "";
  }
  return moment(date).format("DD MMMM");
}

function openTask(task: string) {
  const taskId = task.split("/").pop();
  const propertyId = taskState?.value?.property.split("/").pop();
  if (taskId && propertyId) {
    router.push(`/t/li/po/${propertyId}/t${taskId}`);
  }
}
</script>

<template>
  <div class="px-[15px]">
    <div :class="dialog ? 'grid grid-cols-1 gap-4 xl:max-w-[300px]' : 'grid grid-cols-1 xl:grid-cols-3 gap-4'">
      <div class="col-span-1">
        <div class="text-sm text-gray-800 dark:text-gray-200 flex flex-col gap-2 xl:max-w-[350px]">
          <span class="font-bold w-full">
            {{ $t('tasks.reservation_state.property.info.title') }}
          </span>

          <template v-if="loading">
            <div v-for="n in 3" :key="n" class="flex items-center gap-3">
              <span class="flex-1">
                <VSkeleton :width="60" width-unit="%" :height="14" />
              </span>
              <div class="flex items-center gap-2">
                <span class="w-16 text-left">
                  <VSkeleton :width="48" width-unit="px" :height="14" />
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center gap-3">
              <div class="w-3">
                <FontAwesomeIcon
                  :class="taskState?.lastLegionellaRinseAt ? 'text-green-500' : 'text-red-500'"
                  :icon="['fad', 'faucet-drip']"
                />
              </div>
              <span class="flex-1 hover:cursor-pointer">
                {{ $t('tasks.section.object_status.legionella') }}:
              </span>
              <div class="flex items-center gap-2 group hover:cursor-pointer hover:text-primary-800 dark:hover:text-primary-400" @click="taskState?.lastLegionellaRinseTask && openTask(taskState?.lastLegionellaRinseTask)">
                <FontAwesomeIcon v-if="taskState?.lastLegionellaRinseAt" class="text-primary-600 dark:text-primary-400 group-hover:text-primary-800 dark:group-hover:text-primary-400" :icon="['fad', 'up-right-from-square']" />
                <span class="text-left group-hover:text-primary-800 dark:group-hover:text-primary-400">
                  {{ taskState?.lastLegionellaRinseAt ? formatDate(taskState?.lastLegionellaRinseAt) : $t("base.none") }}
                </span>
              </div>
            </div>

            <div class="flex items-center gap-3">
              <div class="w-3">
                <FontAwesomeIcon
                  :class="taskState?.lastCleaningAt ? 'text-green-500' : 'text-red-500'"
                  :icon="['fad', 'broom']"
                />
              </div>
              <span class="flex-1">
                {{ $t('tasks.section.object_status.cleaning') }}:
              </span>
              <div class="flex items-center gap-2 group hover:cursor-pointer hover:text-primary-800 dark:hover:text-primary-400" @click="taskState?.lastCleaningTask && openTask(taskState?.lastCleaningTask)">
                <FontAwesomeIcon v-if="taskState?.lastCleaningAt" class="text-primary-600 dark:text-primary-400 group-hover:text-primary-800 dark:group-hover:text-primary-400" :icon="['fad', 'up-right-from-square']" />
                <span class="text-left group-hover:text-primary-800 dark:group-hover:text-primary-400">
                  {{ taskState?.lastCleaningAt ? formatDate(taskState?.lastCleaningAt) : $t("base.none") }}
                </span>
              </div>
            </div>
            <div class="flex items-center gap-3">
              <div class="w-3">
                <FontAwesomeIcon
                  :icon="['fad', 'clipboard-list']"
                />
              </div>
              <span class="flex-1">
                {{ $t('tasks.section.object_status.open_tasks', { count: taskState?.openTaskCountOnDatePoint }) }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
