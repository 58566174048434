import type { HydraCollection, PropertyHydraItem, PropertyReservationStateHydraItem, PropertyTaskStateHydraItem, ReportHydraItem, SkeletonItem, TaskHydraItem } from "@verbleif/lib";
import type { CachedItem } from "./types";
import { iriToId } from "@verbleif/lib";
import { api } from "@verbleif/shared";
import { createGlobalState } from "@vueuse/core";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import { isExpired, MAX_PROPERTY_BATCH_SIZE } from "./types";

export const usePropertyStates = createGlobalState(() => {
  const propertyStates = ref<Record<number, CachedItem<{
    reservationState: PropertyReservationStateHydraItem | null
    taskState: PropertyTaskStateHydraItem | null
  }>>>({});
  const loading = ref(false);
  const loadingSingle = ref<number | null>(null);

  function resetPropertyStatesCache() {
    const newPropertyStates: Record<number, CachedItem<{
      reservationState: PropertyReservationStateHydraItem | null
      taskState: PropertyTaskStateHydraItem | null
    }>> = {};

    Object.entries(propertyStates.value).forEach(([key, value]) => {
      if (value) {
        newPropertyStates[Number(key)] = {
          data: value.data,
          timestamp: 0, // Reset timestamp to force refresh
        };
      }
    });

    propertyStates.value = newPropertyStates;
  }

  async function getPropertyStates(items: (TaskHydraItem | SkeletonItem | PropertyHydraItem | ReportHydraItem)[]) {
    if (loading.value) {
      return;
    }
    try {
      loading.value = true;
      const propertyIris = items
        .filter((item): item is TaskHydraItem | PropertyHydraItem | ReportHydraItem => {
          if ((item as TaskHydraItem)["@type"] === "Task") {
            return "@id" in item && (item as TaskHydraItem).property !== undefined && (item as TaskHydraItem).property !== null;
          } else if ((item as ReportHydraItem)["@type"] === "Report") {
            return "@id" in item && (item as ReportHydraItem).property !== undefined && (item as ReportHydraItem).property !== null;
          } else if ((item as PropertyHydraItem)["@type"] === "Property") {
            return "@id" in item;
          }
          return false;
        })
        .map((item) => {
          if ((item as TaskHydraItem)["@type"] === "Task") {
            return iriToId((item as TaskHydraItem).property as string);
          } else if ((item as ReportHydraItem)["@type"] === "Report") {
            return iriToId((item as ReportHydraItem).property as string);
          } else if ((item as PropertyHydraItem)["@type"] === "Property") {
            return iriToId((item as PropertyHydraItem)["@id"]);
          }
          return null;
        })
        .filter((id): id is number => id !== null);

      const propertiesToFetchSet: Set<number> = new Set();

      for (const propertyId of propertyIris) {
        if (propertiesToFetchSet.has(propertyId)) {
          continue;
        }

        if (propertyStates.value[propertyId] && !isExpired(propertyStates.value[propertyId].timestamp)) {
          continue;
        }
        propertiesToFetchSet.add(propertyId);
      }

      if (propertiesToFetchSet.size === 0) {
        return;
      }

      const propertiesToFetch = Array.from(propertiesToFetchSet);
      // Split into batches of MAX_PROPERTY_BATCH_SIZE
      const batches: number[][] = [];
      for (let i = 0; i < propertiesToFetch.length; i += MAX_PROPERTY_BATCH_SIZE) {
        batches.push(propertiesToFetch.slice(i, i + MAX_PROPERTY_BATCH_SIZE));
      }

      const now = Math.floor(Date.now() / 1000);

      // Process each batch sequentially
      for (const batch of batches) {
        const searchParams = new URLSearchParams();

        for (const propertyId of batch) {
          searchParams.append("property[]", propertyId.toString());
        }

        searchParams.append("datePointAt", dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"));

        // Fetch both reservation states and task states
        const [reservationResponse, taskResponse] = await Promise.all([
          api.get<HydraCollection<PropertyReservationStateHydraItem>>("/api/property_reservation_states", {
            params: searchParams,
          }),
          api.get<HydraCollection<PropertyTaskStateHydraItem>>("/api/property_task_states", {
            params: searchParams,
          }),
        ]);

        // Create a map for quicker access
        const reservationStatesMap: Record<number, PropertyReservationStateHydraItem> = {};
        for (const item of reservationResponse.data.member) {
          const propertyId = iriToId(item.property);
          reservationStatesMap[propertyId] = item;
        }

        const taskStatesMap: Record<number, PropertyTaskStateHydraItem> = {};
        for (const item of taskResponse.data.member) {
          const propertyId = iriToId(item.property);
          taskStatesMap[propertyId] = item;
        }

        // Combine both states
        for (const propertyId of batch) {
          propertyStates.value[propertyId] = {
            data: {
              reservationState: reservationStatesMap[propertyId] || null,
              taskState: taskStatesMap[propertyId] || null,
            },
            timestamp: now,
          };
        }
      }
    } catch (error) {
      console.error("[usePropertyStates] error fetching property states", error);
      if (isAxiosError(error) && [403, 401].includes(error.response?.status ?? 0)) {
        return;
      }
      throw error;
    } finally {
      loading.value = false;
    }
  }

  async function getPropertyState(propertyId: number) {
    if (propertyStates.value[propertyId] && !isExpired(propertyStates.value[propertyId].timestamp)) {
      return propertyStates.value[propertyId].data;
    }

    if (loadingSingle.value) {
      return null;
    }

    try {
      loadingSingle.value = propertyId;
      const searchParams = new URLSearchParams();
      searchParams.append("datePointAt", dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"));

      // Fetch both reservation state and task state
      const [reservationResponse, taskResponse] = await Promise.all([
        api.get<PropertyReservationStateHydraItem>(`/api/properties/${propertyId}/reservation_state`, {
          params: searchParams,
        }).catch(() => ({ data: null })),
        api.get<PropertyTaskStateHydraItem>(`/api/properties/${propertyId}/task_state`, {
          params: searchParams,
        }).catch(() => ({ data: null })),
      ]);

      const now = Math.floor(Date.now() / 1000);

      const stateData = {
        reservationState: reservationResponse.data,
        taskState: taskResponse.data,
      };

      propertyStates.value[propertyId] = {
        data: stateData,
        timestamp: now,
      };

      return stateData;
    } catch (error) {
      console.error("[usePropertyStates] error fetching single property state", error);
      if (isAxiosError(error) && [403, 401].includes(error.response?.status ?? 0)) {
        return null;
      }
      throw error;
    } finally {
      loadingSingle.value = null;
    }
  }

  function reset() {
    propertyStates.value = {};
  }

  return {
    propertyStates,
    loading,
    loadingSingle,
    getPropertyStates,
    getPropertyState,
    reset,
    resetPropertyStatesCache,
  };
});
