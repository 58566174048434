<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useComponentId } from "../useComponentId";

interface Props {
  name?: string
  iconLeft?: string | object
  hasBorder?: boolean
  errorMessage?: string
  autoResize?: boolean
  disabled?: boolean
  editable?: boolean
  labelLeft?: string
  labelTop?: string
  required?: boolean
  placeholder?: string
  maxHeight?: string
  hideClear?: boolean
  rows?: string | number
  size?: "small" | "medium" | "large"
  maxLength?: number
  showCharacterCount?: boolean
  spellcheck?: boolean
  readonly?: boolean
  minHeight?: string
  invalid?: boolean
  showErrorBelow?: boolean
  hasPerms?: boolean
}

const {
  name = "",
  iconLeft = undefined,
  hasBorder = true,
  errorMessage = "",
  autoResize = false,
  disabled = false,
  editable = true,
  labelLeft = "",
  labelTop = "",
  required = false,
  placeholder = "",
  maxHeight = "300px",
  hideClear = false,
  rows = "3",
  size = "medium",
  maxLength = undefined,
  showCharacterCount = false,
  spellcheck = true,
  readonly = false,
  minHeight = "85px",
  invalid = false,
  hasPerms = true,
  showErrorBelow = false,
} = defineProps<Props>();

const emit = defineEmits<{
  (e: "blur"): void
  (e: "focus"): void
  (e: "keydown", event: KeyboardEvent): void
  (e: "keyup", event: KeyboardEvent): void
}>();

const isDisabled = computed(() => disabled || !hasPerms);

const model = defineModel<string>({ default: "" });

const componentId = useComponentId();
const id = inject("FieldId", componentId)?.toString();
const value = ref(model.value);
const textAreaRef = ref<HTMLTextAreaElement | null>(null);

let resizeObserver: ResizeObserver | null = null;
let originalHeight = 0;
let originalY = 0;

watch(
  () => model.value,
  (newValue) => {
    if (newValue !== value.value) {
      value.value = newValue;
    }
  },
);

watch(value, (newValue) => {
  model.value = newValue;
});

function resize() {
  if (!textAreaRef.value?.offsetParent) {
    return;
  }

  textAreaRef.value.style.height = "auto";
  textAreaRef.value.style.height = `${textAreaRef.value.scrollHeight}px`;

  if (Number.parseFloat(textAreaRef.value.style.height) >= Number.parseFloat(maxHeight)) {
    textAreaRef.value.style.overflowY = "scroll";
    textAreaRef.value.style.height = maxHeight;
  } else {
    textAreaRef.value.style.overflow = "hidden";
  }
}

function onInput(event: Event) {
  const target = event.target as HTMLTextAreaElement;
  let newValue = target.value;

  // Apply maxLength constraint if set
  if (maxLength !== undefined) {
    newValue = newValue.slice(0, maxLength);
  }

  if (autoResize) {
    resize();
  }

  value.value = newValue;
}

function initResize(e: MouseEvent | TouchEvent) {
  const resizeHandle = (e.target as HTMLElement).closest(".cursor-ns-resize");
  if (!resizeHandle || autoResize || isDisabled.value || !editable) {
    return;
  }

  e.preventDefault();
  if (textAreaRef.value) {
    originalHeight = textAreaRef.value.offsetHeight;
    if (e instanceof MouseEvent) {
      originalY = e.clientY;
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", stopResize);
    } else if (e instanceof TouchEvent) {
      originalY = e.touches[0].clientY;
      window.addEventListener("touchmove", handleResize);
      window.addEventListener("touchend", stopResize);
    }
  }
}

function handleResize(e: MouseEvent | TouchEvent) {
  if (!textAreaRef.value) {
    return;
  }

  const currentY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
  const height = Math.max(
    85, // min-height
    Math.min(
      originalHeight + (currentY - originalY),
      Number.parseInt(maxHeight),
    ),
  );

  textAreaRef.value.style.height = `${Math.round(height)}px`;
}

function stopResize() {
  window.removeEventListener("mousemove", handleResize);
  window.removeEventListener("mouseup", stopResize);
  window.removeEventListener("touchmove", handleResize);
  window.removeEventListener("touchend", stopResize);
}

function onPaste() {
  if (autoResize) {
    nextTick(() => {
      resize();
    });
  }
}

onMounted(() => {
  if (autoResize && textAreaRef.value) {
    resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(textAreaRef.value as unknown as Element);
  }
});

onBeforeUnmount(() => {
  stopResize();
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

function clearValue() {
  value.value = "";
  textAreaRef.value?.focus();
}

const showErrorUnder = computed(() => {
  return showErrorBelow || (errorMessage && value.value);
});

const placeholderText = computed(() => {
  if (errorMessage && !showErrorUnder.value) {
    return errorMessage;
  }
  return placeholder;
});
</script>

<template>
  <div
    class="relative w-full dark:text-white"
    :class="{
      'flex flex-row items-center gap-2': labelLeft,
      'flex flex-col': labelTop,
    }"
  >
    <label v-if="labelLeft" :for="id" class="text-sm leading-4 flex items-center gap-1 dark:text-gray-300">
      {{ labelLeft }}
      <span v-if="required" class="text-secondary-600 font-bold">*</span>
    </label>

    <label v-if="labelTop" :for="id" class="text-sm leading-4 mb-1 flex items-center gap-1 dark:text-gray-300">
      {{ labelTop }}
      <span v-if="required" class="text-secondary-600 font-bold">*</span>
    </label>
    <ACTooltip
      type="edit_field"
      :allow-change="hasPerms"
    >
      <div class="relative flex-grow flex flex-col gap-1" :class="{ 'w-2/3': labelLeft }">
        <div v-if="iconLeft" class="absolute left-3 top-3 text-primary z-10 dark:text-gray-400">
          <FontAwesomeIcon v-if="iconLeft" :icon="iconLeft" />
        </div>

        <div
          class="relative overflow-hidden rounded-[20px] bg-white dark:bg-dark-800 transition-colors duration-200"
          :class="[
            {
              'border border-solid border-gray-300 dark:border-dark-600': hasBorder,
              '!border-danger-500': errorMessage,
              '!opacity-50': isDisabled,
              'focus-within:bg-input-background-active dark:focus-within:bg-dark-700': !isDisabled && editable,
            },
            invalid ? 'border-danger-600' : 'border-gray-300',
          ]"
          @mousedown="initResize"
          @touchstart="initResize"
        >
          <textarea
            :id="id"
            ref="textAreaRef"
            v-model="value"
            :name="name"
            :rows="rows"
            :disabled="isDisabled || !editable"
            :placeholder="placeholderText"
            class="w-full min-h-[85px] px-4 py-2.5 text-sm font-roboto !bg-white dark:!bg-dark-800 transition-colors duration-200 focus:outline-none placeholder:text-gray-400 dark:placeholder:text-dark-400 resize-none overflow-auto"
            :class="[
              {
                'pl-10': iconLeft,
                'text-danger-500': errorMessage && !showErrorUnder,
                'placeholder:!text-danger-500': errorMessage && !showErrorUnder,
                'opacity-50 cursor-not-allowed': isDisabled,
                'text-sm': size === 'small',
                'text-base': size === 'medium',
                'text-xl': size === 'large',
                'pr-4': autoResize,
                'pb-6': !autoResize,
                'cursor-default': readonly,
                'focus:bg-input-background-active dark:focus:bg-dark-700': !isDisabled && editable,
              },
            ]"
            :style="{
              maxHeight,
              minHeight,
              scrollbarGutter: 'stable',
            }"
            :spellcheck="spellcheck"
            :readonly="readonly"
            @input="onInput"
            @blur="emit('blur')"
            @paste="onPaste"
            @focus="emit('focus')"
            @keydown="emit('keydown', $event)"
            @keyup="emit('keyup', $event)"
          />
          <div v-if="!autoResize && !isDisabled && editable" class="absolute bottom-1 right-1 w-6 h-6 cursor-ns-resize touch-none">
            <div
              class="absolute bottom-[11px] right-[4px] rounded-lg w-5 h-[2px] rounded-full rotate-[135deg]"
              :class="errorMessage ? 'bg-danger-500' : 'bg-gray-300 dark:bg-dark-400'"
            />
            <div
              class="absolute bottom-[6px] right-[4px] rounded-lg w-3 h-[2px] rounded-full rotate-[135deg]"
              :class="errorMessage ? 'bg-danger-500' : 'bg-gray-300 dark:bg-dark-400'"
            />
          </div>
          <button
            v-if="value && !isDisabled && !autoResize && !readonly && !hideClear"
            type="button"
            class="absolute right-2 top-2 text-gray-800 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
            @click="clearValue"
          >
            <FontAwesomeIcon :icon="['fas', 'times']" class="w-4 h-4" />
          </button>
        </div>

        <div class="flex justify-between items-center text-sm">
          <div v-if="showErrorUnder" class="text-danger-500">
            {{ errorMessage }}
          </div>
          <div v-if="showCharacterCount" :class="[showErrorUnder ? 'ml-auto' : '']" class="text-gray-400 dark:text-gray-500">
            {{ value.length }}{{ maxLength ? ` / ${maxLength}` : '' }}
          </div>
        </div>
      </div>
    </ACTooltip>
  </div>
</template>

<style lang="scss" scoped>
textarea {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--input-border);
    border-radius: 3px;
    border: 1px solid var(--input-background);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  scrollbar-width: thin;
  scrollbar-color: var(--input-border) transparent;
}
</style>
