import type { UserStatusHydraItem } from "@verbleif/lib";
import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import type { TaskAssigneeEntityVote, TaskEntityVote, TaskUserEntityVote, TaskUserGroupEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const createContextAttributes = [
  PermissionAttributesEnum.ContextTaskCreateassigneddirectlytoscope,
  PermissionAttributesEnum.ContextTaskCreateassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskCreateforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskCreateassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskCreateunassigned,
];

const deleteContextAttributes = [
  PermissionAttributesEnum.ContextTaskDeleteassigneddirectlytoscope,
  PermissionAttributesEnum.ContextTaskDeleteassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskDeleteforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskDeleteassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskDeleteunassigned,
];

const updateContextAttributes = [
  PermissionAttributesEnum.ContextTaskUpdateassigneddirectlytoscope,
  PermissionAttributesEnum.ContextTaskUpdateassigneddirectlytoself,
  PermissionAttributesEnum.ContextTaskUpdateforassignedusergrouplessuser,
  PermissionAttributesEnum.ContextTaskUpdateassignedtoothersinscope,
  PermissionAttributesEnum.ContextTaskUpdateunassigned,
];

export class TaskVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskCreateany,
      contextAttribute: createContextAttributes,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskReadany,
      contextAttribute: [
        PermissionAttributesEnum.ContextTaskReadassigneddirectlytoscope,
        PermissionAttributesEnum.ContextTaskReadofassignedusergrouplessuser,
        PermissionAttributesEnum.ContextTaskReadassignedofothersinscope,
        PermissionAttributesEnum.ContextTaskReadunassigned,
      ],
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskDeleteany,
      contextAttribute: deleteContextAttributes,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationTaskUpdateany,
      contextAttribute: updateContextAttributes,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationTaskCreateany,
        PermissionAttributesEnum.GlobalOperationTaskUpdateany,
        PermissionAttributesEnum.GlobalOperationTaskDeleteany,
      ],
      contextAttribute: [
        ...createContextAttributes,
        ...updateContextAttributes,
        ...deleteContextAttributes,
      ],
    });
  };

  public canAssignAny(): boolean {
    return !!this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskassigneeCreateany,
    });
  }

  /**
   * Checks if the user has permission to assign a task to an assignee.
   *
   * This method verifies if the user has the global permission to create any task assignee.
   *
   * @param taskEntity - The task entity to which the task is to be assigned
   * @param assignee - The assignee entity to which the task is to be assigned
   * @returns {boolean} - Returns true if the user has the permission to assign a task to the assignee, false otherwise.
   */
  public canAssign(taskEntity: TaskEntityVote, assignee: TaskAssigneeEntityVote): boolean {
    if (this.canAssignAny()) {
      return true;
    }

    if (assignee["@type"] === "User") {
      return this.hasAccessToUser({
        taskEntity,
        assignee,
        attribute: PermissionAttributesEnum.ContextTaskassigneeAssigntoothersinscope,
        userGroupLessUserAttribute: PermissionAttributesEnum.ContextTaskassigneeAssignusergrouplessuser,
        propertyAttribute: PermissionAttributesEnum.ContextTaskassigneeAssigntoothersinscope,
      });
    }

    if (assignee["@type"] === "UserGroup") {
      return this.hasAccessToUserGroup({
        taskEntity,
        assignee,
        attribute: PermissionAttributesEnum.ContextTaskassigneeAssigntoothersinscope,
      });
    }

    return false;
  }

  private hasAccessToUser({
    taskEntity,
    assignee,
    attribute,
    userGroupLessUserAttribute,
    propertyAttribute,
  }: {
    taskEntity: TaskEntityVote
    assignee: TaskUserEntityVote
    attribute: PermissionAttributesEnum
    userGroupLessUserAttribute: PermissionAttributesEnum
    propertyAttribute: PermissionAttributesEnum
  }): boolean {
    const location = taskEntity.location;
    const client = location ? this.permissionService.getClientViaLocation(location) : null;
    if (client === null) {
      return false;
    }

    if (this.permissionService.hasAccessVia({ iri: client, attribute })) {
      return true;
    }

    if (this.permissionService.hasAccessVia({ iri: location, attribute })) {
      return true;
    }

    const isUserGroupLess = !assignee.statuses.some(
      (status: UserStatusHydraItem) =>
        status.location === location
        && status.scope === PermissionScope.USER_GROUP,
    );

    if (isUserGroupLess && this.permissionService.hasAccessVia({ iri: client, attribute: userGroupLessUserAttribute })) {
      return true;
    }

    if (isUserGroupLess && this.permissionService.hasAccessVia({ iri: location, attribute: userGroupLessUserAttribute })) {
      return true;
    }

    const explicitUserGroupsAllowed = this.permissionService.getAllIdentitiesViaScope({
      scope: PermissionScope.USER_GROUP,
      attribute,
      column: "userGroup",
    });

    const implicitUserGroupUserIds = this.permissionService.getUsersInUserGroups(explicitUserGroupsAllowed);
    if (implicitUserGroupUserIds.includes(assignee["@id"])) {
      return true;
    }

    const property = taskEntity.property;
    if (property) {
      const explicitPropertiesAllowed = this.permissionService.getAllIdentitiesViaScope({
        scope: PermissionScope.PROPERTY,
        attribute: propertyAttribute,
        column: "property",
      });

      if (!explicitPropertiesAllowed.includes(property)) {
        return false;
      }

      const implicitPropertyUserIds = this.permissionService.getUsersInProperties(explicitPropertiesAllowed);
      if (implicitPropertyUserIds.includes(assignee["@id"])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Checks if the current user has permission to assign a task to a user group
   * @param taskEntity The task entity to which the task is to be assigned
   * @param assignee The user group entity to which the task is to be assigned
   * @param attribute The permission attribute to check
   * @returns Returns true if the user has permission to assign a task to the user group
   */
  private hasAccessToUserGroup({
    taskEntity,
    assignee,
    attribute,
  }: {
    taskEntity: TaskEntityVote
    assignee: TaskUserGroupEntityVote
    attribute: PermissionAttributesEnum
  }): boolean {
    // Check if location exists
    const location = taskEntity.location;
    if (!location) {
      console.error("Location is required");
      return false;
    }

    // Check if we have permission to read the user group
    if (!this.permissionService.hasAccessVia({
      iri: assignee["@id"],
      attribute: PermissionAttributesEnum.ContextOperationUsergroupRead,
    })) {
      return false;
    }

    // Get client for the location
    const client = this.permissionService.getClientViaLocation(location);
    if (!client) {
      console.error("Client is required");
      return false;
    }

    // Check permissions via client
    if (this.permissionService.hasAccessVia({ iri: client, attribute })) {
      return true;
    }

    // Check permissions via location
    if (this.permissionService.hasAccessVia({ iri: location, attribute })) {
      return true;
    }

    // Check explicitly allowed user groups
    const explicitUserGroupsAllowed = this.permissionService.getAllIdentitiesViaScope({
      scope: PermissionScope.USER_GROUP,
      attribute,
      column: "userGroup",
    });

    // If the user group is in the explicitly allowed groups, return true
    if (explicitUserGroupsAllowed.includes(assignee["@id"])) {
      return true;
    }

    return false;
  }

  public canUnassignAny(): boolean {
    // return false;
    return !!this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.GlobalOperationTaskassigneeDeleteany,
    });
  }

  /**
   * Checks if the user has permission to unassign a task from an assignee.
   *
   * This method verifies if the user has the global permission to delete any task assignee.
   *
   * @param taskEntity - The task entity to which the task is to be unassigned
   * @param assignee - The assignee entity from which the task is to be unassigned
   * @returns {boolean} - Returns true if the user has the permission to unassign a task from the assignee, false otherwise.
   */
  public canUnassign(taskEntity: TaskEntityVote, assignee: TaskAssigneeEntityVote): boolean {
    // return false;
    if (this.canUnassignAny()) {
      return true;
    }

    if (assignee["@type"] === "User") {
      return this.hasAccessToUser({
        taskEntity,
        assignee,
        attribute: PermissionAttributesEnum.ContextTaskassigneeUnassignothersinscope,
        userGroupLessUserAttribute: PermissionAttributesEnum.ContextTaskassigneeUnassignusergrouplessusers,
        propertyAttribute: PermissionAttributesEnum.ContextTaskassigneeUnassignothersinscope,
      });
    }

    if (assignee["@type"] === "UserGroup") {
      return this.hasAccessToUserGroup({
        taskEntity,
        assignee,
        attribute: PermissionAttributesEnum.ContextTaskassigneeUnassignothersinscope,
      });
    }

    return false;
  }

  public hasAccessToAttributeViaTaskRelations(item: TaskEntityVote, attribute: PermissionAttributesEnum) {
    const location = item.location;
    const property = item.property;
    const client = location ? this.permissionService.getClientViaLocation(location) : null;
    const users = item.assignees.filter(assignee => assignee.includes("/users/"));
    const userGroups = item.assignees.filter(assignee => assignee.includes("/user_groups/"));

    if (client) {
      const userPermission = this.permissionService.getScopePermissionForUser({
        iri: client,
        attribute,
        scope: PermissionScope.CLIENT,
      });
      if (userPermission !== null) {
        return true;
      }
    }

    if (location) {
      const userLocationPermission = this.permissionService.getScopePermissionForUser({
        iri: location,
        attribute,
        scope: PermissionScope.LOCATION,
      });
      if (userLocationPermission !== null) {
        return true;
      }
    }

    if (property) {
      const userPropertyPermission = this.permissionService.getScopePermissionForUser({
        iri: property,
        attribute,
        scope: PermissionScope.PROPERTY,
      });
      if (userPropertyPermission !== null) {
        return true;
      }
    }

    if (userGroups.length > 0) {
      for (const userGroup of userGroups) {
        const userGroupPermission = this.permissionService.getScopePermissionForUser({
          iri: userGroup,
          attribute,
          scope: PermissionScope.USER_GROUP,
        });
        if (userGroupPermission !== null) {
          return true;
        }
      }
    }

    const usersData = this.permissionService.getUsersData();

    const userStatuses = users
      .map(user => usersData.get(user)?.statuses || null)
      .filter((status): status is UserStatusHydraItem[] => status !== null);

    console.log("[hasAccessToAttributeViaTaskRelations] userStatuses", userStatuses);

    // Return array of unique strings with all userGroupIris
    const userUserGroupStatuses = Array.from(new Set(
      userStatuses
        .flatMap(status => status.filter(s => !!s.userGroup).map(s => s.userGroup))
        .filter((userGroup): userGroup is string => userGroup !== undefined),
    ));

    console.log("[hasAccessToAttributeViaTaskRelations] userUserGroupStatuses", userUserGroupStatuses);

    if (userUserGroupStatuses.length > 0) {
      for (const userGroup of userUserGroupStatuses) {
        const userGroupPermission = this.permissionService.getScopePermissionForUser({
          iri: userGroup,
          attribute,
          scope: PermissionScope.USER_GROUP,
        });
        if (userGroupPermission !== null) {
          console.log("Returning true for user group", userGroup);
          return true;
        }
      }
    }

    return false;
  }
}
