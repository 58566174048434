import type { HydraCollection, TaskHydraItem, TimeEntryHydraItem } from "@verbleif/lib";
import type { TimeEntriestopSchema } from "./time_entry.stop.schema";
import { api, useAuthStore, useServerErrorHandler } from "@verbleif/shared";
import { createGlobalState, useIntervalFn, useStorage } from "@vueuse/core";
import moment from "moment";
import { useTimeEntryTransformer } from "./useTimeEntryTransformer";

export const useActiveTimeEntry = createGlobalState(() => {
  const activeTimeEntry = ref<TimeEntryHydraItem | null>(null);
  const loading = ref(false);
  const { onError } = useServerErrorHandler();
  const trackedTime = ref("0:00:00");
  const { user } = useAuthStore();
  const { outputPartialTransformer, outputTransformer } = useTimeEntryTransformer();
  const { t } = useI18n();
  const timeEntryStopDialogRef = ref<{
    show: (onHide?: () => void) => void
    hide: () => void
  } | null>(null);

  const billable = useStorage<boolean>("billable", false);

  async function getActiveTimeEntry() {
    if (!user.value) {
      return;
    }
    try {
      loading.value = true;
      const params = new URLSearchParams();
      params.set("exists[endedAt]", "false");
      params.set("order[id]", "desc");
      const { data } = await api.get<HydraCollection<TimeEntryHydraItem>>(`${user.value["@id"]}/time_entries`, {
        params,
      });
      activeTimeEntry.value = data.member?.[0] || null;
    } catch (error) {
      onError({ error, toastTitle: t("tasks.TimeEntry.pull_active_time_entry_title") });
    } finally {
      loading.value = false;
    }
  }
  const currentActiveTimeEntry = computed<TimeEntryHydraItem | null>(() => {
    return activeTimeEntry.value && !activeTimeEntry.value.endedAt ? activeTimeEntry.value : null;
  });

  function updateTrackedTime() {
    const startedAt = currentActiveTimeEntry.value?.startedAt;
    const endedAt = currentActiveTimeEntry.value?.endedAt;

    if (!startedAt) {
      trackedTime.value = "0:00:00";
      return;
    }

    const startTime = moment(startedAt).toDate();
    const currentDate = new Date();
    const differenceInSeconds = Math.floor((currentDate.getTime() - startTime.getTime()) / 1000) + moment(endedAt).diff(moment(endedAt), "seconds");

    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    trackedTime.value = [
      hours === 0 ? "0" : String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0"),
    ].join(":");
  };

  const { pause, resume } = useIntervalFn(() => {
    updateTrackedTime();
  }, 1000);

  // Timer state for UI tracking only
  const isPaused = ref(false);
  const pausedTimeEntry = ref<TimeEntryHydraItem | null>(null);
  const pausedAt = ref<Date | null>(null);

  function pauseActiveTimeEntry() {
    if (!currentActiveTimeEntry.value) {
      return;
    }

    // Store the current entry and when it was paused
    pausedTimeEntry.value = { ...currentActiveTimeEntry.value };
    pausedAt.value = new Date();
    isPaused.value = true;
    pause();

    // Don't call setActiveTimeEntryToEnded here - we'll handle this when saving
  }

  function resumeActiveTimeEntry() {
    if (!pausedTimeEntry.value) {
      return;
    }

    pausedAt.value = null;
    isPaused.value = false;
    resume();
  }

  function clearPausedState() {
    pausedTimeEntry.value = null;
    pausedAt.value = null;
    isPaused.value = false;
  }

  watch(() => currentActiveTimeEntry.value, (newVal) => {
    if (newVal) {
      updateTrackedTime();
      if (!isPaused.value) {
        resume();
      }
    } else {
      trackedTime.value = "0:00:00";
      pause();
      isPaused.value = false;
      pausedTimeEntry.value = null;
      pausedAt.value = null;
    }
  }, { deep: true, immediate: true });

  function isCurrentTaskTrackingTimeEntry(taskIri: string): null | TimeEntryHydraItem {
    return currentActiveTimeEntry.value?.task === taskIri && !currentActiveTimeEntry.value?.endedAt ? currentActiveTimeEntry.value : null;
  }

  async function setActiveTimeEntryToEnded(values: TimeEntriestopSchema) {
    if (!currentActiveTimeEntry.value) {
      return;
    }
    try {
      await api.patch(`${currentActiveTimeEntry.value["@id"]}`, outputPartialTransformer({
        endedAt: new Date(),
        ...values,
      }));
      getActiveTimeEntry();
    } catch (error) {
      onError({ error });
      throw error;
    }
  }

  async function startActiveTimeEntry({
    task,
  }: {
    task?: MaybeRefOrGetter<TaskHydraItem | null>
  }) {
    try {
      const taskValue = toValue(task);

      if (!taskValue) {
        return;
      }

      await api.post<TimeEntryHydraItem>("/api/task_time_entries", outputTransformer({
        task: taskValue["@id"],
        billable: billable.value,
        manual: false,
      }));
      getActiveTimeEntry();
    } catch (error) {
      onError({ error });
      throw error;
    }
  }

  function stopActiveTimeEntry() {
    if (!currentActiveTimeEntry.value) {
      return;
    }

    // If the timer is paused, clear the paused state
    clearPausedState();

    setActiveTimeEntryToEnded({});
  }

  function stopActiveTimeEntryInDialog(onHide?: () => void) {
    timeEntryStopDialogRef.value?.show(onHide);
  }

  function onTaskDeleted(taskIri: string) {
    if (currentActiveTimeEntry.value?.task === taskIri) {
      activeTimeEntry.value = null;
    }
  }

  return {
    activeTimeEntry: readonly(activeTimeEntry),
    stopActiveTimeEntryInDialog,
    stopActiveTimeEntry,
    pauseActiveTimeEntry,
    resumeActiveTimeEntry,
    clearPausedState,
    isPaused,
    pausedTimeEntry,
    pausedAt,
    onTaskDeleted,
    getActiveTimeEntry,
    timeEntryStopDialogRef,
    startActiveTimeEntry,
    setActiveTimeEntryToEnded,
    billable,
    currentActiveTimeEntry,
    isCurrentTaskTrackingTimeEntry,
    trackedTime,
  };
});
