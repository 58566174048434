<script setup lang="ts">
import type { HeadlessProgressToastProps } from "./SonnerCustom";
import { VButton } from "@verbleif/lib";
import { computed, ref, watch } from "vue";
import CustomProgressBar from "./SonnerProgressBar.vue";

const {
  title,
  message,
  value,
  isComplete = false,
  hideClose = false,
  violations,
  runningIcon = ["fad", "sensor-cloud"],
  runningIconClass = "text-gray-800 dark:text-white",
  completeIcon = ["fad", "check-circle"],
  completeIconClass = "text-green-500",
  errorIcon = ["fad", "exclamation-triangle"],
  errorIconClass = "text-red-500",
  secondaryTitle = "",
  messageRight = "",
  secondaryValue,
  secondaryMessage = "",
  secondaryMessageRight = "",
  showSecondaryProgressBar = false,
} = defineProps<HeadlessProgressToastProps>();

defineEmits(["closeToast"]);

const hasViolations = computed(() => violations !== undefined);
const messageKey = ref(0);
const violationsKey = ref(0);
const secondaryMessageKey = ref(0);

watch(() => message, () => {
  messageKey.value++;
});

watch(() => secondaryMessage, () => {
  secondaryMessageKey.value++;
});

watch(() => violations, () => {
  violationsKey.value++;
}, { deep: true });
</script>

<template>
  <div class="p-3 rounded-lg bg-white dark:bg-dark-850 border border-gray-300 dark:border-dark-700 relative w-[356px]">
    <div class="flex items-center justify-between mb-2">
      <div class="flex items-center gap-1">
        <div>
          <FontAwesomeIcon
            :icon="hasViolations ? errorIcon : (isComplete ? completeIcon : runningIcon)"
            :class="hasViolations ? errorIconClass : (isComplete ? completeIconClass : runningIconClass)"
            class="ml-px"
          />
        </div>
        <h3 class="text-base m-0 font-medium text-gray-800 dark:text-white">
          {{ title }}
        </h3>
      </div>
      <h3 v-if="secondaryTitle" class="text-base m-0 font-medium text-gray-800 dark:text-white">
        {{ secondaryTitle }}
      </h3>
    </div>

    <Transition name="fade" mode="out-in">
      <div v-if="!hasViolations" key="no-violations">
        <div>
          <div class="flex items-center justify-between mb-1">
            <p :key="messageKey" class="text-xs m-0" :class="isComplete ? 'text-green-500' : 'text-gray-700 dark:text-gray-300'">
              {{ message }}
            </p>
            <div v-if="messageRight" class="text-xs" :class="isComplete ? 'text-green-500' : 'text-gray-700 dark:text-gray-300'">
              {{ messageRight }}
            </div>
          </div>
          <CustomProgressBar
            v-if="value !== undefined"
            :mode="value === 0 ? 'indeterminate' : 'determinate'"
            :value="value"
            height="1rem"
            show-percentage
          />

          <div v-if="showSecondaryProgressBar" class="mt-1">
            <div class="flex items-center justify-between mt-2 mb-1">
              <p :key="secondaryMessageKey" class="text-xs m-0" :class="isComplete ? 'text-green-500' : 'text-gray-700 dark:text-gray-300'">
                {{ secondaryMessage }}
              </p>
              <div v-if="secondaryMessageRight" class="text-xs" :class="isComplete ? 'text-green-500' : 'text-gray-700 dark:text-gray-300'">
                {{ secondaryMessageRight }}
              </div>
            </div>
            <CustomProgressBar
              v-if="secondaryValue !== undefined"
              :mode="secondaryValue === 0 ? 'indeterminate' : 'determinate'"
              :value="secondaryValue"
              height="1rem"
              show-percentage
            />
          </div>

          <div class="flex gap-2 mt-2">
            <VButton v-for="(button, index) in buttons" :key="index" v-bind="button" @click="button.onClick">
              {{ button.label }}
            </VButton>
          </div>
        </div>
      </div>
      <div v-else key="violations">
        <div>
          <div class="text-sm text-red-500 mt-2">
            {{ message }}
          </div>
          <template v-if="!hideViolations">
            <ul :key="violationsKey" class="text-xs text-gray-600 dark:text-gray-400 mt-1">
              <li v-for="(messages, key) in violations" :key="key">
                {{ key }}: {{ messages.join(', ') }}
              </li>
            </ul>
          </template>
        </div>
      </div>
    </Transition>

    <button
      v-if="!hideClose"
      class="absolute cursor-pointer top-1.5 h-6 w-6 flex justify-center items-center right-1.5 text-gray-400 dark:text-gray-600 hover:text-gray-600 dark:hover:text-gray-400 p-0 bg-transparent border-none transition-colors duration-200"
      @click="$emit('closeToast')"
    >
      <svg width="14" height="14" viewBox="0 0 16 16" fill="currentColor">
        <path
          d="M2.96967 2.96967C3.26256 2.67678 3.73744 2.67678 4.03033 2.96967L8 6.939L11.9697 2.96967C12.2626 2.67678 12.7374 2.67678 13.0303 2.96967C13.3232 3.26256 13.3232 3.73744 13.0303 4.03033L9.061 8L13.0303 11.9697C13.2966 12.2359 13.3208 12.6526 13.1029 12.9462L13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L8 9.061L4.03033 13.0303C3.73744 13.3232 3.26256 13.3232 2.96967 13.0303C2.67678 12.7374 2.67678 12.2626 2.96967 11.9697L6.939 8L2.96967 4.03033C2.7034 3.76406 2.6792 3.3474 2.89705 3.05379L2.96967 2.96967Z"
        />
      </svg>
    </button>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
