<script setup lang="ts">
import { computed, ref, watch } from "vue";

interface Props {
  totalPages: number
  page: number
  previousText?: string
  nextText?: string
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  totalPages: 1,
  page: 1,
  previousText: "Previous",
  nextText: "Next",
  loading: false,
});

const emit = defineEmits<{
  (e: "update:page", value: number): void
}>();

const currentPage = ref(props.page);

watch(() => props.page, () => {
  currentPage.value = props.page;
});

function setPage(number: number) {
  if (props.loading) {
    return;
  }
  emit("update:page", number);
}

const showAllPages = computed(() => props.totalPages <= 6);
const showFirstPages = computed(() => props.totalPages > 6 && currentPage.value <= 3);
const showLastPages = computed(() => props.totalPages > 6 && currentPage.value > props.totalPages - 3);
const showMiddlePages
= computed(() => props.totalPages > 6
  && currentPage.value <= props.totalPages - 3
  && currentPage.value > 3);
</script>

<template>
  <div class="flex items-center h-full mr-2.5 gap-2">
    <VButton
      :loading="loading"
      rounded
      variant="is-light"
      @click="
        currentPage === 1 ? setPage(totalPages) : setPage(currentPage - 1)
      "
    >
      {{ previousText }}
    </VButton>

    <template v-if="showAllPages">
      <div
        v-for="number in totalPages"
        :key="number"
        class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
        :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === number }"
        @click="setPage(number)"
      >
        {{ number }}
      </div>
    </template>

    <template v-if="showFirstPages">
      <div class="flex flex-wrap items-center">
        <div
          v-for="number in [1, 2, 3, 4, 5]"
          :key="number"
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === number }"
          @click="setPage(number)"
        >
          {{ number }}
        </div>
        <div class="flex items-center justify-center min-w-[38px] min-h-[38px] select-none text-sm text-gray-800 dark:text-gray-200">
          ...
        </div>
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === totalPages }"
          @click="setPage(totalPages)"
        >
          {{ totalPages }}
        </div>
      </div>
    </template>

    <template v-if="showLastPages">
      <div class="flex flex-wrap items-center">
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === 1 }"
          @click="setPage(1)"
        >
          {{ 1 }}
        </div>
        <div class="flex items-center justify-center min-w-[38px] min-h-[38px] select-none text-sm text-gray-800 dark:text-gray-200">
          ...
        </div>
        <div
          v-for="number in [4, 3, 2, 1, 0]"
          :key="totalPages - number"
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === totalPages - number }"
          @click="setPage(totalPages - number)"
        >
          {{ totalPages - number }}
        </div>
      </div>
    </template>

    <template v-if="showMiddlePages">
      <div class="flex flex-wrap items-center">
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === 1 }"
          @click="setPage(1)"
        >
          {{ 1 }}
        </div>
        <div class="flex items-center justify-center min-w-[38px] min-h-[38px] select-none text-sm text-gray-800 dark:text-gray-200">
          ...
        </div>
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === currentPage - 1 }"
          @click="setPage(currentPage - 1)"
        >
          {{ currentPage - 1 }}
        </div>
        <div class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full bg-primary-500 text-white dark:text-white select-none text-sm">
          {{ currentPage }}
        </div>
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === currentPage + 1 }"
          @click="setPage(currentPage + 1)"
        >
          {{ currentPage + 1 }}
        </div>
        <div class="flex items-center justify-center min-w-[38px] min-h-[38px] select-none text-sm text-gray-800 dark:text-gray-200">
          ...
        </div>
        <div
          class="flex items-center justify-center min-w-[38px] min-h-[38px] rounded-full cursor-pointer select-none text-sm text-gray-800 dark:text-gray-200 hover:bg-primary-500 hover:text-white dark:hover:text-white"
          :class="{ 'bg-primary-500 text-white dark:text-white': currentPage === totalPages }"
          @click="setPage(totalPages)"
        >
          {{ totalPages }}
        </div>
      </div>
    </template>

    <VButton
      :loading="loading"
      rounded
      variant="is-light"
      @click="
        currentPage === totalPages ? setPage(1) : setPage(currentPage + 1)
      "
    >
      {{ nextText }}
    </VButton>
  </div>
</template>

<style lang="scss" scoped>
.pages {
  display: flex;
  height: 100%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  .button-container {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  .page {
    color: var(--text);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 38px;
    min-height: 38px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    &:hover:not(.unselectable) {
      background-color: var(--primary);
      color: var(--text-invert);
    }
    &.is-active:not(.unselectable) {
      color: var(--text-invert);
      background-color: var(--primary);
    }
  }
}
</style>
