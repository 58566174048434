<script setup lang="ts">
import { TransitionEvent } from "@verbleif/lib";

const { dialog } = defineProps<{
  dialog?: boolean
}>();
const { reservationState, loading } = useReservationState();
</script>

<template>
  <div :class="dialog ? 'max-w-full xl:max-w-[300px]' : 'max-w-full xl:max-w-[400px]' " class="text-sm text-gray-800 dark:text-gray-200 flex flex-col gap-2">
    <span class="font-bold w-full">
      {{ $t('tasks.reservation_state.occupation.status.title') }}
    </span>

    <template v-if="loading">
      <div v-for="n in 3" :key="n" class="flex items-center gap-3">
        <span class="flex-1">
          <VSkeleton :width="60" width-unit="%" :height="14" />
        </span>
        <span class="w-16 text-left">
          <VSkeleton :width="24" width-unit="px" :height="14" />
        </span>
      </div>
    </template>
    <template v-else>
      <div class="flex items-center gap-3">
        <div class="w-3">
          <FontAwesomeIcon
            :class="{
              '!text-red-600 dark:!text-red-400': reservationState?.datePointState.transitionEvent !== TransitionEvent.NONE,
              'text-blue-600 dark:text-blue-400': reservationState?.current?.checkedInAt,
              '!text-primary-600 dark:!text-primary-400': !reservationState?.current?.checkedInAt,
            }"
            :icon="['fad', 'people-group']"
          />
        </div>
        <span class="flex-1">
          {{ $t('tasks.section.object_status.guest_present') }}:
        </span>
        <span class="w-16 text-left">
          {{ reservationState?.current?.checkedInAt ? $t('base.yes') : $t('base.no') }}
        </span>
      </div>

      <div class="flex items-center gap-3">
        <div class="w-3">
          <FontAwesomeIcon
            :class="{
              'text-red-600 dark:text-red-400': reservationState?.blocked,
              'text-primary-600 dark:text-primary-400': !reservationState?.blocked,
            }"
            :icon="reservationState?.blocked ? ['fad', 'lock'] : ['fad', 'lock-open']"
          />
        </div>
        <span class="flex-1">
          {{ $t('tasks.section.object_status.blocked') }}:
        </span>
        <span class="w-16 text-left">
          {{ reservationState?.blocked ? $t('base.yes') : $t('base.no') }}
        </span>
      </div>

      <div v-if="reservationState?.accessCode" class="flex items-center gap-3">
        <div class="w-3">
          <FontAwesomeIcon :icon="['fad', 'key']" />
        </div>
        <span class="flex-1">
          {{ $t('tasks.section.object_status.access_code') }}:
        </span>
        <span class="w-16 text-left">
          {{ reservationState?.accessCode }}
        </span>
      </div>
    </template>
  </div>
</template>
