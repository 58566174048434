<script setup lang="ts">
import type { AccordionSize, AccordionVariant } from "./types";
import { toRefs } from "vue";
import { useProvideAccordionState } from "./useAccordionState";

interface Props {
  multiple?: boolean
  size?: AccordionSize
  variant?: AccordionVariant
}

const props = withDefaults(defineProps<Props>(), {
  multiple: false,
  size: "md",
  variant: "default",
});

const modelValue = defineModel<string | number | (string | number)[]>({
  required: true,
  default: () => 0,
});

const { multiple, size, variant } = toRefs(props);

useProvideAccordionState({
  modelValue,
  multiple,
  size,
  variant,
});
</script>

<template>
  <div class="w-full">
    <slot />
  </div>
</template>
