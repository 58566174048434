import { computed } from "vue";

export const truncateText = computed(() => {
  return (text?: string, truncateAt: number = 60) => {
    if (text && text.length > truncateAt) {
      return `${text.slice(0, truncateAt)}...`;
    }
    return text;
  };
});
