export const languageNames = {
  nl: "Nederlands",
  en: "English",
  de: "Deutsch",
  pl: "Polski",
  fr: "Français",
  es: "Español",
  tr: "Türkçe",
  ro: "Română",
  bg: "Български",
  cs: "Čeština",
  uk: "Українська",
  hu: "Magyar",
  sk: "Slovenčina",
};

export const countryNames = {
  nl: "Nederland",
  en: "United Kingdom",
  de: "Deutschland",
  pl: "Polska",
  fr: "France",
  es: "España",
  tr: "Türkiye",
  ro: "România",
  bg: "България",
  cs: "Česká republika",
  uk: "Україна",
  hu: "Magyarország",
  sk: "Slovensko",
};
