export enum TransitionEvent {
  NONE = 1,
  ARRIVAL = 2,
  DEPARTURE = 3,
  SWITCH = 4,
}

export enum OccupationState {
  OCCUPIED = 1,
  VACANT = 2,
}
