import { InterceptorType } from "@axios-extensions/plugins-api";
import { installInterceptor } from "@verbleif/shared";
import { useImpersonationStore } from "@web/features/Auth/Impersonate/useImpersonationStore";

const EXCLUDED_ROUTES = ["/oauth/v2/token"];

const impersonationInterceptorPlugin = {
  name: "ImpersonationInterceptor",
  type: InterceptorType.REQUEST,
  priority: 10,
  resolved: async (config: any) => {
    if (!config.url) {
      return config;
    }

    // Skip impersonation for excluded routes
    if (EXCLUDED_ROUTES.some(route => config.url.includes(route))) {
      return config;
    }

    const impersonationStore = useImpersonationStore();

    if (!impersonationStore.isImpersonating.value) {
      return config;
    }

    Object.assign(config.headers, impersonationStore.getRequestHeaders.value);

    return config;
  },
};

installInterceptor(impersonationInterceptorPlugin);
