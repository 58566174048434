<script setup lang="ts">
import { computed, defineProps } from "vue";
import ACTooltip from "../ACTooltip/ACTooltip.vue";

const {
  optionLabel = "name",
  optionValue = false,
  label = "",
  disabled = false,
  layout = "is-column",
  labelPosition = "left",
  options,
  hasPerms = true,
  preventUnselect = false,
  multiple = false,
  tooltipValue = "tooltip",
  disabledValue = "disabled",
} = defineProps<{
  options: Option
  multiple?: boolean
  invalid?: boolean
  required?: boolean
  optionLabel?: string
  label?: string
  labelPosition?: "left" | "top"
  optionValue?: string | false
  disabled?: boolean
  layout?: string
  preventUnselect?: boolean
  hasPerms?: boolean
  tooltipValue?: string
  disabledValue?: string
}>();

interface Option {
  [key: string]: any
}

const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

const modelValue = defineModel<any>();

function getOptionValue(option: Option) {
  if (typeof optionValue === "string") {
    return option[optionValue];
  }

  return option[optionLabel];
};

function updateSelection(option: Option) {
  const value = getOptionValue(option);
  console.log(value, option, options);

  if (value === undefined) {
    return;
  }

  if (multiple) {
    const newValue = Array.isArray(modelValue.value) ? [...modelValue.value] : [];
    const index = newValue.indexOf(value);

    if (index >= 0) {
      if (!preventUnselect) {
        newValue.splice(index, 1);
      }
    } else {
      newValue.push(value);
    }

    modelValue.value = newValue;
  } else {
    if (modelValue.value !== value || !preventUnselect) {
      modelValue.value = modelValue.value === value && !preventUnselect ? null : value;
    }
  }
};

function isSelected(option: Option) {
  const value = getOptionValue(option);

  return multiple
    ? Array.isArray(modelValue.value) && modelValue.value.includes(value)
    : modelValue.value === value;
};

const buttonClasses = computed(() => {
  return (option: Option) => ({
    "bg-white dark:bg-dark-800 text-theme-light-gray-2 dark:text-dark-100": isSelected(option),
    "bg-gray-50 dark:bg-dark-700 text-gray-500 dark:text-dark-400": !isSelected(option),
    "cursor-not-allowed opacity-50": isDisabled.value || option[disabledValue],
  });
});
</script>

<template>
  <div :class="{ 'inline-block': labelPosition === 'left', 'flex flex-col': labelPosition === 'top' }">
    <label
      v-if="label"
      class="text-theme-light-gray-2 dark:text-white gap-1 text-sm mb-1"
      :class="{
        'inline-flex w-1/3': labelPosition === 'left' && layout === 'is-row',
        'inline-flex': labelPosition === 'left' && layout !== 'is-row',
        'block': labelPosition === 'top',
      }"
    >
      {{ label }}
      <span v-if="required" class="text-red-500 font-bold">*</span>
    </label>
    <ACTooltip
      type="edit_field"
      :allow-change="hasPerms"
    >
      <div class="inline-flex bg-gray-50 dark:bg-dark-700 rounded-lg border border-solid" :class="invalid ? 'border-danger-600' : 'border-gray-50 dark:border-dark-700'">
        <VTooltip
          v-for="option in options"
          :key="option[optionLabel]"
          :content="option[tooltipValue]"
          :disabled="!option[tooltipValue]"
          placement="top"
        >
          <template #reference>
            <button
              class="py-1 px-3 m-1 rounded-lg transition-colors duration-200 text-sm"
              :class="buttonClasses(option)"
              :disabled="isDisabled || option.disabled"
              @click.prevent="updateSelection(option)"
            >
              <slot v-if="$slots.value" name="value" :label="option[optionLabel]" :option="option" />
              <span v-else>{{ option[optionLabel] }}</span>
            </button>
          </template>
        </VTooltip>
      </div>
    </ACTooltip>
  </div>
</template>

<style lang="scss" scoped>
</style>
