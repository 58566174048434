import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class ReservationVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReservationCreateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReservationCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReservationReadany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReservationRead,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReservationDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReservationDelete,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationReservationUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextOperationReservationUpdate,
      scope: PermissionScope.PROPERTY,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationReservationCreateany,
        PermissionAttributesEnum.GlobalOperationReservationUpdateany,
        PermissionAttributesEnum.GlobalOperationReservationDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextOperationReservationCreate,
        PermissionAttributesEnum.ContextOperationReservationUpdate,
        PermissionAttributesEnum.ContextOperationReservationDelete,
      ],
      scope: PermissionScope.PROPERTY,
    });
  };
}
