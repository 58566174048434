<script setup lang="ts">
import type { TableColumn, TableField } from "../../composables/Paginate/Paginate";
import { computed, nextTick, onMounted, onUnmounted, ref, useSlots, useTemplateRef, watch } from "vue";
import Empty from "../../assets/EmptyTable.svg?component";
import { usePaginate } from "../../composables";
import IconTitleText from "../IconTitleText/IconTitleText.vue";
import VTableHeadSortable from "./VTableHeadSortable.vue";

interface Props {
  sortableHeading?: boolean
  onRowClick?: (item: any) => void
  emptyTitle?: string
  borderless?: boolean
  rightFieldLabel?: string | null
  rightField?: string | null
  getRowClass?: (item: any) => string | Record<string, boolean> | null
  getRowTooltip?: (item: any) => string | null
}

withDefaults(defineProps<Props>(), {
  sortableHeading: false,
  emptyTitle: "No data available.",
  onRowClick: () => {},
  borderless: false,
  rightFieldLabel: null,
  rightField: null,
  getRowClass: () => null,
  getRowTooltip: () => null,
});

const {
  tableFields,
  loading,
  items,
  hasError,
  errorMsg,
  errorCode,
} = usePaginate();

const tableRef = ref<HTMLTableElement | null>(null);
const contentRef = useTemplateRef("content-ref");
const slots = useSlots();
const resizeObserver = ref<ResizeObserver | null>(null);

const hasRightSlot = computed(() => !!slots.right);
const hasRightButtonSlot = computed(() => !!slots.rightButton);

// Expose the contentRef to parent components
defineExpose({
  contentRef,
});

function isTableColumn(field: TableField): field is TableColumn {
  return typeof field === "object" && field !== null;
}

function updateTableLayout() {
  if (!tableRef.value || !tableFields) {
    return;
  }

  const headerCells = tableRef.value.querySelectorAll<HTMLTableCellElement>("th:not(.is-right)");
  const availableWidth = tableRef.value.clientWidth - (hasRightSlot.value ? 100 : 0);
  let totalFixedWidth = 0;
  let autoColumns = 0;

  // First pass: calculate fixed widths and count auto columns
  (Object.values(tableFields) as TableField[]).forEach((field) => {
    if (isTableColumn(field)) {
      if (!field.width || field.width === "auto") {
        autoColumns++;
      } else if (typeof field.width === "number") {
        totalFixedWidth += field.width;
      } else if (typeof field.width === "string") {
        if (field.width.endsWith("px")) {
          totalFixedWidth += Number.parseInt(field.width);
        } else if (field.width.endsWith("%")) {
          totalFixedWidth += (Number.parseInt(field.width) * availableWidth) / 100;
        } else {
          autoColumns++;
        }
      }
    } else {
      autoColumns++;
    }
  });

  // Calculate width for auto columns
  const autoWidth = autoColumns > 0 ? Math.max((availableWidth - totalFixedWidth) / autoColumns, 100) : 0;

  // Second pass: apply widths and alignment
  headerCells.forEach((cell, index) => {
    const field = (Object.values(tableFields) as TableField[])[index];

    if (isTableColumn(field)) {
      // Apply width
      if (!field.width || field.width === "auto") {
        cell.style.width = `${autoWidth}px`;
      } else {
        cell.style.width = typeof field.width === "number"
          ? `${field.width}px`
          : field.width;
      }

      // Apply alignment
      if (field.align) {
        cell.classList.add(`is-${field.align}`);
      }
    } else {
      cell.style.width = `${autoWidth}px`;
    }
  });
}

// Add ResizeObserver to handle table container size changes
onMounted(() => {
  if (tableRef.value) {
    resizeObserver.value = new ResizeObserver(() => {
      nextTick(updateTableLayout);
    });
    resizeObserver.value.observe(tableRef.value as unknown as Element);
  }
});

onUnmounted(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
});

watch(() => tableFields, () => {
  nextTick(updateTableLayout);
}, { immediate: true });
</script>

<template>
  <div class="v-table-container border-solid border-gray-300 dark:border-dark-600" :class="borderless ? '' : 'border mt-[16] mb-0 rounded-[9px] bg-white dark:bg-dark-800'">
    <div ref="content-ref" class="v-table-content">
      <table ref="tableRef" class="v-table">
        <thead>
          <tr class="v-table-header">
            <th
              v-for="(field, fieldName) in tableFields"
              :key="fieldName"
              class="v-table-cell v-table-header-cell bg-white"
              :class="[borderless ? 'dark:bg-dark-850' : 'dark:bg-dark-800', { 'is-right': isTableColumn(field as TableField) && (field as TableColumn).align === 'right' }]"
            >
              <VTableHeadSortable
                :item="{
                  field: fieldName,
                  translation: isTableColumn(field as TableField)
                    ? (field as TableColumn).label
                    : field as string,
                }"
                :sortable-heading="sortableHeading"
              />
            </th>
            <th
              v-if="hasRightSlot"
              :class="[borderless ? 'dark:bg-dark-850' : 'dark:bg-dark-800']"
              class="v-table-cell v-table-header-cell bg-white is-right"
            >
              <VTableHeadSortable
                v-if="rightField && rightFieldLabel"
                :item="{
                  field: rightField,
                  translation: rightFieldLabel,
                }"
                right
                :sortable-heading="sortableHeading"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="hasError">
            <tr class="v-table-row v-table-error">
              <td :colspan="Object.keys(tableFields ?? {}).length + (hasRightSlot ? 1 : 0)">
                <slot :error-code="errorCode" :error-msg="errorMsg" name="error">
                  <IconTitleText :error-code="errorCode" :error-msg="errorMsg">
                    <template #icon>
                      <slot :error-code="errorCode" :error-msg="errorMsg" name="errorIcon" />
                    </template>
                    <template #title>
                      <slot :error-code="errorCode" :error-msg="errorMsg" name="errorTitle" />
                    </template>
                    <template #text>
                      <slot :error-code="errorCode" :error-msg="errorMsg" name="errorText" />
                    </template>
                  </IconTitleText>
                </slot>
              </td>
            </tr>
          </template>
          <template v-else-if="!loading && items.length === 0">
            <tr class="v-table-row v-table-empty">
              <td
                :colspan="Object.keys(
                  tableFields ?? {}).length + (hasRightSlot ? 1 : 0) + (hasRightButtonSlot ? 1 : 0)"
              >
                <slot name="empty">
                  <IconTitleText>
                    <template #icon>
                      <slot name="emptyIcon">
                        <Empty style="width: 200px" />
                      </slot>
                    </template>
                    <template #title>
                      <slot name="emptyTitle">
                        {{ emptyTitle }}
                      </slot>
                    </template>
                    <template #text>
                      <slot name="emptyText" />
                    </template>
                  </IconTitleText>
                </slot>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(item, index) in items"
              :key="index"
              v-tooltip="getRowTooltip?.(item)"
              class="v-table-row"
              :class="[
                { 'is-clickable': !loading && onRowClick !== null },
                getRowClass?.(item),
              ]"
              @click="() => !loading && onRowClick && onRowClick(item)"
            >
              <td
                v-for="(_, field) in tableFields"
                :key="field"
                class="v-table-cell"
                :class="field"
              >
                <slot :field="field" :loading="loading" :name="field" :item="item as any">
                  <VSkeleton v-if="loading" :height="16" :width="50" />
                  <template v-else>
                    {{ item[field as keyof typeof item] }}
                  </template>
                </slot>
              </td>
              <td v-if="hasRightSlot" class="v-table-cell is-right">
                <slot :loading="loading" name="right" :item="item as any" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-table-container {
  color: var(--text);
  overflow: auto;
  flex-grow: 1;
  height: 100%; /* Ensure the container takes up the full height */
}

.v-table-content {
  width: 100%;
  padding: 0 15px;
  height: 100%; /* Ensure the content takes up the full height */
  overflow: auto; /* Added overflow auto here */
}

.v-table {
  width: 100%;
  border-collapse: collapse;
}

.v-table-cell {
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 48px;
  vertical-align: middle;

  &.is-right {
    text-align: right;

    :deep(.table-heading) {
      justify-content: flex-end;
    }
  }

  &.is-left {
    text-align: left;

    :deep(.table-heading) {
      justify-content: flex-start;
    }
  }

  &.is-center {
    text-align: center;

    :deep(.table-heading) {
      justify-content: center;
    }
  }
}

.v-table-header-cell {
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: none;
  height: 48px;
  padding: 0 10px;
  vertical-align: middle;
  box-shadow: inset 0 -1px 0 var(--input-border); /* Add bottom border with box-shadow */

  &.is-right {
    text-align: right;
  }

  &.is-left {
    text-align: left;
  }

  &.is-center {
    text-align: center;
  }

  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }
}

.v-table-row {
  transition: background-color 0.3s ease, border-color 0.3s ease, border-radius 0.3s ease;
  &:not(:first-child) {
    border-top: 1px solid var(--input-border);
  }

  &.is-clickable {
    cursor: pointer;

    // &:hover {
    //   background-color: var(--main-hover);
    // }
  }
}

@media (max-width: 768px) {
  .v-table-container {
    height: auto;
    max-height: 84vh;
  }

  .v-table-content {
    padding: 0;
  }

  .v-table-cell {
    padding: 10px;
  }
}
</style>
