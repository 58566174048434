<script setup lang="ts">
import { useMagicKeys, whenever } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import CloseIcon from "../../assets/CloseIcon.svg?component";
import { configOverrideDialogVisible } from "./configOverrideDialogRef";
import DebugSonner from "./DebugSonner.vue";
import DebugToolsGeneral from "./DebugToolsGeneral.vue";
import DebugToolsUrlDialog from "./DebugToolsUrlDialog.vue";
import DebugToolsUser from "./DebugToolsUser.vue";

const emit = defineEmits<{
  (e: "close"): void
}>();
const activeIndex = ref(0);
const enablePropertyStateDebug = defineModel<boolean>("enablePropertyStateDebug");

const { Ctrl_Shift_D } = useMagicKeys();
whenever(Ctrl_Shift_D, () => {
  configOverrideDialogVisible.value = true;
});

const { t } = useI18n();
const configOverrideDialog = ref(false);

function closeDialog() {
  emit("close");
}
</script>

<template>
  <VDialog
    id="config-override-dialog"
    v-model:visible="configOverrideDialogVisible"
    mask
    size="xlarge"
    headless
    @close="closeDialog"
  >
    <template #content="{ close }">
      <div class="flex flex-col">
        <div class="flex justify-between items-center m-5">
          <span class="text-xl leading-6 font-bold dark:text-white">Debug Tools</span>
          <span
            class="cursor-pointer rounded-full flex items-center justify-center close-icon"
            role="button"
            aria-label="Close"
            @click="close"
            @mousedown.stop
          >
            <CloseIcon class="w-4 h-4" />
          </span>
        </div>
        <VTabs v-model:active-index="activeIndex" tile orientation="vertical">
          <VTabList>
            <VTabItem title="Algemeen" :icon="['fad', 'memo-pad']" :index="0" />
            <VTabItem title="Sonner" :icon="['fad', 'bell']" :index="1" />
            <VTabItem title="User" :icon="['fad', 'user']" :index="2" />
          </VTabList>
          <VTabPanels>
            <VTabPanel :index="0">
              <div class="pl-3">
                <DebugToolsGeneral
                  v-model="configOverrideDialog"
                  v-model:enable-property-state-debug="enablePropertyStateDebug"
                />
              </div>
            </VTabPanel>
            <VTabPanel :index="1">
              <div class="pl-3">
                <DebugSonner />
              </div>
            </VTabPanel>
            <VTabPanel :index="2">
              <div class="pl-3">
                <DebugToolsUser />
              </div>
            </VTabPanel>
          </VTabPanels>
        </VTabs>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full p-5">
        <VDivider class="w-full !mb-6" />
        <div class="flex flex-col sm:flex-row justify-between gap-4">
          <VButton rounded class="is-gray" @click="close">
            {{ t("base.cancel") }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>

  <DebugToolsUrlDialog :visible="configOverrideDialog" @close="configOverrideDialog = false" />
</template>

<style scoped></style>
