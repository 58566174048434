import type { VButtonProps } from "@verbleif/lib/src/components/Button";
import type { ExternalToast } from "vue-sonner";
import { markRaw } from "vue";
import { toast as sonner } from "vue-sonner";
import SonnerCustom from "./SonnerCustom.vue";

export interface HeadlessProgressToastViolations {
  [key: string]: string[]
}

export interface HeadlessProgressToastProps {
  title: string
  message: string
  value?: number
  isComplete?: boolean
  violations?: HeadlessProgressToastViolations
  hideViolations?: boolean
  runningIcon?: string[]
  runningIconClass?: string
  completeIcon?: string[]
  completeIconClass?: string
  errorIcon?: string[]
  errorIconClass?: string
  hideProgressBar?: boolean
  hideClose?: boolean
  secondaryTitle?: string
  messageRight?: string
  secondaryValue?: number
  secondaryMessage?: string
  secondaryMessageRight?: string
  showSecondaryProgressBar?: boolean
  buttons?: (VButtonProps & {
    onClick: () => void
    label: string
  })[]
}

export function customToast(toast: ExternalToast & { componentProps: HeadlessProgressToastProps }) {
  return sonner.custom(markRaw(SonnerCustom), {
    ...toast,
  });
}
