<script setup lang="ts">
import { useReservationState } from "./useReservationState";

const props = defineProps<{
  isoDate: string
  icon?: string[]
  iconColor?: string
  lateCheckout?: boolean
}>();

const { formatDate } = useReservationState();
const { date, time, isToday } = formatDate(props.isoDate);

// Default values
const defaultIcon = ["fad", "calendar-day"];
const defaultIconColor = "text-gray-600 dark:text-gray-300";

// Capitalize the first letter
const capitalizedDate = date && date.length > 0
  ? date.charAt(0).toUpperCase() + date.slice(1)
  : date;

// Clock color based on late checkout
const clockIconColor = props.lateCheckout
  ? "text-red-500 dark:text-red-400"
  : "text-gray-500 dark:text-dark-400";
</script>

<template>
  <div class="flex items-center gap-3">
    <div class="w-5">
      <FontAwesomeIcon
        :icon="icon || defaultIcon"
        :class="iconColor || defaultIconColor"
      />
    </div>
    <span class="flex-1" :class="{ 'text-blue-500': isToday }">
      <template v-if="isToday">{{ $t('tasks.reservation_state.date_point.subtitle.today') }}</template>
      <template v-else>{{ capitalizedDate }}</template>
    </span>
    <div class="w-16 text-left flex items-center gap-2">
      <FontAwesomeIcon :class="clockIconColor" :icon="['fad', 'clock']" />
      <span :class="{ 'text-blue-500': isToday }">{{ time }}</span>
    </div>
  </div>
</template>
