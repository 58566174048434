<script setup lang="ts">
import { Avatar, useAuthStore } from "@verbleif/shared";
import { useLogout } from "@web/features/Auth/Logout/useLogout";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import NavbarLocaleChange from "./NavbarLocaleChange/NavbarLocaleChange.vue";
import NavbarNotifications from "./NavbarNotifications/NavbarNotifications.vue";
import { useProvideNavbarNotifications } from "./NavbarNotifications/useNavbarNotifications";
import NavbarSettingsItem from "./NavbarSettingsItem.vue";

const authStore = useAuthStore();
const route = useRoute();
const { logout } = useLogout();
const { t } = useI18n();
const TRANSLATION_KEY = "navbar.dropdown";

const {
  unreadCount,
} = useProvideNavbarNotifications();

const notificationCount = computed(() => unreadCount.value);
</script>

<template>
  <VDropdown
    theme="verbleif-popper-bounded"
    placement="right"
    auto-size="min"
  >
    <template #popper>
      <div class="flex flex-col">
        <div class="flex items-center mx-4 mt-4 mb-3">
          <Avatar :name="authStore.user.value?.name" :avatar-url="authStore.user.value?.avatar?.contentUrl" class="mr-2" size="xs" />
          <div class="text-xs">
            {{ authStore.user.value?.name }}
          </div>
        </div>
        <div class="flex flex-col">
          <RouterLink v-close-popper.all to="/settings/my-account" class="">
            <NavbarSettingsItem :icon="['fad', 'user']" :text="t(`${TRANSLATION_KEY}.my_account`)" />
          </RouterLink>
          <NavbarLocaleChange />
          <NavbarNotifications />
          <RouterLink v-close-popper.all :to="`/spaces?redirect=${route.path}`">
            <NavbarSettingsItem :icon="['fad', 'location-dot']" :text="t(`${TRANSLATION_KEY}.location_switch`)" />
          </RouterLink>
          <div v-close-popper.all @click="logout()">
            <NavbarSettingsItem variant="danger" :icon="['fad', 'right-from-bracket']" :text="t(`${TRANSLATION_KEY}.logout`)" />
          </div>
        </div>
      </div>
      <div class="flex w-full bg-gray-50 dark:bg-dark-800 text-gray-500 py-2 mt-4 px-4 text-xs">
        <div class="mr-10 font-bold uppercase">
          {{ t("navbar.download_apps") }}:
        </div>
        <div class="flex gap-3">
          <a class="cursor-pointer  hover:text-primary-500 active:text-primary-700" href="https://verbleif.com/android" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon :icon="['fab', 'android']" />
          </a>
          <a class="cursor-pointer hover:text-primary-500 active:text-primary-700" href="https://verbleif.com/ios" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon :icon="['fab', 'apple']" />
          </a>
        </div>
      </div>
    </template>
    <div>
      <Avatar
        size="md"
        main
        :name="authStore.user.value?.name"
        :avatar-url="authStore.user.value?.avatar?.contentUrl"
        :notifications="notificationCount"
        class="cursor-pointer"
      />
    </div>
  </VDropDown>
</template>
