<script setup lang="ts">
import { defineModel, provide, watch } from "vue";

// Define the v-model with a default value of 0
const { totalSteps } = defineProps<{
  totalSteps: number
}>();

const emit = defineEmits<{
  (e: "onChange", currentStep: number): void
}>();

const currentStep = defineModel<number>("modelValue", { default: 0 });

function nextStep() {
  if (currentStep.value < totalSteps - 1) {
    currentStep.value++;
  }
}

function previousStep() {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
}

// Provide shared state & functions so Items can control navigation
provide("currentStep", currentStep);
provide("nextStep", nextStep);
provide("previousStep", previousStep);
provide("totalSteps", totalSteps);

watch(currentStep, (newValue) => {
  emit("onChange", newValue);
});
</script>

<template>
  <div class="stepper">
    <slot />
  </div>
</template>
