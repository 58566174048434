import { PermissionScope, type UserHydraItem, UserStateEnum } from "@verbleif/lib";

import { computed, ref } from "vue";
import { useLocationStore } from "../LocationStore";

export function useSharedStoreUsers() {
  const users = ref<Record<string, UserHydraItem>>({});
  const systemUsers = ref<Record<string, UserHydraItem>>({});

  const { selectedLocation, selectedLocationObject } = useLocationStore();

  const allUsers = computed<Record<string, UserHydraItem>>(() => {
    return { ...users.value, ...systemUsers.value };
  });

  const usersList = computed<UserHydraItem[]>(() => {
    return Object.values(users.value);
  });

  const systemUsersList = computed<UserHydraItem[]>(() => {
    return Object.values(systemUsers.value);
  });

  const allUsersList = computed<UserHydraItem[]>(() => {
    return [...usersList.value, ...systemUsersList.value];
  });

  function setUsers(newUsers: UserHydraItem[]) {
    users.value = newUsers.reduce((acc, user) => {
      acc[user["@id"]] = user;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  }

  function setSystemUsers(newSystemUsers: UserHydraItem[]) {
    systemUsers.value = newSystemUsers.reduce((acc, user) => {
      acc[user["@id"]] = user;
      return acc;
    }, {} as Record<string, UserHydraItem>);
  }

  function _reset() {
    users.value = {};
    systemUsers.value = {};
  }

  function upsertUser(user: UserHydraItem) {
    users.value[user["@id"]] = user;
  }

  function deleteUser(user: UserHydraItem) {
    delete users.value[user["@id"]];
  }

  function getActiveUserStatuses(user: UserHydraItem) {
    return user.statuses.filter(status => status.state === UserStateEnum.ACTIVE);
  }

  function getUsersPerUserGroup() {
    return usersList.value.reduce<Record<string, UserHydraItem[]>>((acc, user) => {
      const userGroupStatusses = getActiveUserStatuses(user).filter(status => status.userGroup && status.scope === PermissionScope.USER_GROUP);

      if (!userGroupStatusses.length) {
        return acc;
      }

      userGroupStatusses.forEach((userGroupStatus) => {
        if (!userGroupStatus.userGroup) {
          return;
        }
        if (!acc[userGroupStatus.userGroup]) {
          acc[userGroupStatus.userGroup] = [];
        }
        acc[userGroupStatus.userGroup].push(user);
      });

      return acc;
    }, {} as Record<string, UserHydraItem[]>);
  }

  function getUserGroupLessUsers() {
    return usersList.value.filter((user) => {
      //* Get all active user statuses for the user that are in the selected location or the client of the selected location

      const activeUserStatuses = getActiveUserStatuses(user);
      //* Check if the user has any user group status
      const hasAnyUserGroup = activeUserStatuses.some(status => status.scope === PermissionScope.USER_GROUP);

      //* If the user has any user group status, return false
      if (hasAnyUserGroup) {
        return false;
      }

      const baseUsersStatusses = getActiveUserStatuses(user).filter((status) => {
        if (status.location) {
          return status.location === selectedLocation.value;
        }
        return selectedLocationObject.value?.client === status.client;
      });

      const userStatuses = baseUsersStatusses.filter(status => status.scope !== PermissionScope.USER_GROUP);

      if (!userStatuses.length) {
        return false;
      }

      return true;
    });
  }

  const usersPerUserGroup = computed<Record<string, UserHydraItem[]>>(() => {
    return getUsersPerUserGroup();
  });

  const userGroupLessUsers = computed<UserHydraItem[]>(() => {
    return getUserGroupLessUsers();
  });

  return {
    users,
    usersList,
    setUsers,
    systemUsers,
    getUsersPerUserGroup,
    usersPerUserGroup,
    getActiveUserStatuses,
    userGroupLessUsers,
    systemUsersList,
    setSystemUsers,
    allUsers,
    getUserGroupLessUsers,
    allUsersList,
    _reset,
    upsertUser,
    deleteUser,
  };
}
