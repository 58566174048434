<script setup lang="ts">
import { PermissionScope } from "@verbleif/lib";
import { ref } from "vue";
import { useAuthStore } from "../../composables";
import { useDeviceState } from "../../composables/useDeviceState";

const { user } = useAuthStore();
const activeIndex = ref(0);
const deviceState = useDeviceState();
</script>

<template>
  <VTabs
    v-model:active-index="activeIndex"
    orientation="horizontal"
  >
    <VTabList>
      <VTabItem
        title="Profile"
        :icon="['fad', 'user']"
        :index="0"
      />
      <VTabItem
        title="Account"
        :icon="['fad', 'id-card']"
        :index="1"
      />
      <VTabItem
        title="Roles"
        :icon="['fad', 'shield']"
        :index="2"
      />
      <VTabItem
        title="Status"
        :icon="['fad', 'signal-bars']"
        :index="3"
      />
      <VTabItem
        title="Permissions"
        :icon="['fad', 'key']"
        :index="4"
      />
    </VTabList>
    <VTabPanels>
      <!-- Profile Tab -->
      <VTabPanel :index="0">
        <div class="flex flex-col gap-4 p-4">
          <!-- Profile Header -->
          <div class="flex items-center gap-4 border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <div class="relative">
              <div v-if="user?.avatar" class="w-20 h-20 rounded-full overflow-hidden">
                <img :src="user.avatar.contentUrl" :alt="user?.name" class="w-full h-full object-cover">
                <div class="mt-1 text-xs text-gray-500 dark:text-gray-400 text-center">
                  {{ user.avatar.mimeType }}
                </div>
              </div>
              <div v-else class="w-20 h-20 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center">
                <FontAwesomeIcon :icon="['fad', 'user']" class="text-primary-500 w-10 h-10" />
              </div>
            </div>
            <div>
              <h3 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {{ user?.name }}
              </h3>
              <p class="text-sm text-gray-600 dark:text-gray-400">
                {{ user?.username }}
              </p>
              <div v-if="user?.autoLinkSuggestion" class="mt-1 text-xs text-gray-500 dark:text-gray-400">
                Auto Link: {{ user.autoLinkSuggestion }}
              </div>
            </div>
          </div>

          <!-- Basic Information -->
          <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <h4 class="text-sm font-medium text-gray-800 dark:text-gray-300 mb-3">
              Basic Information
            </h4>
            <div class="space-y-2">
              <div class="flex items-center gap-2">
                <FontAwesomeIcon :icon="['fad', 'globe']" class="text-primary-500 w-4 h-4" />
                <span class="text-sm text-gray-600 dark:text-gray-400"><span class="font-bold">Locale:</span> {{ user?.locale }}</span>
              </div>
              <div class="flex items-center gap-2">
                <FontAwesomeIcon :icon="['fad', 'clock']" class="text-primary-500 w-4 h-4" />
                <span class="text-sm text-gray-600 dark:text-gray-400"><span class="font-bold">Timezone:</span> {{ user?.timezone }}</span>
              </div>
              <div class="flex items-center gap-2">
                <FontAwesomeIcon :icon="['fad', 'calendar']" class="text-primary-500 w-4 h-4" />
                <span class="text-sm text-gray-600 dark:text-gray-400"><span class="font-bold">Created:</span> {{ user?.createdAt ? new Date(user.createdAt).toLocaleString() : '-' }}</span>
              </div>
              <div class="flex items-center gap-2">
                <FontAwesomeIcon :icon="['fad', 'calendar-plus']" class="text-primary-500 w-4 h-4" />
                <span class="text-sm text-gray-600 dark:text-gray-400"><span class="font-bold">Updated:</span> {{ user?.updatedAt ? new Date(user.updatedAt).toLocaleString() : '-' }}</span>
              </div>
              <div class="flex items-center gap-2">
                <FontAwesomeIcon :icon="['fad', 'mobile']" class="text-primary-500 w-4 h-4" />
                <span class="text-sm text-gray-600 dark:text-gray-400"><span class="font-bold">Device:</span> {{ deviceState.deviceId }}</span>
              </div>
            </div>
          </div>
        </div>
      </VTabPanel>

      <!-- Account Tab -->
      <VTabPanel :index="1">
        <div class="flex flex-col gap-4 p-4">
          <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <h4 class="text-sm font-medium text-gray-800 dark:text-gray-300 mb-3">
              Account Status
            </h4>
            <div class="space-y-3">
              <div class="flex items-center justify-between px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
                <div class="flex items-center gap-2">
                  <FontAwesomeIcon :icon="['fad', 'toggle-on']" class="text-primary-500 w-4 h-4" />
                  <span class="text-sm text-gray-700 dark:text-gray-300">Active</span>
                </div>
                <span class="px-2 py-0.5 text-xs rounded" :class="user?.active ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'">
                  {{ user?.active ? 'Yes' : 'No' }}
                </span>
              </div>
              <div class="flex items-center justify-between px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
                <div class="flex items-center gap-2">
                  <FontAwesomeIcon :icon="['fad', 'envelope']" class="text-primary-500 w-4 h-4" />
                  <span class="text-sm text-gray-700 dark:text-gray-300">Email Verified</span>
                </div>
                <span class="px-2 py-0.5 text-xs rounded" :class="user?.emailVerifiedAt ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'">
                  {{ user?.emailVerifiedAt ? new Date(user.emailVerifiedAt).toLocaleString() : 'Not Verified' }}
                </span>
              </div>
              <div class="flex items-center justify-between px-3 py-2 border border-gray-200 dark:border-gray-700 rounded">
                <div class="flex items-center gap-2">
                  <FontAwesomeIcon :icon="['fad', 'hourglass-end']" class="text-primary-500 w-4 h-4" />
                  <span class="text-sm text-gray-700 dark:text-gray-300">Account Expiry</span>
                </div>
                <span class="px-2 py-0.5 text-xs rounded" :class="user?.expiresAt ? 'bg-warning-100 text-warning-700 dark:bg-warning-900 dark:text-warning-300' : 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300'">
                  {{ user?.expiresAt ? new Date(user.expiresAt).toLocaleString() : 'Never' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </VTabPanel>

      <!-- Roles Tab -->
      <VTabPanel :index="2">
        <div class="flex flex-col gap-4 p-4">
          <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <h4 class="text-sm font-medium text-gray-800 dark:text-gray-300 mb-3">
              User Roles
            </h4>
            <div class="space-y-3">
              <template v-if="user?.roles?.length">
                <div v-for="role in user.roles" :key="role.id" class="p-3 border border-gray-200 dark:border-gray-700 rounded">
                  <div class="flex items-center justify-between mb-2">
                    <span class="text-sm font-medium text-gray-700 dark:text-gray-300">{{ role.roleName }}</span>
                    <span class="px-2 py-0.5 text-xs rounded" :class="role.inSyncWithRole ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-warning-100 text-warning-700 dark:bg-warning-900 dark:text-warning-300'">
                      {{ role.inSyncWithRole ? 'In Sync' : 'Out of Sync' }}
                    </span>
                  </div>
                  <div class="grid grid-cols-2 gap-2 text-xs text-gray-500 dark:text-gray-400">
                    <div>Template Group: {{ role.templateGroup }}</div>
                    <div>Auto Sync: {{ role.autoSyncWithRole ? 'Yes' : 'No' }}</div>
                    <div v-if="role.client">
                      Client: {{ role.client }}
                    </div>
                    <div v-if="role.location">
                      Location: {{ role.location }}
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="p-6 border border-gray-200 dark:border-gray-700 rounded flex flex-col items-center justify-center text-center">
                <FontAwesomeIcon :icon="['fad', 'shield']" class="text-gray-400 dark:text-gray-600 w-8 h-8 mb-2" />
                <span class="text-sm text-gray-500 dark:text-gray-400">No roles assigned to this user</span>
              </div>
            </div>
          </div>
        </div>
      </VTabPanel>

      <!-- Status Tab -->
      <VTabPanel :index="3">
        <div class="flex flex-col gap-4 p-4">
          <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <h4 class="text-sm font-medium text-gray-800 dark:text-gray-300 mb-3">
              User Statuses
            </h4>
            <div class="space-y-3">
              <template v-if="user?.statuses?.length">
                <div v-for="status in user.statuses" :key="status['@id']" class="p-3 border border-gray-200 dark:border-gray-700 rounded">
                  <div class="flex items-center justify-between mb-2">
                    <span class="text-sm font-medium text-gray-700 dark:text-gray-300">{{ status['@type'] }}</span>
                    <span class="text-xs text-gray-500 dark:text-gray-400">ID: {{ status['@id'] }}</span>
                  </div>
                  <div class="grid grid-cols-2 gap-2 text-xs text-gray-500 dark:text-gray-400">
                    <div>Context: {{ status['@context'] }}</div>
                    <div>State: {{ status.state }}</div>
                    <div>Scope: {{ status.scope }}</div>
                    <div>Expires: {{ status.expiresAt ? new Date(status.expiresAt).toLocaleString() : 'Never' }}</div>
                    <template v-if="status.scope === PermissionScope.PROPERTY">
                      <div>Property: {{ status.property }}</div>
                      <div>Location: {{ status.location }}</div>
                      <div>Client: {{ status.client }}</div>
                    </template>
                    <template v-else-if="status.scope === PermissionScope.USER_GROUP">
                      <div>User Group: {{ status.userGroup }}</div>
                      <div>Location: {{ status.location }}</div>
                      <div>Client: {{ status.client }}</div>
                    </template>
                    <template v-else-if="status.scope === PermissionScope.LOCATION">
                      <div>Location: {{ status.location }}</div>
                      <div>Client: {{ status.client }}</div>
                    </template>
                    <template v-else-if="status.scope === PermissionScope.CLIENT">
                      <div>Client: {{ status.client }}</div>
                    </template>
                  </div>
                </div>
              </template>
              <div v-else class="p-6 border border-gray-200 dark:border-gray-700 rounded flex flex-col items-center justify-center text-center">
                <FontAwesomeIcon :icon="['fad', 'signal-bars']" class="text-gray-400 dark:text-gray-600 w-8 h-8 mb-2" />
                <span class="text-sm text-gray-500 dark:text-gray-400">No statuses available for this user</span>
              </div>
            </div>
          </div>
        </div>
      </VTabPanel>

      <!-- Permissions Tab -->
      <VTabPanel :index="4">
        <div class="p-4">
          <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
            <div class="space-y-3">
              <template v-if="user?.permissions?.length">
                <div v-for="permission in user.permissions" :key="permission['@id']" class="p-3 border border-gray-200 dark:border-gray-700 rounded">
                  <div class="flex items-center justify-between mb-2">
                    <span class="text-sm font-medium text-gray-700 dark:text-gray-300">{{ permission['@type'] }}</span>
                    <span class="px-2 py-0.5 text-xs rounded" :class="permission.active ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'">
                      {{ permission.active ? 'Active' : 'Inactive' }}
                    </span>
                  </div>
                  <div class="grid grid-cols-2 gap-2 text-xs text-gray-500 dark:text-gray-400 mb-2">
                    <div>Created: {{ permission.createdAt ? new Date(permission.createdAt).toLocaleString() : '-' }}</div>
                    <div>Updated: {{ permission.updatedAt ? new Date(permission.updatedAt).toLocaleString() : '-' }}</div>
                    <div>Expires: {{ permission.expiresAt ? new Date(permission.expiresAt).toLocaleString() : 'Never' }}</div>
                    <div>Scope: {{ permission.scope }}</div>
                    <div v-if="permission.client">
                      Client: {{ permission.client }}
                    </div>
                    <div v-if="permission.location">
                      Location: {{ permission.location }}
                    </div>
                    <div v-if="permission.userGroup">
                      User Group: {{ permission.userGroup }}
                    </div>
                    <div v-if="permission.property">
                      Property: {{ permission.property }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="text-xs font-medium text-gray-600 dark:text-gray-400 mb-1">
                      Attributes:
                    </div>
                    <div class="flex flex-wrap gap-1">
                      <span v-for="attr in permission.attributes" :key="attr" class="px-2 py-0.5 text-xs bg-primary-50 text-primary-700 dark:bg-primary-900 dark:text-primary-300 rounded-sm">
                        {{ attr }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="p-6 border border-gray-200 dark:border-gray-700 rounded flex flex-col items-center justify-center text-center">
                <FontAwesomeIcon :icon="['fad', 'key']" class="text-gray-400 dark:text-gray-600 w-8 h-8 mb-2" />
                <span class="text-sm text-gray-500 dark:text-gray-400">No permissions assigned to this user</span>
              </div>
            </div>
          </div>
        </div>
      </VTabPanel>
    </VTabPanels>
  </VTabs>
</template>
