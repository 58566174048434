import type { AppSubscriptionImportProgress } from "@verbleif/lib";
import {
  useMercure,
} from "@verbleif/lib";
import { useDebounceFn } from "@vueuse/core";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ref, watchEffect } from "vue";
import { toast } from "vue-sonner";
import { customToast } from "../components/Sonner/SonnerCustom";
import useConfigStore from "./ConfigStore/useConfigStore";
import { useLocationStore } from "./LocationStore/useLocationStore";
import { useSystemStore } from "./SystemStore/useSystemStore";
import { useImportHistory } from "./useImportHistory";
import { useMercureState } from "./useMercureState";

// Initialize dayjs duration plugin
dayjs.extend(duration);

export default function () {
  const locationStore = useLocationStore();
  const configStore = useConfigStore();
  const mercureState = useMercureState();
  const { isAnyAppSubscriptionSyncImportBatchRunning, deleteAppSubscriptionSyncImportBatch } = useSystemStore();
  const { importHistory, getComponentProps } = useImportHistory();
  const dismissedUnixMap = ref<Record<string, number>>({});

  // Debounced function to dismiss toasts
  const debouncedDismissToast = useDebounceFn((toastId: string | number) => {
    toast.dismiss(toastId);
  }, 6000);

  async function onAddOrUpdate(progress: AppSubscriptionImportProgress) {
    // Check if it has importBatch
    if (!progress.importBatch) {
      console.log("No importBatch in progress:", progress);
      return;
    }

    // Add to import history
    const batchIri = progress.importBatch;
    importHistory.value[batchIri] = progress;

    const componentProps = getComponentProps(progress);

    if (!componentProps) {
      console.log("No componentProps in progress:", progress);
      return;
    }

    const dismissedUnix = dismissedUnixMap.value[batchIri];
    // 5 seconds ago
    if (dismissedUnix && Date.now() - dismissedUnix < 5000) {
      console.log("Dismissed toast, skip");
      return;
    }

    // Create or update toast
    customToast({
      id: batchIri,
      important: true,
      duration: Infinity,
      componentProps,
    });

    // When we receive complete/failed, wait 2 sec with debounce, then hide the message
    if (progress.finished || progress.failed) {
      dismissedUnixMap.value[batchIri] = Date.now();
      debouncedDismissToast(batchIri);
      deleteAppSubscriptionSyncImportBatch(batchIri);
    }
  };

  // setTimeout(() => {
  //   onAddOrUpdate({
  //     progressBar: {
  //       max: 14,
  //       startTime: Date.now(),
  //       step: 1,
  //       percent: 7,
  //       completed: false,
  //       estimated: 600, // 10 minutes in seconds
  //       remaining: 600, // 10 minutes in seconds
  //       elapsed: 60, // 1 minute in seconds
  //     },
  //     sequenceProgressBar: {
  //       max: 186,
  //       startTime: Date.now() - 60000, // Started 1 minute ago
  //       step: 30,
  //       percent: 15,
  //       completed: false,
  //       estimated: 120, // 2 minutes in seconds
  //       remaining: 60, // 1 minute in seconds
  //       elapsed: 60, // 1 minute in seconds
  //     },
  //     sequenceName: "executors",
  //     finished: false,
  //     failed: false,
  //     appSubscription: "/api/app_subscriptions/10",
  //     importBatch: "/api/app_subscription_import_batches/15",
  //   });
  // }, 2000);

  const mercure = useMercure<AppSubscriptionImportProgress>({
    hubUrlGetter: () => configStore.config.value?.mercureUrl || "",
    refreshMercureToken: mercureState.refreshMercureToken,
    mercureTokenGetter: () => mercureState.mercureToken.value || "",
    onAddOrUpdate,
  });

  watchEffect(() => {
    const clientId = locationStore.selectedClient.value?.id;
    if (!clientId) {
      return;
    }
    if (!isAnyAppSubscriptionSyncImportBatchRunning.value) {
      console.log("[useImport] No app subscription sync import batch running, close mercure");
      mercure.close();
      return;
    }

    if (mercure.isConnected()) {
      console.log("[useImport] Mercure is already connected, skip");
      return;
    }

    const encoded = encodeURIComponent("/api/app_subscriptions/import_batch");

    console.log("[useImport] Import is running, connect mercure");
    mercure.connect(`/clients/${clientId}?topic=${encoded}`);
  });
}
