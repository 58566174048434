import * as Sentry from "@sentry/vue";
import { DevicePushTokenTypeEnum, DeviceTypeEnum, type RegisterDevice } from "@verbleif/lib";
import { createGlobalState, useStorage } from "@vueuse/core";
import { version } from "../../package.json";
import api from "./Api/api";

export const useDeviceState = createGlobalState(() => {
  // Persistent storage using VueUse hooks
  const deviceId = useStorage<string>("device_identifier", () => crypto.randomUUID?.() || Math.random().toString(36).substring(2), localStorage);
  const browserVersion = useStorage<string>("browser_version", "", localStorage);
  const lastUpdateAt = useStorage<string>("browser_last_update", "", localStorage);

  async function updateDevice(token?: string) {
    const browserInfo = getBrowserInfo();
    const currentVersion = browserInfo.version;

    // First check if we have a version stored
    if (!browserVersion.value) {
      // First time running, set current date as update date
      lastUpdateAt.value = new Date().toISOString();
      browserVersion.value = currentVersion;
    } else if (browserVersion.value !== currentVersion) {
      lastUpdateAt.value = new Date().toISOString();
      browserVersion.value = currentVersion;
    } else if (!lastUpdateAt.value) {
      lastUpdateAt.value = new Date().toISOString();
    }

    // Ensure all fields respect max length constraints
    const name = `${browserInfo.model} ${browserInfo.version} on ${getOS().name}`.substring(0, 255);
    const brand = browserInfo.brand.substring(0, 32);
    const userAgent = navigator.userAgent.substring(0, 1024);
    const model = browserInfo.model.substring(0, 128);
    const operatingSystemName = getOS().name.substring(0, 32);
    const operatingSystemVersion = getOS().version.substring(0, 32);
    const fontScale = window.devicePixelRatio.toString().substring(0, 8);
    const runtimeVersion = version.substring(0, 64);
    const bundleVersion = browserInfo.version.substring(0, 64);

    // Determine channel based on hostname
    let channel: "local" | "testing" | "staging" | "production" = "production";
    if (window.location.hostname.includes("localhost")) {
      channel = "local";
    } else if (window.location.hostname.includes("dev")) {
      channel = "testing";
    } else if (window.location.hostname.includes("staging")) {
      channel = "staging";
    }

    const data: RegisterDevice = {
      identifier: deviceId.value,
      type: DeviceTypeEnum.BROWSER,
      pushTokens: token
        ? [{
            type: DevicePushTokenTypeEnum.FCM,
            token,
          }]
        : undefined,
      meta: {
        name,
        brand,
        userAgent,
        model,
        operatingSystemName,
        operatingSystemVersion,
        fontScale,
        runtimeVersion,
        buildNumber: 0,
        bundleVersion,
        lastAppUpdateAt: lastUpdateAt.value,
        emulator: false,
        channel,
      },
    };
    try {
      await api.post("/api/devices/register", data);
    } catch (error) {
      console.error("[useDeviceState] Failed to update device", error);
      Sentry.captureException(error);
    }
  }

  function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let model = "";
    let version = "";
    let brand = "";

    // Chrome
    if (userAgent.includes("Chrome")) {
      const match = userAgent.match(/Chrome\/(\d+\.\d+)/);
      model = "Chrome";
      brand = "Google";
      version = match ? match[1] : "";
    } else if (userAgent.includes("Firefox")) {
      const match = userAgent.match(/Firefox\/(\d+\.\d+)/);
      model = "Firefox";
      brand = "Mozilla";
      version = match ? match[1] : "";
    } else if (userAgent.includes("Safari")) {
      const match = userAgent.match(/Version\/(\d+\.\d+)/);
      model = "Safari";
      brand = "Apple";
      version = match ? match[1] : "";
    } else if (userAgent.includes("Edg")) {
      const match = userAgent.match(/Edg\/(\d+\.\d+)/);
      model = "Edge";
      brand = "Microsoft";
      version = match ? match[1] : "";
    }

    return { model, version, brand };
  }

  function getOS() {
    const userAgent = navigator.userAgent;

    // Windows
    if (userAgent.includes("Windows")) {
      const windowsVersion = userAgent.match(/Windows NT (\d+\.\d+)/);
      return {
        name: "Windows",
        version: windowsVersion ? windowsVersion[1] : "",
      };
    }

    // macOS
    if (userAgent.includes("Mac")) {
      const macVersion = userAgent.match(/Mac OS X (\d+[._]\d+)/);
      return {
        name: "macOS",
        version: macVersion ? macVersion[1].replace("_", ".") : "",
      };
    }

    // iOS
    if (userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod")) {
      const iosVersion = userAgent.match(/OS (\d+[._]\d+)/);
      return {
        name: "iOS",
        version: iosVersion ? iosVersion[1].replace("_", ".") : "",
      };
    }

    // Android
    if (userAgent.includes("Android")) {
      const androidVersion = userAgent.match(/Android (\d+\.\d+)/);
      return {
        name: "Android",
        version: androidVersion ? androidVersion[1] : "",
      };
    }

    // Linux
    if (userAgent.includes("Linux")) {
      return {
        name: "Linux",
        version: "",
      };
    }

    return {
      name: "",
      version: "",
    };
  }

  return {
    updateDevice,
    deviceId,
    browserVersion,
    lastUpdateAt,
  };
});
