import type { PropertyReservationStateHydraItem } from "@verbleif/lib";
import { createGlobalState } from "@vueuse/core";

export const useReservationStateDialog = createGlobalState(() => {
  const reservationStateDialogRef = ref<{
    show: ({
      reservationState,
    }: {
      reservationState?: PropertyReservationStateHydraItem | null
    }) => void
  } | null>(null);

  return {
    reservationStateDialogRef,
  };
});
