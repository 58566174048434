import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4F4F4F",
      "fill-rule": "nonzero",
      d: "M499.412 446.623c14.991 14.951 14.991 39.21 0 54.163-14.992 14.953-39.317 14.951-54.31 0L256.026 310.817 65.606 500.722c-14.991 14.951-39.315 14.951-54.309 0s-14.992-39.21 0-54.163l190.484-189.84L11.244 65.376c-14.992-14.951-14.992-39.21 0-54.163s39.316-14.951 54.31 0L256.026 202.62l190.42-189.905c14.992-14.951 39.316-14.951 54.31 0s14.992 39.21 0 54.163L310.272 256.718z",
      class: "close-icon"
    }, null, -1)
  ])))
}
export default { render: render }