import { createInjectionState } from "@vueuse/core";

const [useProvideAccordionPanelState, useAccordionPanelStateRaw] = createInjectionState(({
  value,
  disabled = false,
}: {
  value: string | number
  disabled?: boolean
}) => {
  return {
    value,
    disabled,
  };
});

export { useProvideAccordionPanelState };

export function useAccordionPanelState() {
  const state = useAccordionPanelStateRaw();
  if (state == null) {
    throw new Error("Please call `useProvideAccordionPanelState` on the appropriate parent component");
  }
  return state;
}
