<script setup lang="ts">
import { useAttrs } from "vue";
import { useI18n } from "vue-i18n";

const {
  allowChange = false,
  type = "button",
  popperClass,
  showGroup,
  customContent,
} = defineProps<{
  allowChange: boolean
  type: "dropdown_item" | "edit_field" | "button" | "tab" | "list" | "item" | "delete" | "action" | "comment"
  popperClass?: string
  showGroup?: string
  customContent?: string
}>();

const attrs = useAttrs();

const { t } = useI18n();
</script>

<template>
  <VTooltip
    placement="top"
    :class="[
      attrs.class,
      !allowChange ? 'cursor-not-allowed' : '',
    ]"
    theme="verbleif-tooltip-ac"
    :triggers="['hover', 'touch']"
    :show-group="showGroup"
    :popper-class="popperClass"
    :disabled="allowChange"
  >
    <template #content>
      <div class="flex items-center gap-2">
        <FontAwesomeIcon :icon="['fas', 'exclamation-triangle']" />
        <div>{{ customContent || t(`permissions.ac.${type}`) }}</div>
      </div>
    </template>
    <template #reference>
      <slot />
    </template>
  </VTooltip>
</template>

<style scoped>

</style>
