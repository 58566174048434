import type { HydraContext } from "../hydra.interface";

export enum DeviceTypeEnum {
  MOBILE = 1,
  TABLET = 2,
  BROWSER = 3,
}

export enum DevicePushTokenTypeEnum {
  FCM = 1,
  APN = 2,
  EXPO = 3,
}

export interface DevicePushToken {
  type: DevicePushTokenTypeEnum
  token: string
}

export interface DeviceMeta {
  name?: string
  brand?: string
  userAgent?: string
  model?: string
  operatingSystemName?: string
  operatingSystemVersion?: string
  rooted?: boolean
  fontScale?: string
  runtimeVersion?: string
  buildNumber?: number
  bundleVersion?: string
  lastAppUpdateAt?: string
  emulator?: boolean
  hermes?: boolean
  channel: "local" | "testing" | "staging" | "production"
}

export interface RegisterDevice {
  identifier: string
  type: DeviceTypeEnum
  pushTokens?: DevicePushToken[]
  meta?: DeviceMeta
}

export type DeviceHydraCollectionItemItem = HydraContext & {
  "@type": "Device"
  "relatedUser": string
  "id": number
  "createdAt": string
  "updatedAt"?: string | null
} & Pick<RegisterDevice, "identifier" | "type">;

export type DeviceHydraItem = DeviceHydraCollectionItemItem & Pick<RegisterDevice, "meta">;
