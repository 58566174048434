import { PriorityEnum } from "@verbleif/lib";
import PriorityInlineIcon from "./PriorityInlineIcon.svg";
import PriorityPlaceholderIcon from "./PriorityPlaceholderIcon.svg";
import PriorityStrokedIcon from "./PriorityStrokedIcon.svg";

export const PRIORITIES_TEXTS = {
  [PriorityEnum.LOW]: "tasks.priority.low",
  [PriorityEnum.NORMAL]: "tasks.priority.medium",
  [PriorityEnum.HIGH]: "tasks.priority.high",
  [PriorityEnum.URGENT]: "tasks.priority.urgent",
};
export const PRIORITIES_CLASSES = {
  [PriorityEnum.LOW]: "is-low",
  [PriorityEnum.NORMAL]: "is-medium",
  [PriorityEnum.HIGH]: "is-high",
  [PriorityEnum.URGENT]: "is-urgent",
};

export function usePriority() {
  function priorityToInlineIcon(v: PriorityEnum | null) {
    if (v === null) {
      return PriorityInlineIcon;
    }

    return PriorityInlineIcon;
  }

  function priorityToStrokedIcon(v: PriorityEnum | null) {
    if (v === null) {
      return PriorityPlaceholderIcon;
    }

    return PriorityStrokedIcon;
  }

  function priorityToText(v: PriorityEnum | null) {
    if (v === null) {
      return "tasks.priority.none";
    }

    return PRIORITIES_TEXTS?.[v] || "tasks.priority.none";
  }

  function priorityToClass(v: PriorityEnum | null) {
    if (v === null) {
      return "is-none";
    }

    return PRIORITIES_CLASSES?.[v] || "is-none";
  }

  return {
    priorityToInlineIcon,
    priorityToStrokedIcon,
    priorityToText,
    priorityToClass,
  };
}
