import type { ReservationHydraItem, TransitionState } from "@verbleif/lib";
import { createGlobalState } from "@vueuse/core";

export const useCurrentReservationDialog = createGlobalState(() => {
  const currentReservationDialogRef = ref<{
    show: ({
      currentReservation,
    }: {
      currentReservation?: {
        reservation: ReservationHydraItem | null
        state: TransitionState | null
      } | null
    }) => void
  } | null>(null);

  return {
    currentReservationDialogRef,
  };
});
