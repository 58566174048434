import type { MessagePayload, Unsubscribe } from "firebase/messaging";
import { createGlobalState, tryOnScopeDispose, useThrottleFn } from "@vueuse/core";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { customToast } from "../components";
import { useAuthStore } from "./AuthStore/useAuthStore";
import { useDeviceState } from "./useDeviceState";
import { useNavbarUnreadCount } from "./useNavbarUnreadCount";

const VAPID_KEY = "BNP0kOXWCJWv3gnQvHZ10GzqtwVdedjSa0kKeOP1IjDmeQZZ_6k2zrm__ONfqUACzeL0mHJOdnleqLu2HZeAlU4";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOWQ4W4e9K544Itbts_gsTpGc-AEIgVsg",
  authDomain: "verbleif-489a4.firebaseapp.com",
  databaseURL: "https://verbleif-489a4.firebaseio.com",
  projectId: "verbleif-489a4",
  storageBucket: "verbleif-489a4.firebasestorage.app",
  messagingSenderId: "572754758664",
  appId: "1:572754758664:web:d0a4899006b1f49aa5d5bd",
  measurementId: "G-MBT9BYGQJQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = isSupported().then((supported) => {
  if (!supported) {
    return getMessaging(app);
  }
  return null;
});

export function deleteFirebaseToken() {
  return deleteToken(getMessaging());
}

export const useFirebase = createGlobalState(() => {
  const granted = ref(
    window.Notification && window.Notification.permission === "granted",
  );
  const { checkUnreadCount } = useNavbarUnreadCount();
  const { user, isAuthenticated, device } = useAuthStore();
  const lastToken = ref<string | null>(null);
  const unsubscribe = ref<Unsubscribe | null>(null);
  const isTryingToSubscribe = ref(false);
  const { updateDevice } = useDeviceState();

  const throttleUpdateDevice = useThrottleFn(updateDevice, 10000);

  function handleMessage(payload: MessagePayload) {
    try {
      console.log("[useFirebase] Received message", payload);
      const { notification, data } = payload;

      if (!notification) {
        throw new Error("Invalid payload.notification received from firebase.");
      }
      if (!data) {
        throw new Error("Invalid payload.data received from firebase.");
      }

      // Check if message is targeted to current user
      if ("target" in data && data.target !== user.value?.["@id"]) {
        return;
      }

      // Validate required fields
      if (!notification.title || typeof notification.title !== "string") {
        throw new Error("Invalid payload.notification.title received from firebase.");
      }
      if (!notification.body) {
        throw new Error("Invalid payload.notification.body received from firebase.");
      }
      // if (!("@id" in data)) {
      //   throw new Error("Missing @id in payload data from firebase.");
      // }

      // const type = data.type;
      // if (!type || !(Object.values(NotificationTypeEnum) as string[]).includes(type)) {
      //   throw new Error(`Invalid notification type: ${type}`);
      // }

      checkUnreadCount().then();

      setTimeout(() => {
        customToast({
          id: Date.now(),
          duration: 6000,
          componentProps: {
            title: notification.title ?? "",
            message: notification.body ?? "",
            runningIcon: ["fas", "bell"],
            runningIconClass: "text-primary-500",
          },
        });
      }, 1);

      // const mappedData: NotificationItem = {
      //   title: notification.title,
      //   body: notification.body,
      //   // type: type as NotificationTypeEnum,
      //   type: NotificationTypeEnum.TYPE_TASK,
      //   iri: data["@id"],
      // };

      // notifications.addNotification(mappedData);
    } catch (error) {
      console.error("[useFirebase] Error handling Firebase message:", error);
      // Don't throw here to prevent breaking the message listener
    }
  }

  async function subscribeToMessages() {
    if (isTryingToSubscribe.value) {
      return;
    }
    isTryingToSubscribe.value = true;
    try {
      if (!granted.value) {
        return;
      }

      const token = await getToken(getMessaging(), { vapidKey: VAPID_KEY });

      if (!lastToken.value || token !== lastToken.value) {
        await updateDevice(token);

        lastToken.value = token;
      }

      console.log("[useFirebase] Device", device.value);

      console.log("[useFirebase] Setting up Firebase message listener");

      // Clean up any existing subscription
      unsubscribeFromMessages();

      unsubscribe.value = onMessage(getMessaging(), handleMessage);
      console.log("[useFirebase] Firebase message listener set up");
    } catch (error) {
      console.error("[useFirebase] Error setting up Firebase message listener:", error);
    } finally {
      isTryingToSubscribe.value = false;
    }
  }

  function unsubscribeFromMessages() {
    if (unsubscribe.value) {
      unsubscribe.value();
      unsubscribe.value = null;
    }
  }
  const route = useRoute();

  const skipRoutes = computed(() => {
    return route.meta.public || route.meta.skipAuthChecks || route.name === undefined;
  });

  // Watch for permission changes and handle cleanup
  watch([() => granted.value, () => isAuthenticated.value, () => skipRoutes.value], ([isGranted, isAuthenticated, shouldSkip]) => {
    if (shouldSkip) {
      return;
    }

    if (isAuthenticated) {
      if (isGranted) {
        subscribeToMessages();
      } else {
        console.log("[useFirebase] Permission denied, updating device");
        throttleUpdateDevice();
      }
    } else {
      unsubscribeFromMessages();
    }
  }, { immediate: true });

  // Cleanup on component/scope disposal
  tryOnScopeDispose(() => {
    unsubscribeFromMessages();
  });

  async function initialize(): Promise<boolean> {
    if (await shouldRequestNotificationPermission()) {
      const permission = await Notification.requestPermission();
      granted.value = permission === "granted";
    }

    return granted.value;
  }

  async function shouldRequestNotificationPermission() {
    if (!window.Notification) {
      return false;
    }
    if (!(await isSupported())) {
      return false;
    }
    return window.Notification.permission === "default";
  }

  return {
    initialize,
    isSupported,
    granted,
    unsubscribeFromMessages,
  };
});
