import type { NotificationHydraItem } from "@verbleif/lib";
import { LoopbackFilterLogicalOperatorEnum, LoopbackFilterOperatorEnum, providePaginate } from "@verbleif/lib";
import { api, useAuthStore, useLocationStore, useNavbarUnreadCount, useServerErrorHandler } from "@verbleif/shared";
import { createInjectionState } from "@vueuse/core";
import { toast } from "vue-sonner";

const [useProvideNavbarNotifications, useNavbarNotificationsRaw] = createInjectionState(() => {
  const authStore = useAuthStore();
  const { t } = useI18n();
  const ENDPOINT = computed(() => authStore.user.value?.id ? `/users/${authStore.user.value?.id}/legacy_notifications` : "");
  const TRANSLATION_KEY = "navbar.dropdown";
  const { unreadCount, checkUnreadCount } = useNavbarUnreadCount();
  const { onError } = useServerErrorHandler();

  const { modeSelectedLocation } = useLocationStore();
  // Setup pagination
  const {
    load,
    loading,
    items,
    resetAndLoad,
    searchable,
    cursor,
  } = providePaginate<NotificationHydraItem>({
    cursorParams: { partial: true },
    initialPerPage: 10,
    runOnMount: false,
    sortableFields: {},
    searchableFields: [
      {
        name: "title",
        operator: LoopbackFilterOperatorEnum.ILIKE,
        logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
      },
      {
        name: "messageWeb",
        operator: LoopbackFilterOperatorEnum.ILIKE,
        logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
      },
    ],
    onLoad: async (options) => {
      const urlSearchParams = new URLSearchParams(options.params);

      if (modeSelectedLocation.value) {
        urlSearchParams.set("location", modeSelectedLocation.value);
      }

      return api.get(ENDPOINT.value, options);
    },
  });

  // Add error state
  const error = ref<Error | null>(null);
  const hasError = computed(() => error.value !== null);
  const hasNotifications = computed(() => {
    const realItems = items.value.filter(item => !("skeleton" in item));
    return realItems.length > 0;
  });
  const isAllRead = computed(() => unreadCount.value === 0);

  // Methods

  async function markAsRead() {
    if (!hasNotifications.value || isAllRead.value) {
      return;
    }

    try {
      error.value = null;

      const itemsToMark = items.value.filter((item): item is NotificationHydraItem => !("skeleton" in item) && !item.readAt);

      for (const item of itemsToMark) {
        await markOneAsRead(item, true);
      }

      await resetAndLoad();
      await checkUnreadCount();

      toast.success(t(`${TRANSLATION_KEY}.all_marked_read`));
    } catch (err) {
      error.value = err as Error;
      onError({ error: err });
      toast.error(t(`${TRANSLATION_KEY}.mark_read_error`));
    }
  }

  async function markOneAsRead(notification: NotificationHydraItem, hideToast = false) {
    if (!notification.id || notification.readAt) {
      return;
    }

    try {
      await api.post(`/api/legacy_notifications/${notification.id}/read`, {
        readAt: new Date().toISOString(),
      });

      notification.readAt = new Date().toISOString();
      await checkUnreadCount();
    } catch (error) {
      onError({ error, hideToast });
    }
  }

  onMounted(() => {
    checkUnreadCount();
  });

  return {
    // State
    isAllRead,
    unreadCount,
    items,
    loading,
    searchable,
    hasNextPage: cursor.hasNextPage,
    currentPage: cursor.currentPage,

    // Methods
    load,
    markAsRead,
    markOneAsRead,
    checkUnreadCount,
    resetAndLoad,

    // State
    error,
    hasError,
    hasNotifications,
  };
});

export function useNavbarNotifications() {
  const NavbarNotifications = useNavbarNotificationsRaw();
  if (NavbarNotifications == null) {
    throw new Error("Please call `useProvideNavbarNotifications` on the appropriate parent component");
  }
  return NavbarNotifications;
}

export { useProvideNavbarNotifications };
